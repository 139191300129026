import distinction from '../../../images/distinction.jpeg';
import estudiantil from '../../../images/estudiantil.jpeg';
import infinity from '../../../images/infinity.jpeg';
import signature from '../../../images/signature.jpeg';
import p0070 from '../../../images/productos/salud/0070.jpg';
import p0071 from '../../../images/productos/salud/0071.jpg';
import p0072 from '../../../images/productos/salud/0072.jpg';
import p0136 from '../../../images/productos/salud/0136.jpg';
import p0144 from '../../../images/productos/salud/0144.jpg';
import p0149 from '../../../images/productos/salud/0149.jpg';
import p0140 from '../../../images/productos/salud/0140.jpg';
import p0167 from '../../../images/productos/salud/0167.jpg';
import p0168 from '../../../images/productos/salud/0168.jpg';
import p016808 from '../../../images/productos/salud/0168-08.jpg';
import p0166 from '../../../images/productos/salud/0166.jpg';
import p0137 from '../../../images/productos/salud/0137.jpg';
import p0138 from '../../../images/productos/salud/0138.jpg';

export default [
  {
    'codcia': '03',
    'codprod': '0146',
    img: infinity
  },

  {
    'codcia': '03',
    'codprod': '0137',
    img: p0137
  },

  {
    'codcia': '03',
    'codprod': '0138',
    img: p0138
  },

  {
    'codcia': '03',
    'codprod': '0136',
    'desccobert': 'Signature',
    img: signature
  },
  {
    'codcia': '03',
    'codprod': '0128',
    'desccobert': 'Plan Estudiantil',
    img: estudiantil
  },
  {
    'codcia': '03',
    'codprod': '0149',
    'desccobert': 'Distinction Premium',
    img: distinction
  },
  {
    'codcia': '03',
    'codprod': '0135',
    'desccobert': 'Infinity International',
    img: infinity
  },
  {
    'codprod': '0070',
    img: p0070
  },
  {
    'codprod': '0142',
    img: signature
  },
  {
    'codprod': '0071',
    img: p0071
  },
  {
    'codprod': '0072',
    img: p0072
  },
  {
    'codprod': '0136',
    img: p0136
  },
  {
    'codprod': '0144',
    img: p0144
  },
  {
    'codprod': '0142',
    img: p0140
  },
  {
    'codprod': '0149',
    img: p0149
  },
  {
    'codprod': '0140',
    img: p0140
  },
  {
    'codprod': '0170',
    img: p0167
  },
  {
    'codprod': '0165',
    img: p0167
  },
  {
    'codprod': '0163',
    img: signature
  },
  {
    'codprod': '0145',
    img: p0070
  },
  {
    'codprod': '0161',
    img: p0167
  },
  {
    'codprod': '0164',
    img: p0167
  },
  {
    'codprod': '0162',
    img: p0072
  },
  {
    'codprod': '0172',
    img: p0168
  },
  {
    'codprod': '0168',
    img: p0168
  },
  {
    'codcia': '08',
    'codprod': '0168',
    img: p016808
  },
  {
    'codprod': '0171',
    img: p0167
  },
  {
    'codprod': '0167',
    img: p0167
  },
  {
    'codprod': '0166',
    img: p0166
  }
];