//import { styled } from '@material-ui/core/styles';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
//import fondoPlanes from '../../images/fondoPlanes.jpg';

const MyModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InnerModal = styled.div`
  background-color: #FFF;
  padding: 35px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  width: 70%;
  height: 600px;
  overflow: scroll;

  img{
    max-width: 100%;
  }
`;

const GuiaTitle = styled.div`
  width: 50%;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 26px;
  font-weight: 200;
`;

const CapitalAsegurado = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  margin: 20px 0;
`;

const Parametros = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    background-color: #004e7b;
    border-radius: 15px;
    width: 49%;
    padding: 5px 20px;
    color: #FFF;
    text-align: left;
  }
`;
const RequeridosTitle = styled.h3`
  font-size: 20px;
  font-weight: 300;
  margin: 30px 0 15px 0;
  padding-bottom: 15px;
  border-bottom: #CCC solid 1px;
`;
const RequeridosTitleImp = styled.h3`
  font-size: 16px;
  font-weight: 300;
  margin: 30px 0 15px 0;
  padding-bottom: 15px;
  border-bottom: #CCC solid 1px;
`;

const LogoContainer = styled.div`
  display: flex;
`;
const LogoModal = styled.div`
  width: 50%;

  img {
    height: 140px;
  }
`;
const Notas = styled.p`
  font-style: italic;
  font-size: 13px;
`;

const Loading = styled.div`
    text-align: center;
    padding: 50px;

    svg{
      color: #01a9e5;
    }
`;

const StepperContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  div div div div:first-child {
    border: 5px solid #FFF !important;
    box-shadow: 0 0 0 1px #E8E8E8;
  }
  div div div div a {
    line-height: 26px !important;
  }
  div div div div span {
    line-height: 26px !important;
  }
`;

const PasoContainer = styled.div`
  width: 100%;
`;

const PasoContainerImpresion = styled.div`
  width: 100%;
  font-size:1em;
`;

const Wizzard = styled.div`
  width: 920px;
  min-height: 600px;
  margin: 0 auto;
`;

const FromContainer = styled.div`
  width: 840px;
  min-height: 600px;
  margin: 0 auto;
`;

const PasoForm = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const PasoFormColec = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-bottom: 30px;
`;

const PasoName = styled.h4`
  width: 100%;
  text-align: center;
  font-size: 17px;
  color: #B0B0B0;
  margin-top: 60px;
`;

const PlanName = styled.h4`
  width: 100%;
  text-align: center;
  font-size: 17px;
  color: #B0B0B0;
  margin: 40px 0;
`;

const DependientesTitle = styled.h4`
  width: 100%;
  text-align: center;
  font-size: 17px;
  color: #B0B0B0;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const PasoDesc = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #2E2E2B;
  margin-top: 5px;
  margin-bottom: 70px;
`;

const PasoButtons = styled.div`
  width: 100%;
  margin: 60px 0;
  text-align: center;
`;

const DependientesButtons = styled.div`
  width: 100%;
  margin: 40px 0 0 0;
  text-align: center;
`;

const SiguienteButton = styled.button`
  background-color: #00A9E6;
  border: 0;
  border-radius: 7px;
  width: 190px;
  height: 60px;
  padding: 0 20px;
  margin: 0 10px;
  text-align: left;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;

  transition: all 0.2s linear;

  :hover{
    background-color: #009cd4;
  }

  div {
    float: left;
    margin-top: 4px;
  }

  svg{
    float: right;
  }
`;

const cargarButton = styled.button`
  background-color: #FFF;
  border: #CCC solid 1px;
  border-radius: 7px;
  width: 240px;
  height: 60px;
  padding: 0 20px;
  margin: 0 10px;
  text-align: left;
  color: #6c757d;
  font-size: 12px;
  text-transform: uppercase;

  transition: all 0.2s linear;

  :hover{
    background-color: rgba(0, 0, 0, 0.075);
  }

  div {
    float: left;
    margin-top: 4px;
  }

  svg{
    float: right;
  }
`;

const DependientesButton = styled.button`
  background-color: #FFF;
  border: #CCC solid 1px;
  border-radius: 7px;
  width: 240px;
  height: 60px;
  padding: 0 20px;
  margin: 0 10px;
  text-align: left;
  color: #6c757d;
  font-size: 12px;
  text-transform: uppercase;

  transition: all 0.2s linear;

  :hover{
    background-color: rgba(0, 0, 0, 0.075);
  }

  div {
    float: left;
    margin-top: 4px;
  }

  svg{
    float: right;
  }
`;

const AnteriorButton = styled.button`
  background-color: #FFF;
  border: 0;
  border-radius: 7px;
  width: 190px;
  height: 60px;
  padding: 0 20px;
  margin: 0 10px;
  text-align: left;
  color: #adb5bd;
  font-size: 12px;
  text-transform: uppercase;

  transition: all 0.2s linear;

  :hover{
    background-color: #F9F9F9;
  }

  div {
    float: left;
    margin-top: 4px;
  }

  svg{
    float: left;
    transform: rotate(180deg);
    margin-right: 10px;
  }
  `;
const ActionsButtons = styled.button`
  background-color: #FFF;
  border: 0;
  border-radius: 7px;
  width: 190px;
  height: 60px;
  padding: 0 20px;
  margin: 0 10px;
  text-align: left;
  color: #343a40;
  font-size: 12px;
  text-transform: uppercase;

  transition: all 0.2s linear;

  :hover{
    background-color: #F9F9F9;
  }

  div {
    float: left;
    margin-top: 4px;
  }

  svg{
    float: right;
  }
  `;
const Planes = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const PlanBreak =styled.div`
  flex-basis: 100%;
  height: 0;
`;
const Plan = styled.div`
  width: 280px;
  margin: 10px;
  border-width: 3px;
  border-radius: 9px;
  border-color: #DFE1E6;
  border-style: solid;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);

  transition: all 0.2s linear;

  :hover{
    border-color: rgba(0, 169, 230, .5);;
  }
`;

const FondoPlan = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-image: ${props => `url("${props.bgImage}")`};
  background-size: cover;
  width: 100%;
  height: 152px;
  border-width: 5px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  span {
    flex: 1;
    align-items: flex-start;
    height: 100%;
    border-radius: 0; 
  }

  span > span {
    justify-content: flex-end;  
  }

  svg{
    color: #00A9E6;
    font-size: 2.5rem !important;
  }

`;

const DetallesPlan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px;
`;

const TituloPlan = styled.div`
  height: 100%;
  font-size: 17;
  color: #00A9E6;
  margin: 0;
`;

const BeneficiosPlan = styled.div`
  height: 100%;
  font-size: 12px;
  color: #A2A2A2;

  button{
    font-size: 10px;
    color: #A2A2A2;
    text-transform: initial;
    padding: 2px 8px;
    border: #ccc solid 1px;
    border-radius: 3px;
  }
  ul{
    margin: 0;
    padding: 0;
  }

`;

const BeneficiosList = styled.ul`
  margin: 0;
  padding: 5px 5px 5px 20px;
  list-style: none;
  li{
    font-weight: normal;
    margin: 5px 0;
  }
  li::before {
    content: '•';
    color: #c2c2c2;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

`;

const FieldRow = styled.div`
  display: flex;
  margin: 0 0 20px 0;

  > div{
    width: 50%;
    padding: 0 10px;
  }
  > div div{
    width: 100%;
  }
  div > label{
    padding: 0 15px;
    width: 100%;
    font-size: 0.90rem;
  }
`;

const FieldRowOne = styled.div`
  display: flex;
  margin: 20px 0;

  > div{
    width: 80%;
    padding: 0 10px;
    margin: 0 auto;
  }
  > div div{
    width: 100%;
  }
  div > label{
    padding: 0 15px;
    width: 100%;
    font-size: 0.90rem;
    margin: 0 auto;
    color: #6c757d;
  }
`;

const FieldRowPrimaEsperada = styled.div`
  display: flex;
  margin: 50px 0 30px 0;

  > div{
    width: 53%;
    padding: 0 10px;
    margin: 0 auto;
  }
  div > label{
    padding: 0 15px;
    width: 100%;
    font-size: 0.90rem;
    margin: 0 auto;
  }
  fieldset {
    border-color: #212529;
  }
  input {
    font-size: 24px;
    font-weight: 300;
  }
`;

const FieldRowFumador = styled.div`
  display: flex;
  margin: 0 0 20px 0;

  > div{
    width: 50%;
    padding: 0 10px;
  }
  > div div{
    width: 100%;
    flex-direction: row;
  }
`;

const FieldRowBeneficios = styled.div`
  display: flex;
  margin: 0 0 20px 0;

  > div{
    width: 48%;
    padding: 0 10px;
    margin-bottom:15px;
  }
  > div div {
    flex-direction: row;
    width: 100%;
  }
  /* > div div label{
    width: 48%;
  } */
`;

const Coberturas = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F9F7F5;
  margin: 0;
  padding: 15px;
  font-size: 12px;
  text-transform: uppercase;
`;

const CoberturasTitulo = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 15px 0;
`;

const CoberturasRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
  font-size: 12px;
`;

const CoberturasName = styled.div`
  color: #A1A1A1;
  font-size: 12px;
`;

const CoberturasDesc = styled.div`
  color: #2E2E2B;
  font-size: 12px;
  text-align: right;
`;

const PrimasContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
`;

const PrimasHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #157DBA;
  color: #FFF;
  padding: 10px 15px;
`;

const PrimasDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Prima = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: #F9F7F5;
    color: #2E2E2B;
    width: 50%;
    text-align: right;
    padding: 6px;
    margin-bottom: 1px;
    font-weight: 700;

    span:first-child{
      font-weight: normal;
      margin-right: 10px;
    }
    span:last-child{
      min-width: 90px;
      /* padding-right: 10px; */
      display: block;
    }
`;

const Disclaimer = styled.div`
  a{
    font-size: 12px;
    color: #157DBA;
    text-decoration: underline;
    cursor: pointer;
    margin: 15px 0;
    display: block;
  }
  p{
    font-size: 10px;
    color: #A2A2A2;
    line-height: 20px;
  }
`;

const Hidden = styled.div`
  display: none;
`;

const HeaderCotizacion = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-bottom: 60px;
  border-bottom: #000 solid 3px;

  div:first-child{
    width:60%
  }

  img{
    max-height:110px;
  }
`;

const CotizacionDetails =styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 40px 0;
  width: 100%;

  > div{
    width: 50% !important;
  }

`;

const CotizacionDetailsDos = styled.div`
  width:40%;
`;

const PlanTable = styled.table`
  width: 100%;
  margin-bottom: 30px;
`;

const PlanCell = styled.td`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;

  h4{
    font-size: 12px;
    color: #888;
  }

  span{
    border-bottom: #000 solid 1px;
    display: block;
    padding-bottom: 8px;
  }
`;
const CoberturasTituloPrint = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
`;

const PlanResumenPrimasCell = styled.td`
  background: #157dba;
  color: #FFF;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
`;

const PlanResumenPrimasTotal = styled.td`
  background: #157dba;
  color: #FFF;
  text-align: right;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
`;

const PlanPrimasCell = styled.td`
  background: #f9f7f5;
  vertical-align: middle;
  padding: 7px;
  font-size: 12px;

  span{
    display:block;
    text-align: right;
  }
`;

const Firma = styled.div`
  width: 200px;
  border-top: #000 solid 1px;
  margin: 50px auto 10px auto;
  text-align: center;
  font-size: 14px;
`;

const TermValueTable = styled.table`
  width: 100%;
  margin: 30px 0;

  th {
    background-color: #daebff;
    padding: 5px;
    text-align: center;
  }
  td {
    padding: 2px;
    text-align: center;
  }

`;
//SALUD

const TermValueNumberCell = styled.td`
  text-align: right !important;
  padding-right: 12px !important;

`;

const TituloDependiente = styled.h5`
  color: #b0b0b0;
  font-size: 1em;
`;
const DeducibleContainer = styled.div`
  border: 0;
  width: 100%;
  background: #F9F7F5;
  text-transform: uppercase;
  padding: 15px 0 0 0;
  font-size: 12px;
  margin-bottom: 50px;
`;
const DeducibleTable = styled.table`
  border:0;
  width: 100%;
`;

const DecucibleRow = styled.tr`
border-top: ${props => props.primaRow ? '#FFF solid 2px' : 'none'};
`;

const ContainerTable = styled.table`
  width: 100%;
`;

const PrimaColumn = styled.td`
  text-align: right;
  background: ${props => props.bgwhite ? '#FFF' : 'inherit'};
  color: #212529;
  padding: 7px 5px 7px 5px;
  width: 230px;
`;

const DecucibleColumn = styled.td`
  text-align: right;
  background: ${props => props.th ? '#157DBA' : 'inherit'};
  color: ${props => props.th ? '#FFF' : '#212529' };
  padding: ${props => props.last ? '7px 15px 7px 5px' : '7px 5px 7px 5px' };
  display: ${props => props.hidden ? 'none' : 'table-cell'};
  min-width: 35px;
  max-width: 114px;
`;

const DecucibleColumnParentezco = styled.td`
  text-align: right;
  background: ${props => props.th ? '#157DBA' : 'inherit'};
  color: ${props => props.th ? '#FFF' : '#212529' };
  padding: ${props => props.last ? '7px 15px 7px 5px' : '7px 5px 7px 5px' };
  display: ${props => props.hidden ? 'none' : 'table-cell'};
  width: 114px;
`;

const DecucibleMainColumn = styled.td`
  width: ${props => props.todos === false ? '350px' : '200px'};
  padding: 7px 0 7px 15px;
  font-weight: ${props => props.bold ? '700' : '500'};
  background: ${props => props.th ? '#157DBA' : 'none'};
  color: ${props => props.th ? '#FFF' : '#212529' };
  font-weight: ${props => props.bold ? '700' : 'inherit' };
`;

export default { 
  InnerModal, MyModal, Loading, StepperContainer, PasoContainer, PasoContainerImpresion, PasoName, 
  PasoDesc, PasoButtons, SiguienteButton, TermValueTable, TermValueNumberCell,
  AnteriorButton, Plan, FondoPlan, TituloPlan,
  DetallesPlan, BeneficiosPlan, Planes, PlanBreak, PasoFormColec,
  FieldRow, FieldRowOne, FieldRowFumador, FieldRowPrimaEsperada, PasoForm, RequeridosTitleImp,
  FieldRowBeneficios, Wizzard, Coberturas, CotizacionDetailsDos,
  CoberturasTitulo, CoberturasRow, CoberturasName, FromContainer,
  CoberturasDesc, Prima, PrimasHeader, PrimasDesc,CotizacionDetails, ContainerTable,
  PrimasContainer, Disclaimer, BeneficiosList, ActionsButtons, Hidden, HeaderCotizacion,
  PlanTable, PlanCell, PlanPrimasCell, PlanResumenPrimasCell, PlanResumenPrimasTotal, CoberturasTituloPrint,
  Firma, LogoModal, GuiaTitle, CapitalAsegurado, Parametros, RequeridosTitle, LogoContainer, Notas,
  TituloDependiente, DependientesTitle, DependientesButton, cargarButton, DependientesButtons, PlanName,
  DeducibleContainer, DeducibleTable, DecucibleRow, DecucibleColumn, DecucibleColumnParentezco, DecucibleMainColumn, PrimaColumn
};