import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RenderTextField from './renderTextField';
import renderCheckbox from './renderCheckbox';
import { createNumberMask } from 'redux-form-input-masks';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircle from '@material-ui/icons/CheckCircle';

const currencyMask = createNumberMask({
  prefix: 'US$ ',
  //suffix: ' per item',
  decimalPlaces: 0,
  locale: 'en-US',
});

class BeneficiosField extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isChecked: false
    };
  }

  onChange(event){
    if (event.target.checked){
      this.setState({isChecked: true});
    }else{
      this.setState({isChecked: false});
    }
  }

  render(){
    const{itemValue} = this.props;
    //console.log(itemValue);
    if(itemValue.suma_variable === "N"){
      //console.log(itemValue);
      return (<div>
        <Field name={itemValue.codcobert} component={renderCheckbox} label={itemValue.desccobert} />
      </div>);
    }else{
      return (
        <div>
          <FormControlLabel
            control={
              <Checkbox
              //checked={input.value.indexOf(itemValue.codcobert) >= 0}
                onChange={this.onChange.bind(this)}
                value={itemValue.codcobert}
                color="default"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircle />}
              />
            }
            label={itemValue.desccobert}
          />
          {this.state.isChecked && 
            <Field
              name={itemValue.codcobert}
              //value={itemValue.sumaBasica}
              component={RenderTextField}
              //label={itemValue.descobert}
              {...currencyMask}
            />
          }
        </div>
    
      );
    }
  }
}

export default BeneficiosField;