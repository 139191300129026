/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from '../../helpers/validate';
//import warn from '../../helpers/warn';
import RenderSelectField from '../common/fields/renderSelectField';
import RenderCheckbox from '../common/fields/renderCheckbox';
import CircularProgress from '@material-ui/core/CircularProgress';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
//import cotizar from '../../helpers/api/salud/cotizar';
import moment from 'moment';
import {displayErrorPolizas} from '../../helpers/alerts';
import {env, cia, cambioParams, cambio} from '../../env';
import axios from 'axios';
import _ from 'lodash';

import {load as cargaValores} from '../../reducers/valores';

class CotizadorPasoTres extends Component {
  constructor(props) {
    super(props);
    this.handleOpcionChange = this.handleOpcionChange.bind(this);
    this.state = {
      planes: [],
      planSelected: '',
      shrink: false,
      loading : true,
      polizas: {},
      asegurados: {},
      poliza: {}
    };
  }

  componentDidMount(){
    //const {valores} = this.props;
    //const hasDependientes = valores.dependientes ? true : false;
    //console.log(valores);

    console.log('CAMBIO: ', cambio, cambioParams);

    // //GET OPCIONES
    // axios.post(`//${env.beURL}/stpr-planes-salud`, {
    //   cia: cia,
    //   codprod: ,
    //   codramo: hasDependientes ? '0002' : '0001'
    // }).then(
    //   res => {
    //     //console.log(res);
    //     let planes;
    //     if(res.data.data){
    //       planes = res.data.data;
    //     } else {
    //       planes = res.data;
    //     }
    //     this.setState({planes, loading: false});
    //     //console.log('planes: ', planes);
    //   }
    // ).catch(
    //   () => {
    //     errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
    //   }
    // );

    // GET POLIZA
    axios.all([axios.get(`//${env.beURL}/polizas?noPoliza=${cambioParams.poliza}`),
      axios.get(`//${env.beURL}/asegurados?$limit=0&noPoliza=${cambioParams.poliza}`)])
      .then(axios.spread((polizas, asegurados) => {  
        console.log('poliza: ', polizas.data.data[0], 'polizas: ', polizas.data.data, 'asegurados: ', asegurados.data.data);

        const poliza = polizas.data.data[0];

        const hasDependientes = polizas.data.data.length > 1 ? true : false;
        const isDeducible = cambioParams.deducible ? poliza.idProducto : cambioParams.producto;

        //GET OPCIONES
        axios.post(`//${env.beURL}/stpr-planes-salud`, {
          cia: cia,
          codprod: isDeducible ,
          codramo: hasDependientes ? '0002' : '0001'
        }).then(
          res => {
            //console.log(res);
            let planes;
            if(res.data.data){
              planes = res.data.data;
            } else {
              planes = res.data;
            }
            this.setState({planes, loading: false});
            console.log('planes: ', planes);
          }
        ).catch(error => console.log(error));

        //VALORES
        const asegurado = _.find(asegurados.data.data, (o) => { return o.certificado == 1 &&  (o.codparent == '0001' || o.codparent == '0002') ; });

        console.log(asegurado);

        let valores = {
          nombres: asegurado.nombresAsegurado,
          apellidos: asegurado.apellidosAsegurado,
          sexo: asegurado.sexo == 'M' ? 'H' : 'M',
          fechaDeNacimiento: moment(asegurado.fechaNacimiento).format('YYYY-MM-DD'),
          plan: isDeducible,
          dependientes: []
        };

        _.map(asegurados.data.data, (item, key) => {
          if(item){
            valores.dependientes.push({
              id: key,
              nombres: item.nombresAsegurado,
              apellidos: item.apellidosAsegurado,
              parentesco: item.parentesco == 'HIJO' || item.parentesco == 'HIJA' ? 'H' : 'C',
              sexo: item.sexo == 'M' ? 'H' : 'M',
              edad: moment(item.fechaNacimiento).format('YYYY-MM-DD')
            });
          }
        });

        console.log('VALORES: ', valores);

        if(polizas.data.data[0]){
          this.setState({
            polizas: polizas.data.data, 
            poliza: polizas.data.data[0],
            asegurados: asegurados.data.data, 
            loading: false,
            valores: valores
          });

          this.props.load({valores});
        }

      })).catch(error => console.log(error));

    // {
    //   "nombres": "Carlos",
    //   "apellidos": "Daniel",
    //   "sexo": "H",
    //   "fechaDeNacimiento": "1980-04-10",
    //   "dependientes": [
    //       {
    //           "nombres": "Carlos",
    //           "apellidos": "de Leon",
    //           "parentesco": "H",
    //           "sexo": "H",
    //           "edad": "2001-04-10"
    //       }
    //   ],
    //   "plan": "0136",
    //   "plazoPagos": "S",
    //   "opcion": "0003",
    //   "todosDeducibles": true
    // }
    // .catch(() => {
    //   errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
    // });

    //OPCIONES SHRINK
    // if(this.props.valores.opcion){
    //   this.setState({shrink: true});
    // }
  }
  //const { change, handleSubmit, pristine, previousPage, submitting, rangoCobertura, rangoPagoPrimas = [] } = props;
  handleOpcionChange (event, value){
    console.log(value, this.props.valores);
    if(value){
      this.setState({shrink: true});
    }
  }
  render(){
    const { PasoContainer, PasoName, PasoDesc, PasoForm, FieldRowPrimaEsperada,
      PasoButtons, SiguienteButton, FieldRow, PlanName, FieldRowOne, Loading } = styles;
    const {poliza, loading, valores} = this.state;

    console.log(valores);
    const planSelected = poliza.producto;
    //console.log(productos, planSelected);
    //const primaPrueba = planSelected.primaPrueba.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return (
      <div>
        {!cambio ? displayErrorPolizas('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde') : <div>
          {loading ? <Loading><CircularProgress color='primary' /></Loading>: (
            <PasoContainer>
              <PasoName>Paso 3</PasoName>
              <PasoDesc>Su póliza</PasoDesc>
              <PlanName>Su plan: {planSelected}</PlanName>
              <PasoForm>
                <form onSubmit={this.props.handleSubmit}>
                  <FieldRow>
                    <Field
                      name="plazoPagos"
                      component={RenderSelectField}
                      label={cia === '02'?'Frecuencia de Pagos': 'Forma de Pago'}
                    >
                      <option value=""></option>
                      <option value="M">Mensual</option>
                      <option value="T">Trimestral</option>
                      <option value="S">Semestral</option>
                      <option value="A">Anual</option>
                    </Field>
                    <Field
                      name="opcion"
                      component={RenderSelectField}
                      label="Opción"
                      onChange={this.handleOpcionChange}
                      shrink={this.state.shrink}
                    >
                      <option value=""></option>
                      {this.state.planes.map(item =>
                        <option key={item.codplan} value={item.codplan}>{item.descplan}</option>
                      )};
                    </Field>
                  </FieldRow>
                  <FieldRowPrimaEsperada>
                    {/* <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Prima Esperada</InputLabel>
                  <OutlinedInput
                    id="primaEsperada"
                    label="Prima Esperada"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    //defaultValue={primaPrueba}
                    readOnly
                  />
                </FormControl> */}
                  </FieldRowPrimaEsperada>
                  <FieldRowOne>
                    {this.state.planes.length >= 3 &&
                <Field
                  name="todosDeducibles"
                  component={RenderCheckbox}
                  label="Mostrar todos los deducibles"
                />}
                  </FieldRowOne>
                  <PasoButtons>
                    <SiguienteButton type="submit" className="next" disabled={this.props.pristine || this.props.submitting}>
                      <div>Siguiente</div>
                      <TrendingFLat />
                    </SiguienteButton>
                  </PasoButtons>
                </form>
              </PasoForm>
            </PasoContainer>
          )}
        </div>}
      </div>
    );
  }
  
}

CotizadorPasoTres.propTypes = {
  handleSubmit: PropTypes.any,
  input: PropTypes.object
};

CotizadorPasoTres = reduxForm({
  form: 'cotizadorSalud', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(CotizadorPasoTres);

/*
CotizadorPasoUno = connect(
  state => ({
    cliente: selector(state, 'cliente'),
    initialValues : state.certificados.data,
  }),
  {load : cargaCertificados}
)(CotizadorPasoUno);
*/

//const selector = formValueSelector('cotizadorSalud');
CotizadorPasoTres = connect(
  state => ({
    //cliente: selector(state, 'cliente'),
    initialValues : state.valores.data,
  }),
  {load : cargaValores}
  // state => {
  //   const { fechaDeNacimiento, periodoCobertura, plazoPagos, sumaAsegurada, duracionPrimas, plan
  //   } = selector(state, 'fechaDeNacimiento', 'plan', 'periodoCobertura', 'plazoPagos', 'sumaAsegurada', 'duracionPrimas', 'rangoPrimas');
  //   const edad = moment().diff(fechaDeNacimiento, 'years');
  //   //const planSelected = _.find(this.props.productos, function(o) { return o.codprod === plan; });
  //   return {
  //     fechaDeNacimiento,
  //     edad,
  //     periodoCobertura,
  //     plazoPagos,
  //     sumaAsegurada, 
  //     duracionPrimas,
  //     plan,
  //     valores: getFormValues('cotizadorSalud')(state)
  //   };
  // }
)(CotizadorPasoTres);

export default CotizadorPasoTres;