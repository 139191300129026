/* eslint-disable no-prototype-builtins */
/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
import styles from './index.styles';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import Email from '@material-ui/icons/Email';
import NumberFormat from 'react-number-format';
import Requisitos from './requisitos';
import Loader from '../common/Loader';
//REQUESTS
import cotizar from '../../helpers/api/vida/cotizar';
import {saveCotizacion} from '../../helpers/api/vida/saveCotizacion';
import {saveCoberturas} from '../../helpers/api/vida/saveCoberturas';
import getRequisitos from '../../helpers/api/vida/requisitos';
import enviarCorreo from '../../helpers/api/vida/enviarCorreo';
import createPDF from '../../helpers/api/vida/createPDF';
//ALERTS
import {alertMessage, successMessage, errorMessage} from '../../helpers/alerts';
//REQUISITOS MODAL
//import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
//HELPERS
//import ReactToPrint from 'react-to-print';
import _ from 'lodash';
import moment from 'moment';

//import axios from 'axios';
import {cia, env, source, pais} from '../../env';
//${env.envURL}/productos${env.beMethod}
//const currentURl = `//${env.envURL}/coberturas${env.beMethod}`;

class CotizadorCotizacion extends Component {
  constructor(props) {
    super(props);
    this.enviarPorCorreo = this.enviarPorCorreo.bind(this);
    this.verCotizacion = this.verCotizacion.bind(this);
    this.nuevaCotizacion = this.nuevaCotizacion.bind(this);
    this.sumaPrimas = this.sumaPrimas.bind(this);
    this.state = {
      cotizacionId: '',
      cotizacion: {},
      primaAnual: {},
      primaSemestral: {},
      primaTrimestral: {},
      primaMensual: {},
      primas: [],
      sumaPrimas: null,
      sumaImpuestos: null,
      sumaCargosAdm: null,
      loading: false,
      error: false,
      hideForPrint: false,
      isTermValue: false,
      isSurvivor: false,
      selectedPlan: {},
      open: false,
      primaTarget: 0,
      requisitos: {}
    };
  }

  componentDidMount() {
    //START PRELOADER
    this.setState({loading: true});

    const {valores, productos} = this.props;
    //console.log(valores);

    //PLAN SELECCIONADO
    const planSelected = _.find(productos, { 'codprod': valores.plan, 'codcobert': 'VIDA' });
    this.setState({selectedPlan: planSelected});

    //TERM VALUE    
    const isTermValue = valores.plan === '0187' || valores.plan === '0183' || planSelected.tipo_producto === 'TERM_VALUE' ? true : false;
    const isSurvivor = valores.plan === '0191' || planSelected.tipo_producto === 'SURVIVOR' ? true : false;

    //console.log(isSurvivor);

    //TERM VALUE    
    if(isTermValue){
      this.setState({isTermValue: true});
    }

    //SURVIVOR  
    if(isSurvivor){
      this.setState({isSurvivor: true});
    }

    //Filtra Beneficios(Coberturas) y Asigna los valores
    const valoresKeys = _.keys(valores);
    //Agrega Cobertura por defecto
    valoresKeys.push('VIDA');
    //Si es TermValue
    if(isTermValue || isSurvivor){
      valoresKeys.push('VIVE');
    }

    const coberturasPlan = _.filter(productos, (o) => {
      return o.codprod === valores.plan;
    });

    const coberturasFiltradas = _.chain(coberturasPlan)
      .filter((cob) => {
        return _.includes(valoresKeys, cob.codcobert) || _.includes(valoresKeys, cob.dependencia);
      }).map((el) => {
        let o = Object.assign({}, el);
        let sumaBasica = (el.sumaBasica !== 0) ? el.sumaBasica : 0;
        let calcSumaAsegurada = (el.porcSumaBasica * valores.sumaAsegurada) / 100;
        let porcSumaAsegurada = calcSumaAsegurada >= el.sumaAsegMax ? el.sumaAsegMax : calcSumaAsegurada;
        let cumplerango = valores.sumaAsegurada >= el.montoDesde && valores.sumaAsegurada <= el.montoHasta;
        //console.log(el, valores.hasOwnProperty(el.codcobert), cumplerango, sumaBasica, el.porcSumaBasica, porcSumaAsegurada);
        //o.sumaAseguradaPrimas = valores.sumaAsegurada;
        // if(el.orden === 0){
        //   //console.log(o.orden);
        //   o.orden = 3;
        // }
        // if(el.codcobert === 'VIDA'){
        //   o.orden = 1;
        // }
        //TIENE UN BENEFICIO?
        if(valores.hasOwnProperty(el.codcobert)){
          console.log("VALOR COBERTURA: ", isNaN(valores[el.codcobert]), 'VALOR: ', valores[el.codcobert], 'ES INCL: ', valores.INCL );
          if(valores[el.codcobert] === true){
            if(valores.hasOwnProperty(el.codcobert) && cumplerango){
              o.sumaAsegurada = sumaBasica;
              o.sumaAseguradaPrimas = sumaBasica;
            }
            //MIENTRAS PIENSO ALGO MEJOR         
            // if(valores.hasOwnProperty(el.codcobert) && !valores.INCL){
            //   o.sumaAsegurada = valores.sumaAsegurada;
            //   o.sumaAseguradaPrimas = valores.sumaAsegurada;
            // }
            //EXON
            if(o.codcobert !== 'INCL'){
              o.sumaAsegurada = valores.sumaAsegurada;
              o.sumaAseguradaPrimas = valores.sumaAsegurada;
            }
          }
          if(valores[el.codcobert] !== true){
            o.sumaAsegurada = valores[el.codcobert];
            o.sumaAseguradaPrimas = valores[el.codcobert];
          }  
        } 
        if(valores.hasOwnProperty(el.codcobert) && el.porcSumaBasica > 0 && valores[el.codcobert] === true){
          o.sumaAsegurada = porcSumaAsegurada;
        }
        if(el.codcobert === 'VIVE' || el.codcobert === 'VIDA'){
          o.sumaAsegurada = valores.sumaAsegurada;
          o.sumaAseguradaPrimas = valores.sumaAsegurada;
        }
        //INCLUDE INCL DEPENDENCIES
        if(valores.hasOwnProperty('INCL') && el.dependencia === 'INCL'){
          //console.log('INCL: ', o);
          //o.orden = 4;
          o.sumaAsegurada = o.sumaAsegMax;
          o.sumaAseguradaPrimas = o.sumaAsegMax;
        }

        console.log(o, valores[el.codcobert]);
        return o;
      }).value();
    //Flatea y devuelve coberturas unicas
    //const coberturas = _.uniqBy(coberturasFiltradas, 'codcobert');
    //console.log(coberturasFiltradas);

    const coberturasConSumaAsegurada = _.filter(coberturasFiltradas, (o) => {
      return o.hasOwnProperty('sumaAsegurada');
    });

    const sortedCoberturas = _.sortBy(coberturasConSumaAsegurada, [(o) => { return o.orden; }]);
    //console.log(sortedCoberturas);
    //IS RENTA INVALIDEZ
    const isRentaInvalidez = _.find(coberturasConSumaAsegurada, (o) => { return o.codcobert === 'INCL'; });
    const rentaInvalidez = isRentaInvalidez ? 'S' : 'N';

    console.log(isRentaInvalidez, rentaInvalidez);

    console.log(
      'Valores: ', valores,
      'Keys: ', valoresKeys,
      'Filtradas: ', coberturasFiltradas,
      'conSuma: ', coberturasConSumaAsegurada,
      'Orden: ', sortedCoberturas,
      'Renta Invalidez: ', rentaInvalidez
    );

    

    //COTIZAR
    cotizar(this.props.valores, sortedCoberturas, rentaInvalidez, isSurvivor).then((res) => {
      console.log('COTIZANDO: ', res);
      if(res){

        const primaAnual = _.find(res, (o) => { return o.pformapago === 'A'; });
        const primaSemestral = _.find(res, (o) => { return o.pformapago === 'S'; });
        const primaTrimestral = _.find(res, (o) => { return o.pformapago === 'T'; });
        const primaMensual = _.find(res, (o) => { return o.pformapago === 'M'; });
        const selectedPayForm = _.find(res, (o) => { return o.pformapago === this.props.valores.plazoPagos; });
  
        console.log(selectedPayForm, primaAnual, primaSemestral, primaTrimestral, primaMensual);

        this.setState({
          cotizacion: selectedPayForm,
          primaAnual: primaAnual,
          primaSemestral: primaSemestral,
          primaTrimestral: primaTrimestral,
          primaMensual: primaMensual,
          primas: sortedCoberturas,
          primaTarget: selectedPayForm.outprimatarget
        });


        if(selectedPayForm.cerror === '0'){
          errorMessage(
            'Lo Sentimos', 'Prima Esperada Insuficiente.'
          );
          this.setState({error: true});
        }else{
          //STOP PRELOADER
          this.setState({loading: false});
        }

        // SAVE COTIZACION
        saveCotizacion(this.props.valores, this.props.user, res, this.props.userInfo.CodBroker).then((res) => {
          //SET ID
          this.setState({cotizacionId: res.id});
          //SAVE PDF
          createPDF(this.props.valores, this.props.user, this.state.cotizacion, 
            this.state.cotizacionId, this.state.selectedPlan, this.state.isTermValue, this.state.isSurvivor, this.state.requisitos,
            this.props.edad, this.props.esFumador, this.props.sexoName, this.props.plazoPagosDesc, this.state.primas,
            this.props.fechaTermino, this.state.primaAnual, this.state.primaSemestral, this.state.primaTrimestral, this.state.primaMensual, cia, pais).then((res) => {
            //console.log(res);
            if(res === 'error'){
              errorMessage(
                'Lo Sentimos', 'Ha ocurrido un error, por favor inténtelo mas tarde.'
              );
            }
            //console.log(res);
          }).catch(error => {alertMessage(error);});
          
          //SAVE COBERTURAS
          saveCoberturas(this.state.cotizacion, this.state.primas, this.state.cotizacionId).then((res) => {
            //console.log(res);
            if(res === 'error'){
              errorMessage(
                'Lo Sentimos', 'Ha ocurrido un error, por favor inténtelo mas tarde.'
              );
            }
            //console.log(res);
          }).catch(error => {alertMessage(error);});

        }).catch(error => { alertMessage(error);});
      }
    }).catch(error => { alertMessage(error);});

    //REQUISITOS
    getRequisitos(this.props.edad, this.props.sumaAsegurada).then((res) => {
      this.setState({requisitos: res});
      console.log(res);
    }).catch(error => { alertMessage(error);});
  }

  //SUMA PRIMAS / IMPUESTOS / COUTA
  sumaPrimas(key, array){
    return array.reduce((a, b) => a + (b[key] || 0), 0);
  }

  nuevaCotizacion(){
    const currentURl = `//${env.envURL}`;
    window.open(`${currentURl}?cia=${cia}`, '_self');
  }

  //Render Primas
  renderPrimas() {
    const { primas, loading, error, cotizacion } = this.state;
    const {CoberturasRow, CoberturasName, CoberturasDesc } = styles;
    const isSurvivor = this.props.valores.plan === '0191' || this.props.valores.plan === '0208' || this.props.valores.plan === '0215' || this.props.valores.plan === '0216' ? true : false;
    const isTermValue = this.props.valores.plan === '0187' || this.props.valores.plan === '0183' || this.props.valores.plan === '0214' ? true : false;
    
    console.log(isSurvivor);

    if (loading) {
      return <Loader />;
    }
    if (error) {
      return 'Ha ocurrido un error';
    }
    return primas.map(prima => {
      if(prima.sumaAsegurada > 0 && prima.dependencia === null){
        return(<CoberturasRow key={prima.codcobert}>
          <CoberturasName>{prima.desccobert}</CoberturasName>
          {isSurvivor &&
            <CoberturasDesc>
              { prima.codcobert === 'VIVE' ? <NumberFormat value={cotizacion.outSumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }
            </CoberturasDesc>
          }
          {isTermValue &&
            <CoberturasDesc>
              { prima.codcobert === 'VIVE' ? <NumberFormat value={cotizacion.outSumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }
            </CoberturasDesc>
          }
          {!isTermValue && !isSurvivor &&
            <CoberturasDesc>
              <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </CoberturasDesc>
          }
          {/* <CoberturasDesc>
            {isSurvivor && prima.codcobert === 'VIVE' ? <NumberFormat value={cotizacion.outSumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
            {isTermValue && prima.codcobert === 'VIVE' ? <NumberFormat value={cotizacion.outSumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> :  <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
            {!isSurvivor && !isTermValue ? <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ''}
            {prima.codcobert === 'VIVE' ? <NumberFormat value={cotizacion.outSumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }
            <NumberFormat value={prima.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </CoberturasDesc> */}
        </CoberturasRow>);
      }
    });
  }

  renderPropuestas() {
    const { cotizacion, primaSemestral, primaTrimestral, primaMensual, loading } = this.state;
    const { plazoPagos } = this.props;
    const {PrimasDesc, Prima } = styles;

    //console.log(cotizacion);
    if (loading) {
      return <Loader />;
    }
    if(cia !== '03'){
      return(
      //   if(plazoPagos === 'A'){return 'Anual';}
      // if(plazoPagos === 'S'){return 'Semestral';}
      // if(plazoPagos === 'M'){return 'Mensual';}
      // return 'Trimestral';
        <div>
          {/* { plazoPagos === 'A' &&
            <PrimasDesc>
              <Prima>
                <span>Prima Anual</span>
                <span>
                  <NumberFormat 
                    value={Number(primaAnual.outPrimaNeta).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima>
            </PrimasDesc>
          } */}
          { plazoPagos === 'S' &&
            <PrimasDesc>
              {/* <Prima>
                <span>Prima Anual</span>
                <span>
                  <NumberFormat 
                    value={Number(primaAnual.outPrimaNeta).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima> */}
              <Prima>
                <span>Prima Semestral</span>
                <span>
                  <NumberFormat 
                    value={ Number(Number(primaSemestral.outPrimaNeta) + Number(primaSemestral.outImpuestos) + Number(primaSemestral.outCargoAdmin)).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima>
            </PrimasDesc>
          }
          { plazoPagos === 'M' &&
            <PrimasDesc>
              {/* <Prima>
                <span>Prima Anual</span>
                <span>
                  <NumberFormat 
                    value={Number(primaAnual.outPrimaNeta).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima> */}
              <Prima>
                <span>Prima Semestral</span>
                <span>
                  <NumberFormat 
                    value={ Number(Number(primaSemestral.outPrimaNeta) + Number(primaSemestral.outImpuestos) + Number(primaSemestral.outCargoAdmin)).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima>
              <Prima>
                <span>Prima Trimestral</span>
                <span>
                  <NumberFormat 
                    value={ Number(Number(primaTrimestral.outPrimaNeta) + Number(primaTrimestral.outImpuestos) + Number(primaTrimestral.outCargoAdmin)).toFixed(2)}
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima>
              <Prima>
                <span>Prima Mensual</span>
                <span>
                  <NumberFormat 
                    value={ Number(Number(primaMensual.outPrimaNeta) + Number(primaMensual.outImpuestos) + Number(primaMensual.outCargoAdmin)).toFixed(2)}
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima>
            </PrimasDesc>
          }
          { plazoPagos === 'T' &&
            <PrimasDesc>
              {/* <Prima>
                <span>Prima Anual</span>
                <span>
                  <NumberFormat 
                    value={Number(primaAnual.outPrimaNeta).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima> */}
              <Prima>
                <span>Prima Semestral</span>
                <span>
                  <NumberFormat 
                    value={ Number(Number(primaSemestral.outPrimaNeta) + Number(primaSemestral.outImpuestos) + Number(primaSemestral.outCargoAdmin)).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima>
              <Prima>
                <span>Prima Trimestral</span>
                <span>
                  <NumberFormat 
                    value={ Number(Number(primaTrimestral.outPrimaNeta) + Number(primaTrimestral.outImpuestos) + Number(primaTrimestral.outCargoAdmin)).toFixed(2)}
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'$'} />
                </span>
              </Prima>
            </PrimasDesc>
          }
        </div>
      );
    }
    if(cia === '03'){ 
      return(<PrimasDesc>
        <Prima>
          <span>Prima</span>
          <span>
            {!this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(this.state.sumaPrimas) + Number(cotizacion.outPrimaNeta)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
            {this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(cotizacion.outPrimaNeta)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
          </span>
        </Prima>
        <Prima>
          <span>Impuestos</span>
          <span>
            {!this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(this.state.sumaImpuestos) + Number(cotizacion.outImpuestos)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
            {this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(cotizacion.outImpuestos)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
          </span>
        </Prima>
        <Prima>
          <span>Cargo Administrativo</span>
          <span>
            {!this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(cotizacion.outCargoAdmin)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
            {this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(cotizacion.outCargoAdmin)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
          </span>
        </Prima>
        <Prima>
          <span>TOTAL</span>
          <span>
            {!this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(this.state.sumaPrimas) + Number(cotizacion.outPrimaNeta)+Number(cotizacion.outCargoAdmin)+Number(cotizacion.outImpuestos)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
            {this.state.isTermValue && 
            <NumberFormat 
              value={Number(Number(cotizacion.outPrimaNeta)+Number(cotizacion.outCargoAdmin)+Number(cotizacion.outImpuestos)).toFixed(2)} 
              displayType={'text'} 
              thousandSeparator={true} prefix={'$'} />
            }
          </span>
        </Prima>
      </PrimasDesc>);
    }
  }

  //Render Term Value
  renderTermValue() {
    const { valores, edad } = this.props;
    const { primaAnual } = this.state;
    const duracionPrimas = Number(valores.periodoCobertura) + 1;
    const rangoPagoPrimas = Array.from(Array(duracionPrimas).keys()).slice(1);
    const { TermValueNumberCell } = styles;


    //console.log(valores, edad, cotizacion);
    
    return rangoPagoPrimas.map(item => {
      return(<tr key={item}>
        <td>{item}</td>
        <td>{Number(item + Number(edad))}</td>
        {cia === '03' && 
         <TermValueNumberCell>
           {item <= valores.duracionPrimas &&
         <NumberFormat value={primaAnual.outPrimaNeta} displayType={'text'} thousandSeparator={true} prefix={'$'} />
           }
         </TermValueNumberCell>       
        }
        {cia !== '03' && 
         <TermValueNumberCell>
           {item <= valores.duracionPrimas &&
         <NumberFormat value={Number(Number(primaAnual.outPrimaNeta) + Number(primaAnual.outImpuestos) + Number(primaAnual.outCargoAdmin)).toFixed(2)} 
           displayType={'text'} thousandSeparator={true} prefix={'$'} />
           }
         </TermValueNumberCell>       
        }
        {/* <td>
          {item <= valores.duracionPrimas &&
          <NumberFormat value={cotizacion.outPrimaNeta} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          }
        </td> */}
        <TermValueNumberCell>
          <NumberFormat value={valores.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </TermValueNumberCell>
      </tr>);
    });
  }

  //Render SURVIVOR
  renderSurvivor() {
    const { valores, edad } = this.props;
    const { cotizacion, primaAnual } = this.state;
    const duracionPrimas = Number(valores.periodoCobertura) + 1;
    const rangoPagoPrimas = Array.from(Array(duracionPrimas).keys()).slice(1);

    const outValoresAcumulados = cotizacion.outValorAcumulado ? cotizacion.outValorAcumulado.split(',') : '';
    const outValorRescate = cotizacion.outValorRescate ? cotizacion.outValorRescate.split(',') : '';

    const { TermValueNumberCell } = styles;
    
    //console.log(outValoresAcumulados, outValorRescate);
  
    //console.log(valores, edad, cotizacion);
      
    return rangoPagoPrimas.map(item => {
      return(<tr key={item}>
        <td>{item}</td>
        <td>{Number(item + Number(edad))}</td>
        {cia === '03' && 
           <TermValueNumberCell>
             {item <= valores.duracionPrimas &&
           <NumberFormat value={primaAnual.outPrimaNeta} displayType={'text'} thousandSeparator={true} prefix={'$'} />
             }
           </TermValueNumberCell>       
        }
        {cia !== '03' && 
           <TermValueNumberCell>
             {item <= valores.duracionPrimas &&
           <NumberFormat value={Number(Number(primaAnual.outPrimaNeta) + Number(primaAnual.outImpuestos) + Number(primaAnual.outCargoAdmin)).toFixed(2)} 
             displayType={'text'} thousandSeparator={true} prefix={'$'} />
             }
           </TermValueNumberCell>       
        }
        {/* <td>
            {item <= valores.duracionPrimas &&
            <NumberFormat value={cotizacion.outPrimaNeta} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            }
          </td> */}
        <TermValueNumberCell><NumberFormat value={outValoresAcumulados[item-1]} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TermValueNumberCell>
        <TermValueNumberCell><NumberFormat value={outValorRescate[item-1]} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TermValueNumberCell>
        <TermValueNumberCell>
          <NumberFormat value={valores.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </TermValueNumberCell>
      </tr>);
    });
  }

  enviarPorCorreo(){
    //const mensaje = this.renderReactToString();
    //const impresion = this.componentRef.current;

    enviarCorreo(this.props.user, this.state.cotizacionId, this.state.selectedPlan).then((res) => {
      //console.log(res);
      if(res === 'error'){
        errorMessage(
          'Lo Sentimos', 'Ha ocurrido un error, por favor inténtelo mas tarde.'
        );
      }
      successMessage('Listo!','La cotizacion fue enviada a su correo electrónico');
      //console.log(res);
    }).catch(error => {alertMessage(error);});
  }

  verCotizacion(){
    const currentURl = `//${env.beURL}`;
    window.open(`${currentURl}/tmp/cotizacion-${this.state.cotizacionId}.pdf`, '_blank');
    //window.open(`https://cotizadores.wwseguros.com.do:30443/tmp/cotizacion-${this.state.cotizacionId}.pdf`, '_blank');
  }

  solicitar(){
    //const currentURl = `//${env.beURL}`;
    window.open(`https://portal.wwseguros.com.do/dashboard/requests/new-requests/vida/cotizacion/${this.state.cotizacionId}`, '_blank');
    //window.open(`https://wwsdevfrontend-desadevfrontend.azurewebsites.net/dashboard/requests/new-requests/vida/cotizacion/${this.state.cotizacionId}`, '_blank');
  }

  render(){

    //MODAL STYLES AN HANDLERS
    const handleOpenM = () => {
      this.setState({open: true});
    };
  
    const handleCloseM = () => {
      this.setState({open: false});
    };

    const {cotizacion, loading, primaAnual, error, primaTarget, isSurvivor} = this.state;

    const { MyModal, PasoContainer, PasoDesc, PasoForm, PasoButtons, 
      SiguienteButton, AnteriorButton, PasoName, 
      FieldRow, Coberturas, CoberturasTitulo, CoberturasRow, CoberturasName,
      CoberturasDesc, PrimasHeader, PrimasContainer, Disclaimer, ActionsButtons, TermValueTable
    } = styles;

    return (
      <PasoContainer>
        <PasoName></PasoName>
        <PasoDesc>Cotización</PasoDesc>
        {loading && <Loader />}
        
        <PasoForm>
          {!loading && 
          <form onSubmit={this.props.handleSubmit}>
            <FieldRow>
              <TextField
                id="fullName"
                label="Cliente"
                defaultValue={this.props.fullName}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="plan"
                label="Plan"
                value={this.state.selectedPlan.descprod}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </FieldRow>
            <FieldRow>
              <TextField
                id="edad"
                label="Edad"
                defaultValue={this.props.edad}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="periodoCobertura"
                label="Periodo de Cobertura"
                defaultValue={`${this.props.periodoCobertura} años`}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            </FieldRow>
            <FieldRow>
              <TextField
                id="fumador"
                label="Condición de Fumador"
                defaultValue={this.props.esFumador}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="duracionPrimas"
                label="Duración de pago de Primas"
                defaultValue={`${this.props.duracionPrimas} años`}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            </FieldRow>
            <FieldRow>
              <TextField
                id="sexo"
                label="Sexo"
                defaultValue={this.props.sexoName}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="plazoPagos"
                label="Frecuencia de Pagos"
                defaultValue={this.props.plazoPagosDesc}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            </FieldRow>
            <Coberturas>
              <CoberturasTitulo>RESUMEN DE COBERTURAS</CoberturasTitulo>
              {this.renderPrimas()}
              <CoberturasRow>
                <CoberturasName>Término del Plan</CoberturasName>
                <CoberturasDesc>{this.props.fechaTermino}</CoberturasDesc>
              </CoberturasRow>
              {isSurvivor &&
              <CoberturasRow>
                <CoberturasName>Prima Target</CoberturasName>
                <CoberturasDesc>
                  <NumberFormat value={primaTarget} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </CoberturasDesc>
              </CoberturasRow>
              }
            </Coberturas>
            <PrimasContainer>
              <PrimasHeader>
                <span>RESUMEN DE PROPUESTA</span>
                {this.props.valores.plazoPagos === 'A' &&
                  <span>TOTAL: <NumberFormat 
                    value={ Number(Number(cotizacion.outPrimaNeta) + Number(cotizacion.outImpuestos) + Number(cotizacion.outCargoAdmin)).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'US$'} /></span>
                }
                {this.props.valores.plazoPagos !== 'A' &&
                  <span>PRIMA ANUAL: <NumberFormat 
                    value={ Number(Number(primaAnual.outPrimaNeta) + Number(primaAnual.outImpuestos) + Number(primaAnual.outCargoAdmin)).toFixed(2)} 
                    displayType={'text'} 
                    thousandSeparator={true} prefix={'US$'} /></span>
                }
              </PrimasHeader> 
              {this.renderPropuestas()}
              {this.state.isTermValue &&
              <TermValueTable>
                <tbody>
                  <tr>
                    <th>Año</th>
                    <th>Edad</th>
                    <th>Prima</th>
                    <th>Monto Asegurado</th>
                  </tr>
                  {this.renderTermValue()}
                </tbody>
              </TermValueTable>
              }
              {this.state.isSurvivor &&
              <TermValueTable>
                <tbody>
                  <tr>
                    <th>Año</th>
                    <th>Edad</th>
                    <th>Prima</th>
                    <th>Valor Acumulado</th>
                    <th>Valor Rescate <br /> Garantizado</th>
                    <th>Monto Asegurado</th>
                  </tr>
                  {this.renderSurvivor()}
                </tbody>
              </TermValueTable>
              }
            </PrimasContainer>
            <Disclaimer>
              {/* <a href={reqAsegurabilidad} target='_blank' rel='noopener noreferrer'>Requisitos de Asegurabilidad</a> */}
              <a type="button" onClick={handleOpenM}>Requisitos de Asegurabilidad</a>
            
              {cia === '03' && 
            <p>La prima incluye una cuota administrativa de US$ 20.00, pagadera al inicio de cada año.</p>}
              <p>Disclaimer: La presente cotización incluye los impuestos correspondientes. 
              La misma tendrá una vigencia de 30 días calendario desde su emisión y se encuentra sujeta 
              a las Condiciones de la Compañía. La Compañía se reserva el derecho de modificar 
              o anular las tarifas establecidas en la presente cotización previa notificación 
              escrita al Cliente. La(s) cobertura(s) serán garantizadas una vez el Cliente 
              cumpla con las Condiciones de la Compañía para la suscripción y emisión de pólizas.
              <br/><b>País de residencia: {pais.descpais}</b>.
              </p>
              {cia !== '03' &&
              <p>“Regulado y Supervisado por la Superintendencia de Seguros y Reaseguros de Panama”</p>
              }
            </Disclaimer>
            {!this.state.hideForPrint &&
            <div>
              <PasoButtons className='noPrint'>
                <ButtonGroup>
                  <AnteriorButton type="button" className="previous" onClick={this.props.previousPage}>
                    <TrendingFLat />
                    <div>Volver</div>
                  </AnteriorButton>
                  <ActionsButtons type="button" onClick={this.enviarPorCorreo.bind(this)}>
                    <div>Enviar por Correo</div>
                    <Email />
                  </ActionsButtons>
                  <ActionsButtons type="button" onClick={this.verCotizacion.bind(this)}>
                    <div>Imprimir</div>
                    <Print />
                  </ActionsButtons>
                </ButtonGroup>
              </PasoButtons>
              <PasoButtons className='noPrint'>
                <SiguienteButton type="button" onClick={this.nuevaCotizacion.bind(this)} disabled={this.props.pristine || this.props.submitting}>
                  <div>Nueva Cotización</div>
                  <Add />
                </SiguienteButton>
                <SiguienteButton type="button" onClick={this.solicitar.bind(this)}>
                  <div>Solicitar</div>
                  <AddToPhotosOutlinedIcon />
                </SiguienteButton>
              </PasoButtons>
            </div>
            }
          
          </form>
          }
          {error &&
                      <div>
                        <PasoButtons className='noPrint'>
                          <AnteriorButton type="button" className="previous" onClick={this.props.previousPage}>
                            <TrendingFLat />
                            <div>Volver</div>
                          </AnteriorButton>
                          <SiguienteButton type="button" onClick={this.nuevaCotizacion.bind(this)} disabled={this.props.pristine || this.props.submitting}>
                            <div>Nueva Cotización</div>
                            <Add />
                          </SiguienteButton>
                        </PasoButtons>
                      </div>
          }
        </PasoForm> 
        
        
        <MyModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          //className={classes.modal}
          open={this.state.open}
          onClose={handleCloseM}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Requisitos req={this.state.requisitos} edad={this.props.edad} sumaAsegurada={this.props.sumaAsegurada}/>
          </Fade>
        </MyModal>
      </PasoContainer>
    );
  }
  
}

CotizadorCotizacion = reduxForm({
  form: 'cotizador', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CotizadorCotizacion);

const selector = formValueSelector('cotizador');
CotizadorCotizacion = connect(
  state => {
    const { plan, nombres, apellidos, 
      fechaDeNacimiento, periodoCobertura, 
      sexo, plazoPagos, sumaAsegurada, duracionPrimas,
      beneficiosAdicionales, fumador
    } = selector(state, 'plan', 'fumador', 'nombres', 'apellidos', 'fechaDeNacimiento', 'periodoCobertura', 'sexo', 'plazoPagos', 'sumaAsegurada', 'duracionPrimas', 'beneficiosAdicionales');
    const edad = moment().diff(fechaDeNacimiento, 'years');
    const sexoName = (sexo !== 'M') ? 'Femenino' : 'Masculino';
    const fechaTermino = moment().add(periodoCobertura, 'years').format('MMM/YYYY');
    const getPlazoPagosDesc = (plazoPagos) => {
      if(plazoPagos === 'A'){return 'Anual';}
      if(plazoPagos === 'S'){return 'Semestral';}
      if(plazoPagos === 'M'){return 'Mensual';}
      return 'Trimestral';
    };
    const getFumador = (fumador) => {
      if(fumador === 'S'){return 'Fumador';}
      return 'No Fumador';
    };
    const plazoPagosDesc = getPlazoPagosDesc(plazoPagos);
    const esFumador =  getFumador(fumador);

    return {
      plan,
      nombres,
      apellidos,
      fullName: `${nombres || ''} ${apellidos || ''}`,
      fechaDeNacimiento,
      edad,
      periodoCobertura,
      sexo,
      fumador,
      esFumador,
      sexoName,
      plazoPagos,
      plazoPagosDesc,
      sumaAsegurada, 
      duracionPrimas,
      beneficiosAdicionales,
      fechaTermino,
      valores: getFormValues('cotizador')(state)
    };
  }
)(CotizadorCotizacion);

export default CotizadorCotizacion;
