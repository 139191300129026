import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Loader from '../Loader';
import Layout from '../layout/layout';
import LayoutSalud from '../layout/layout-salud';
import LayoutSaludColectivo from '../layout/layout-salud-colectivo';
import LayoutSaludCambios from '../layout/layout-salud-cambios';
import Welcome from '../../welcome/index';
//ALERTS
import {displayError} from '../../../helpers/alerts';
import {cambio} from '../../../env';

import {useKeycloak} from 'react-keycloak';

export const AppRouter = () => {

  const { keycloak, initialized } = useKeycloak();
  //IF NO NDA
  // const nuevaCotizacion = () => {
  //   const currentURl = `//${env.envURL}`;
  //   window.open(`${currentURl}?cia=${source}`, '_self');
  // };

  const profile = () => {
    keycloak.loadUserInfo().success(info => {
      console.log('Este es el erro:', info, keycloak);
      if(info.nda === 'yes'){
        console.log('OK');
      }else{
        displayError(
          'Lo Sentimos',
          'Usted no tiene permisos para usar este cotizador, comuníquese con su ejecutivo de cuentas.'
        );
      }
      return ('Ok');
    }).error(()=> {
      //console.log('Failed to load user profile');
    });
  };

  
  if(!initialized) {
    return <Loader main />;
  }else{

    return(
      <BrowserRouter>
        {profile()}
        <div className="">
          <Route exact path="/" render={(props) => (<Welcome {...props} /> )}>
            {cambio ? <Redirect to="/saludCambio" /> : ''}
          </Route>
          <Route path="/vida" component={Layout} />
          <Route path='/salud' component={LayoutSalud}/>
          <Route path='/saludcolectivo' component={LayoutSaludColectivo}/>
          <Route path='/saludCambio' component={LayoutSaludCambios}/>
        </div>
      </BrowserRouter>        
    );
  }

};