/* eslint-disable no-prototype-builtins */
/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import styles from './index.styles';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import validate from '../../helpers/validate';
import BeneficiosField from '../common/fields/beneficiosField';
import RenderTextField from '../common/fields/renderTextField';
import RenderSelectField from '../common/fields/renderSelectField';
import RenderCheckBoxGroup from '../common/fields/arrayCheckbox';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import { createNumberMask } from 'redux-form-input-masks';
import NumberFormat from 'react-number-format';

//REQUESTS
import cotizar from '../../helpers/api/vida/cotizar';

import moment from 'moment';
import _ from 'lodash';

const currencyMask = createNumberMask({
  prefix: 'US$ ',
  //suffix: ' per item',
  decimalPlaces: 0,
  locale: 'en-US',
});

class CotizadorPasoCuatro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaTarget: 0,
      primaNeta: 0
    };
  }

  componentDidMount() {
    const { productos, valores } = this.props;

    //PLAN SELECCIONADO
    const planSelected = _.find(productos, { 'codprod': valores.plan, 'codcobert': 'VIDA' });
    this.setState({selectedPlan: planSelected});

    //TERM VALUE    
    const isTermValue = valores.plan === '0187' || valores.plan === '0183' || planSelected.tipo_producto === 'TERM_VALUE' ? true : false;
    const isSurvivor = valores.plan === '0191' || planSelected.tipo_producto === 'SURVIVOR' ? true : false;

    //console.log(isSurvivor);

    //TERM VALUE    
    if(isTermValue){
      this.setState({isTermValue: true});
    }

    //SURVIVOR  
    if(isSurvivor){
      this.setState({isSurvivor: true});
    }

    //console.log(isSurvivor, valores);

    //-------

    //Filtra Beneficios(Coberturas) y Asigna los valores
    const valoresKeys = _.keys(valores);
    //Agrega Cobertura por defecto
    valoresKeys.push('VIDA');
    //Si es TermValue
    if(isTermValue || isSurvivor){
      valoresKeys.push('VIVE');
    }

    const coberturasPlan = _.filter(productos, (o) => {
      return o.codprod === valores.plan;
    });

    const coberturasFiltradas = _.chain(coberturasPlan)
      .filter((cob) => {
        return _.includes(valoresKeys, cob.codcobert) || _.includes(valoresKeys, cob.dependencia);
      }).map((el) => {
        let o = Object.assign({}, el);
        let sumaBasica = (el.sumaBasica !== 0) ? el.sumaBasica : 0;
        let calcSumaAsegurada = (el.porcSumaBasica * valores.sumaAsegurada) / 100;
        let porcSumaAsegurada = calcSumaAsegurada >= el.sumaAsegMax ? el.sumaAsegMax : calcSumaAsegurada;
        let cumplerango = valores.sumaAsegurada >= el.montoDesde && valores.sumaAsegurada <= el.montoHasta;
        //console.log(el, valores.hasOwnProperty(el.codcobert), cumplerango, sumaBasica, el.porcSumaBasica, porcSumaAsegurada);
        o.sumaAseguradaPrimas = valores.sumaAsegurada;
        if(el.orden === 0){
          //console.log(o.orden);
          o.orden = 3;
        }
        if(el.codcobert === 'VIDA'){
          o.orden = 1;
        }
        if(valores.hasOwnProperty(el.codcobert) && cumplerango){
          o.sumaAsegurada = sumaBasica;
        } 
        else if(valores.hasOwnProperty(el.codcobert) && el.porcSumaBasica > 0 && valores[el.codcobert] === true){
          o.sumaAsegurada = porcSumaAsegurada;
        }
        else if(el.codcobert === 'VIVE' || el.codcobert === 'VIDA'){
          o.sumaAsegurada = valores.sumaAsegurada;
        }
        //INCLUDE INCL DEPENDENCIES
        else if(valores.hasOwnProperty('INCL') && el.dependencia === 'INCL'){
          //console.log('INCL: ', o);
          o.orden = 4;
          o.sumaAsegurada = o.sumaAsegMax;
        }
        //console.log(o);
        return o;
      }).value();
    //Flatea y devuelve coberturas unicas
    //const coberturas = _.uniqBy(coberturasFiltradas, 'codcobert');
    //console.log(coberturasFiltradas);

    const coberturasConSumaAsegurada = _.filter(coberturasFiltradas, (o) => {
      return o.hasOwnProperty('sumaAsegurada');
    });

    const sortedCoberturas = _.sortBy(coberturasConSumaAsegurada, [(o) => { return o.orden; }]);
    //console.log(sortedCoberturas);
    //IS RENTA INVALIDEZ
    const isRentaInvalidez = _.find(coberturasConSumaAsegurada, (o) => { return o.codcobert === 'INCL'; });
    const rentaInvalidez = isRentaInvalidez ? 'S' : 'N';
    //-------

    if(isSurvivor){
      const dummyValores = _.cloneDeep(valores);
      const postValores = valores;
      dummyValores.tipoPropuesta = 1;
      dummyValores.psumaasesuperv = valores.sumaAsegurada;

      //COTIZAR
      cotizar(dummyValores, sortedCoberturas, rentaInvalidez, isSurvivor).then((res) => {
        //console.log(res);
        if(res){

          const frecuenciaSeleccionada = _.find(res, (o) => {return o.pformapago === 'A';});

          this.setState({primaTarget: frecuenciaSeleccionada.outprimatarget, primaNeta: frecuenciaSeleccionada.outPrimaNeta});

          //console.log('IF RES: ',res, frecuenciaSeleccionada.outprimatarget, frecuenciaSeleccionada.outPrimaNeta);

          dummyValores.primaNeta = frecuenciaSeleccionada.outPrimaNeta;
          dummyValores.primaTarget = frecuenciaSeleccionada.outprimatarget;
          postValores.primaNeta = frecuenciaSeleccionada.outPrimaNeta;

        }
      }).catch(error => { console.log(error);});
    }
  }

  render(){
    const { handleSubmit, pristine, previousPage, submitting, productos, plan, fechaDeNacimiento, sumaAsegurada, valores } = this.props;
    const { PasoContainer, PasoName, PasoDesc, PasoForm, PasoButtons, SiguienteButton, AnteriorButton, FieldRowBeneficios, FieldRow } = styles;
    const { primaTarget } = this.state;

    const edad = moment().diff(fechaDeNacimiento, 'years');
    //const edadLimite = Number(edad) + Number(periodoCobertura);

    //FILTER VIDA 
    const fltrVida = _.filter(productos, (prod) => {
      return prod.codprod === plan && prod.codcobert !== 'VIDA';
    });
    //FILTER RANGES
    const beneficios = _.filter(fltrVida, (prod) => {
      return sumaAsegurada >= prod.montoDesde && sumaAsegurada <= prod.montoHasta || prod.montoHasta === 0;
    });

    const fndSurvidor = _.find(productos, (prod) => {
      return prod.codprod === plan;
    });

    const isSurvivor = fndSurvidor.tipo_producto === 'SURVIVOR' ? true : false;

    //console.log("PRODUCTOS DE VIDA: ",fltrVida, "ES SURVIVOR: ", isSurvivor, "VALORES: ", valores);

    //Loop beneficios
    let rows = [];
    beneficios.forEach((producto, idx) => {
      const valEmision = edad < producto.edadEmision;
      const valVisible = producto.visible === 'N';

      if(!valEmision || valVisible){
        //console.log('PRODUCTO: ',producto,'NO CUMPLE: ', 'Edad: ',edad, 'Edad Emision: ',producto.edadEmision, valEmision, edadLimite, valVisible);
      } else {
        rows.push(
          <BeneficiosField 
            className='col-md py-6'
            key={idx}
            name="beneficiosAdicionales" 
            component={RenderCheckBoxGroup}
            itemValue={producto}
          />
        ); 
      }
      
    }); 

    function NumberFormatCustom(props) {
      const { inputRef, onChange, ...other } = props;
    
      return (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
          isNumericString
          prefix="US$ "
        />
      );
    }

    return (
      
      <PasoContainer>
        <PasoName>Paso 4</PasoName>
        {isSurvivor &&
          <PasoDesc>Parámetros de Cobertura</PasoDesc>
        }
        {!isSurvivor &&
          <PasoDesc>Beneficios Adicionales</PasoDesc>
        }
        
        <PasoForm>
          <form onSubmit={handleSubmit}>
            {isSurvivor &&
            <div>
              <FieldRow>
                <Field
                  name="tipoPropuesta"
                  component={RenderSelectField}
                  label="Tipo Propuesta"
                >
                  <option value=""></option>
                  <option value={1}>Beneficio de Supervivencia</option>
                  <option value={2}>Prima Esperada</option>
                </Field>
                <Field
                  name="psumaasesuperv"
                  component={RenderTextField}
                  label="Monto"
                  {...currencyMask}
                />
               </FieldRow>
              {/*<FieldRow>
                <Field
                  name="aporteInicial"
                  component={RenderTextField}
                  label="Aporte Inicial"
                  {...currencyMask}
                />
              </FieldRow> */}
              <PasoName>Primas Esperadas</PasoName>
              <br></br>
              <FieldRow>
                <TextField
                  id="primaTarget"
                  label="Prima Target"
                  value={this.state.primaTarget}
                  InputProps={{
                    readOnly: true,
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <TextField
                  id="primaNeta"
                  label="Prima Máxima"
                  value={this.state.primaNeta}
                  InputProps={{
                    readOnly: true,
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </FieldRow>
            </div>
            }
            {!isSurvivor &&
            <FieldRowBeneficios className='row'>
              {rows}
            </FieldRowBeneficios>
            }
            <PasoButtons>
              <AnteriorButton type="button" className="previous" onClick={previousPage}>
                <TrendingFLat />
                <div>Anterior</div>
              </AnteriorButton>
              <SiguienteButton type="submit" className="next" disabled={pristine || submitting}>
                <div>Finalizar</div>
                <TrendingFLat />
              </SiguienteButton>
            </PasoButtons>
          </form>
        </PasoForm>
      </PasoContainer> 
    );
  }
}

CotizadorPasoCuatro = reduxForm({
  form: 'cotizador', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(CotizadorPasoCuatro);

const selector = formValueSelector('cotizador');
CotizadorPasoCuatro = connect(
  state => {
    const { plan , periodoCobertura, rangoPagoPrimas, fechaDeNacimiento, sumaAsegurada } = selector(state, 'plan','periodoCobertura', 'rangoPagoPrimas', 'fechaDeNacimiento', 'sumaAsegurada');
    return {
      plan,
      periodoCobertura,
      rangoPagoPrimas,
      fechaDeNacimiento,
      sumaAsegurada,
      valores: getFormValues('cotizador')(state)
    };
  }
)(CotizadorPasoCuatro);

export default CotizadorPasoCuatro;
