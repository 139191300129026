/* eslint-disable no-console */
import axios from 'axios';
import {env, cia, pais} from '../../../env';
import _ from 'lodash';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const coberturasSurvivorURl = `//${env.beURL}/strp-primas-survivor`;

export default (async function cotizar(valores, sortedCoberturas, rentaInvalidez, isSurvivor) {

  //FRECUENCIA PAGOS MEDICAL
  //const isSurvivor = valores.plan === '0191' ? true : false;

  const sSumaAseg = _.map(sortedCoberturas, 'sumaAseguradaPrimas');
  const sCobertura = _.map(sortedCoberturas, 'codcobert'); 

  console.log(
    //'Is Term Value?',isTermValue, 
    //'Productos: ',productos, 
    'Coberturas: ', sortedCoberturas, 
    'Valores:', valores,
    //'Cotizacion: ', cotizacion,
    'sCobertura:', sCobertura,
    'sSumaAseg: ', sSumaAseg
  );
  
  await sleep(0.1); // simulate server latency

  //SURVIVOR
  if(isSurvivor){
    console.log('ENTRO AQUI SURVIVOR');
    const plazosPagos = [
      {pformapago : 'A'},
      {pformapago : 'S'},
      {pformapago : 'T'},
      {pformapago : 'M'}
    ];
      //GET COBERTURAS MEDICAL
      //------------------------------------------------
    const promises = plazosPagos.map(cob => {
      const cobertura = {
        pcodcia: cia,
        pcodpais: pais.codpais,
        pcodprod: valores.plan,
        pfechanac: valores.fechaDeNacimiento,
        psexo: valores.sexo,
        pcondicion: valores.fumador,
        ppercobert: valores.periodoCobertura,
        pperpagprima: valores.duracionPrimas,
        pformapago: cob.pformapago,
        sSumaAseg: sSumaAseg.join(),
        psumaasesuperv: valores.psumaasesuperv ? valores.psumaasesuperv : 0,
        sCobertura: sCobertura.join(),
        pTipoPropuesta: valores.tipoPropuesta,
        pAporteInicial: valores.aporteInicial ? valores.aporteInicial : 0,
        pRentaInvalidez: rentaInvalidez ? rentaInvalidez : 'N',
      };

      console.log(cobertura);

      return axios.post(coberturasSurvivorURl, cobertura).then(res => {
        let p = Object.assign({}, res.data);
        p.pformapago = cob.pformapago;
        console.log(p);
        return p;
      }).catch((error) => {
        console.log(error);
      });
      
    });

    return await Promise.all(promises)
      .then(values => {
        //console.log(values);
        return values;
      }).catch(error => {
        console.log(error);
      });

  }
  //NO ES SURVIOR
  else{
    console.log('ENTRO AQUI NO ES SURVIVOR');
    const plazosPagos = [
      {pformapago : 'A'},
      {pformapago : 'S'},
      {pformapago : 'T'},
      {pformapago : 'M'}
    ];

    console.log(plazosPagos);
    //GET COBERTURAS MEDICAL
    //------------------------------------------------
    const promises = plazosPagos.map(cob => {
      const cobertura = {
        pcodcia: cia,
        pcodpais: pais.codpais,
        pcodprod: valores.plan,
        pfechanac: valores.fechaDeNacimiento,
        psexo: valores.sexo,
        pcondicion: valores.fumador,
        ppercobert: valores.periodoCobertura,
        pperpagprima: valores.duracionPrimas,
        pformapago: cob.pformapago,
        sSumaAseg: sSumaAseg.join(),
        psumaasesuperv: valores.psumaasesuperv ? valores.psumaasesuperv : 0,
        sCobertura: sCobertura.join(),
        pTipoPropuesta: valores.tipoPropuesta ? valores.tipoPropuesta : 1,
        pAporteInicial: valores.aporteInicial ? valores.aporteInicial : 0,
        pRentaInvalidez: rentaInvalidez ? rentaInvalidez : 'N',
      };

      console.log(cobertura, coberturasSurvivorURl);

      return axios.post(coberturasSurvivorURl, cobertura).then(res => {
        let p = Object.assign({}, res.data);
        p.pformapago = cob.pformapago;
        console.log('P: ', p);
        return p;
      }).catch((error) => {
        console.log(error);
      });
    });

    return await Promise.all(promises)
      .then(values => {
        console.log(values);
        return values;
      }).catch(error => {
        console.log(error);
      });
  }

});
