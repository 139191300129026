import styled from 'styled-components';
import fondoPlanes from '../../images/fondoPlanes.jpg';
import fondoPlanesSalud from '../../images/fondoPlanesSalud.jpg';

const HeaderContainer = styled.div`
  background-color: #00466A;
  width: 100%;
  margin: 0;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1.2rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  background-color: #FFFFFF;
`;

const PasoContainer = styled.div`
  width: 100%;
`;

const Wizzard = styled.div`
  width: 720px;
  min-height: 600px;
  margin: 0 auto;
`;

const PasoForm = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const PasoName = styled.h4`
  width: 100%;
  text-align: center;
  font-size: 17px;
  color: #B0B0B0;
  margin-top: 60px;
`;

const PasoDesc = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #2E2E2B;
  margin-top: 5px;
  margin-bottom: 70px;
`;

const Planes = styled.div`
  display: flex;
  justify-content: center;
`;
const PaisBreak =styled.div`
  flex-basis: 100%;
  height: 0;
`;
const Paises = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const PaisContainer = styled.div`
  width: 17%;
  justify-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  cursor: pointer;

  span{
    width: 100%;
    text-align: center;
    font-size: 13px;
  }
`;
const Pais = styled.div`
  width: 98px;
  height: 68px;
  margin: 0 auto 10px auto;;
  border-width: 3px;
  border-radius: 12px;
  border-color: #FFF;
  border-style: solid;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);

  transition: all 0.2s linear;

  :hover{
    border-color: rgba(0, 169, 230, .5);;
  }
  img {
    width: 92px;
  }
  a:hover{
    text-decoration: none;
  }
`;
const Plan = styled.div`
  width: 280px;
  height: 200px;
  margin: 10px;
  border-width: 3px;
  border-radius: 9px;
  border-color: #DFE1E6;
  border-style: solid;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);

  transition: all 0.2s linear;

  :hover{
    border-color: rgba(0, 169, 230, .5);;
  }

  a:hover{
    text-decoration: none;
  }
`;

const FondoPlan = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-image: url(${fondoPlanes});
  background-size: cover;
  width: 100%;
  height: 152px;
  border-width: 5px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  svg{
    color: #00A9E6;
    font-size: 2.5rem !important;
  }
`;

const FondoPlanSalud = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-image: url(${fondoPlanesSalud});
  background-size: cover;
  width: 100%;
  height: 152px;
  border-width: 5px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  svg{
    color: #00A9E6;
    font-size: 2.5rem !important;
  }
`;

const DetallesPlan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px;
`;

const TituloPlan = styled.div`
  height: 100%;
  font-size: 17;
  color: #00A9E6;
  margin: 0;
`;
const Loading = styled.div`
    text-align: center;
    padding: 50px;

    svg{
      color: #01a9e5;
    }
`;

export default { HeaderContainer, ContentContainer, PasoContainer, PasoName, 
  PasoDesc, Plan, FondoPlan, TituloPlan, FondoPlanSalud, Loading,
  DetallesPlan, Planes, PasoForm, Wizzard, Paises, Pais, PaisBreak, PaisContainer };