import React from 'react';
import styles from '../../cotizador-vida/index.styles';
import Plan from './plan';
import PlanDetails from '../../../helpers/api/vida/plans';

import _ from 'lodash';

const Planes = ({ productos }) => {
  const { Planes } = styles;
  //console.log(productos);
  const obligatorios = _.filter(
    productos, {'codcobert': 'VIDA'}
  );
  //Merge dos arreglos de obejtos by Key/Value
  const plansWithDetails = _.map(obligatorios, function(obj) {
    return _.assign(obj, _.find(PlanDetails, {
      codprod: obj.codprod
    }));
  });
  const sortedPlans = _.orderBy(plansWithDetails, [(o) => { return o.descprod; }], ['desc']);
  const no0182 = _.reject(sortedPlans, {codprod: '0182'});
  //console.log(obligatorios, PlanDetails, plansWithDetails);
  //console.log(sortedPlans);
  return ( 
    <Planes>
      {no0182.map(producto => 
        <Plan key={producto.codprod} producto={producto} beneficios={productos} />
      )}
    </Planes>
  );
};
 
export default Planes;