import React from 'react';
import styles from './index.styles';
import { Link } from 'react-router-dom';
import {pais} from '../../env';

const Options = (props) => {

  const { PasoContainer, 
    PasoName, PasoDesc, Plan, FondoPlan, TituloPlan,
    DetallesPlan, Planes, FondoPlanSalud } = styles;
  const {isTester, paisDefecto} = props;

  //console.log(props);

  return ( 
    <div className="Welcome">
      <PasoContainer>
        <PasoName>Bienvenido</PasoName>
        <PasoDesc>Elija un Cotizador</PasoDesc>
      </PasoContainer>
      <Planes>
        {paisDefecto.vida === "S" &&
          <Plan>
            <Link to="/vida">
              <FondoPlan />
              <DetallesPlan>
                <TituloPlan>Vida</TituloPlan>
              </DetallesPlan>
            </Link>
          </Plan>
        }
        {paisDefecto.salud === "S" &&
          <Plan>
            <Link to="/salud">
              <FondoPlanSalud />
              <DetallesPlan>
                <TituloPlan>Salud</TituloPlan>
              </DetallesPlan>
            </Link>
          </Plan>
        }
        {paisDefecto.saludColectivo === "S" &&
          <Plan>
            <Link to="/saludcolectivo">
              <FondoPlanSalud />
              <DetallesPlan>
                <TituloPlan>Salud Colectiva</TituloPlan>
              </DetallesPlan>
            </Link>
          </Plan>
        }
      </Planes>
    </div>
  );
};
 
export default Options;