import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { Field, reduxForm, FieldArray } from 'redux-form';
import validate from '../../helpers/validate';
import RenderTextField from '../common/fields/renderTextField';
import RenderDateField from '../common/fields/renderDateField';
import RenderSelectField from '../common/fields/renderSelectField';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
//CARD
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, styled } from '@material-ui/core/styles';
//DIALOG
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

const CotizadorPasoUno = props => {
  const { handleSubmit } = props;
  const { PasoContainer, PasoName, PasoDesc, PasoForm, PasoButtons, SiguienteButton, FieldRow,
    TituloDependiente, DependientesTitle, DependientesButton, DependientesButtons } = styles;
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const useStyles = makeStyles({
    card: {
      borderRadius: 0,
      boxShadow: 'none'
    }
  });
  const classes = useStyles();

  const CardActionsTop = styled(CardActions)({
    paddingLeft: 25,
    paddingBottom : 0
  });

  const IconButtonRigth = styled(IconButton)({
    marginLeft: 'auto !important',
    color: '#00A9E6'
  });

  const CardDependientes = styled(Card)({
    marginBottom: '10px !important',
    paddingTop: 0,
    transition: 'all 0.2s linear'
  });

  const CardDependientesContent = styled(CardContent)({
    paddingTop: 0
  });


  const renderDependientes = ({ fields, meta: { error, submitFailed } }) => (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        {fields.length > 0 ? <DependientesTitle>Dependientes</DependientesTitle> : ''}
        {fields.map((dependiente, index) => (
          <CardDependientes key={index}>
            <CardActionsTop className={ classes.card_action }>
              <TituloDependiente>Dependiente #{index + 1}</TituloDependiente>
              <IconButtonRigth 
                className={classes.buttom_left }
                title="Remove Member"
                onClick={() => fields.remove(index)}
                aria-label="delete">
                <DeleteIcon fontSize="small"/>
              </IconButtonRigth>
            </CardActionsTop>
            <CardDependientesContent>
              <FieldRow>
                <Field
                  name={`${dependiente}.nombres`}
                  component={RenderTextField}
                  label="Nombres"
                />
                <Field
                  name={`${dependiente}.apellidos`}
                  component={RenderTextField}
                  label="Apellidos"
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name={`${dependiente}.parentesco`}
                  component={RenderSelectField}
                  label="Parentesco"
                >
                  <option value=""></option>
                  <option value="C">Conyugue</option>
                  <option value="H">Hijo</option>
                </Field>
                <Field
                  name={`${dependiente}.sexo`}
                  component={RenderSelectField}
                  label="Sexo"
                >
                  <option value=""></option>
                  <option value="H">Masculino</option>
                  <option value="M">Femenino</option>
                </Field>
            
              </FieldRow>
              <FieldRow>
                <Field name={`${dependiente}.edad`} label="Fecha de Nacimiento" component={RenderDateField} />
              </FieldRow>
            </CardDependientesContent>
          </CardDependientes>
        ))}

        <DependientesButtons>
          <DependientesButton type="button" onClick={() => fields.push({})}>
            <div>Agregar Dependientes</div>
            <ControlPointOutlinedIcon />
          </DependientesButton>
          {submitFailed && error && <span>{error}</span>}
        </DependientesButtons>
      </CardContent>
    </Card>

  );

  return (
    <PasoContainer>
      <PasoName>Paso 1</PasoName>
      <PasoDesc>Díganos sobre usted</PasoDesc>
      <PasoForm>
        <form onSubmit={handleSubmit}>
          <FieldRow>
            <Field
              name="nombres"
              component={RenderTextField}
              label="Nombres"
            />
            <Field
              name="apellidos"
              component={RenderTextField}
              label="Apellidos"
            />
          </FieldRow>
          <FieldRow>
            <Field
              name="sexo"
              component={RenderSelectField}
              label="Sexo"
            >
              <option value=""></option>
              <option value="H">Masculino</option>
              <option value="M">Femenino</option>
            </Field>
            <Field name="fechaDeNacimiento" label="Fecha de Nacimiento" component={RenderDateField} />
          </FieldRow>

          <FieldArray name="dependientes" component={renderDependientes} />
          <PasoButtons>
            <SiguienteButton type="submit" className="next">
              <div>Siguiente</div>
              <TrendingFLat className="rightIcon" />
            </SiguienteButton>
          </PasoButtons>

          {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Añadir Dependientes</DialogTitle>
            <DialogContent>
              <FieldRow>
                <Field
                  name="nombres"
                  component={RenderTextField}
                  label="Nombres"
                />
                <Field
                  name="apellidos"
                  component={RenderTextField}
                  label="Apellidos"
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name="sexo"
                  component={RenderSelectField}
                  label="Sexo"
                >
                  <option value=""></option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </Field>
                <Field name="fechaDeNacimiento" label="Fecha de Nacimiento" component={RenderDateField} />
              </FieldRow>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
            Cancel
              </Button>
              <Button onClick={handleClose} color="primary">
            Subscribe
              </Button>
            </DialogActions>
          </Dialog> */}
        </form>
      </PasoForm>
    </PasoContainer>
  );
};

CotizadorPasoUno.propTypes = {
  meta: PropTypes.object,
  handleSubmit: PropTypes.func,
  previousPage: PropTypes.any
};

export default reduxForm({
  form: 'cotizadorSalud', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(CotizadorPasoUno);
