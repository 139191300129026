/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import validate from '../../helpers/validate';
//import warn from '../../helpers/warn';
import RenderSelectField from '../common/fields/renderSelectField';
import RenderCheckbox from '../common/fields/renderCheckbox';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
//import cotizar from '../../helpers/api/salud/cotizar';
import moment from 'moment';
import {errorMessage} from '../../helpers/alerts';
import {env, cia} from '../../env';
import axios from 'axios';
import _ from 'lodash';

class CotizadorPasoTres extends Component {
  constructor(props) {
    super(props);
    this.handleOpcionChange = this.handleOpcionChange.bind(this);
    this.state = {
      planes: [],
      planSelected: '',
      shrink: false
    };
  }
  componentDidMount(){
    const {valores} = this.props;
    const hasDependientes = valores.dependientes ? true : false;
    //console.log(valores);

    //GET OPCIONES
    axios.post(`//${env.beURL}/stpr-planes-salud`, {
      cia: cia,
      codprod: valores.plan,
      codramo: hasDependientes ? '0002' : '0001'
    }).then(
      res => {
        //console.log(res);
        let planes;
        if(res.data.data){
          planes = res.data.data;
        } else {
          planes = res.data;
        }
        this.setState({planes, loading: false});
        //console.log('planes: ', planes);
      }
    ).catch(
      () => {
        errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
      }
    );

    //OPCIONES SHRINK
    if(this.props.valores.opcion){
      this.setState({shrink: true});
    }
  }
  //const { change, handleSubmit, pristine, previousPage, submitting, rangoCobertura, rangoPagoPrimas = [] } = props;
  handleOpcionChange (event, value){
    console.log(value, this.props.valores);
    if(value){
      this.setState({shrink: true});
    }
  }
  render(){
    const {productos, plan} = this.props;
    const { PasoContainer, PasoName, PasoDesc, PasoForm, FieldRowPrimaEsperada,
      PasoButtons, SiguienteButton, AnteriorButton, FieldRow, PlanName, FieldRowOne } = styles;
    const planSelected = _.find(productos, function(o) { return o.codprod === plan; });
    //const primaPrueba = planSelected.primaPrueba.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return (
      <PasoContainer>
        <PasoName>Paso 3</PasoName>
        <PasoDesc>Su póliza</PasoDesc>
        <PlanName>Su plan: {planSelected.descprod}</PlanName>
        <PasoForm>
          <form onSubmit={this.props.handleSubmit}>
            <FieldRow>
              <Field
                name="plazoPagos"
                component={RenderSelectField}
                label={cia === '02'?'Frecuencia de Pagos': 'Forma de Pago'}
              >
                <option value=""></option>
                <option value="M">Mensual</option>
                <option value="T">Trimestral</option>
                <option value="S">Semestral</option>
                <option value="A">Anual</option>
              </Field>
              <Field
                name="opcion"
                component={RenderSelectField}
                label="Opción"
                onChange={this.handleOpcionChange}
                shrink={this.state.shrink}
              >
                <option value=""></option>
                {this.state.planes.map(item =>
                  <option key={item.codplan} value={item.codplan}>{item.descplan}</option>
                )};
              </Field>
            </FieldRow>
            <FieldRowPrimaEsperada>
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">Prima Esperada</InputLabel>
                <OutlinedInput
                  id="primaEsperada"
                  label="Prima Esperada"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  //defaultValue={primaPrueba}
                  readOnly
                />
              </FormControl> */}
            </FieldRowPrimaEsperada>
            <FieldRowOne>
              {this.state.planes.length >= 3 &&
              <Field
                name="todosDeducibles"
                component={RenderCheckbox}
                label="Mostrar todos los deducibles"
              />}
            </FieldRowOne>
            <PasoButtons>
              <AnteriorButton type="button" className="previous" onClick={this.props.previousPage}>
                <TrendingFLat />
                <div>Anterior</div>
              </AnteriorButton>
              <SiguienteButton type="submit" className="next" disabled={this.props.pristine || this.props.submitting}>
                <div>Siguiente</div>
                <TrendingFLat />
              </SiguienteButton>
            </PasoButtons>
          </form>
        </PasoForm>
      </PasoContainer>
      
    );
  }
  
}

CotizadorPasoTres.propTypes = {
  handleSubmit: PropTypes.any,
  input: PropTypes.object
};

CotizadorPasoTres = reduxForm({
  form: 'cotizadorSaludColectivo', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(CotizadorPasoTres);

const selector = formValueSelector('cotizadorSaludColectivo');
CotizadorPasoTres = connect(
  state => {
    const { fechaDeNacimiento, periodoCobertura, plazoPagos, sumaAsegurada, duracionPrimas, plan
    } = selector(state, 'fechaDeNacimiento', 'plan', 'periodoCobertura', 'plazoPagos', 'sumaAsegurada', 'duracionPrimas', 'rangoPrimas');
    const edad = moment().diff(fechaDeNacimiento, 'years');
    //const planSelected = _.find(this.props.productos, function(o) { return o.codprod === plan; });
    return {
      fechaDeNacimiento,
      edad,
      periodoCobertura,
      plazoPagos,
      sumaAsegurada, 
      duracionPrimas,
      plan,
      valores: getFormValues('cotizadorSaludColectivo')(state)
    };
  }
)(CotizadorPasoTres);

export default CotizadorPasoTres;