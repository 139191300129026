import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const RenderRadioButton = ({ input, label, meta: { touched, error }, children, ...rest }) => {
  return(
    <FormControl error={touched && error}>
      <FormHelperText>{label}</FormHelperText>
      <RadioGroup {...input} {...rest}>
        {children}
      </RadioGroup>
      {renderFromHelper({ touched, error })}
    </FormControl>
  );
};

export default RenderRadioButton;