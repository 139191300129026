import React, { Component } from 'react';
import styles from './index.styles';
import PropTypes from 'prop-types';
import Stepper from 'react-stepper-horizontal';
import CotizadorPasoUno from './cotizadorPasoUno';
import CotizadorPasoDos from './cotizadorPasoDos';
import CotizadorPasoTres from './cotizadorPasoTres';
//import CotizadorPasoCuatro from './cotizadorPasoCuatro';
import CotizadorCotizacion from './cotizadorCotizacion';
//import PlanDetails from '../../helpers/api/plans-salud';
import {errorMessage} from '../../helpers/alerts';
import {env, cia} from '../../env';

import axios from 'axios';
// import _ from 'lodash';

class CotizadorSaludColectivo extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 0,
      steps: [
        {title: ''},
        {title: ''},//
        {title: ''},
        {title: ''}
      ],
      productos: [],
      obligatorios: [],
      loading: true
    };
  }
  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }
  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  componentDidMount() {
    axios.post(`//${env.beURL}/stpr-productos-salud`, {
      cia: cia
    }).then(
      res => {
        //console.log(res);
        let productos;
        if(res.data.data){
          productos = res.data.data;
        } else {
          productos = res.data;
        }
        this.setState({productos, loading: false});
        //console.log('productos: ', productos);
      }
    ).catch(
      () => {
        errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
      }
    );
  }

  render() {
    const { onSubmit, user, userInfo } = this.props;
    const { page, steps } = this.state;
    const { StepperContainer, Wizzard, FromContainer } = styles;

    return (
      <Wizzard>
        <StepperContainer>
          <Stepper 
            steps={ steps } activeStep={ page } defaultColor='#E8E8E8' 
            activeColor='#00A9E6' completeColor='#00A9E6' size={37} 
            circleFontSize={14} completeBarColor='#00A9E6' activeBarColor='#00A9E6'
            lineMarginOffset={1}
          />
        </StepperContainer>
        {page === 0 &&
        <FromContainer>
          <CotizadorPasoUno 
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        </FromContainer>
        }
        {page === 1 && 
          <CotizadorPasoDos
            productos={this.state.productos}
            onSubmit={this.nextPage} 
            previousPage={this.previousPage}
            loading={this.state.loading}
            user={user}  
          />}
        {page === 2 &&
        <FromContainer>
          <CotizadorPasoTres
            productos={this.state.productos}
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        </FromContainer>}
        {page === 3 &&
        <FromContainer>
          <CotizadorCotizacion
            productos={this.state.productos}
            previousPage={this.previousPage}
            onSubmit={onSubmit}
            user={user}
            userInfo={userInfo}
          />
        </FromContainer>}
      </Wizzard>
    );
  }
}

CotizadorSaludColectivo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CotizadorSaludColectivo;
