/* eslint-disable no-console */
import axios from 'axios';
import {env} from '../../../env';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function createPDF(id, valores, plan, vigencia, plazoPagos , user, 
  primas, cia, opcion, grpSumas, todos, opciones, opcionesDespforFilter, pais) {
  const currentURl = `//${env.beURL}`;
  const pdfCotizacion = 'pdf-cotizacion-salud';
  const endpointPDFCotizacion = `${currentURl}/${pdfCotizacion}`;
  //const endpointPDFCotizacion = `//localhost:3030/${pdfCotizacion}`;
  
  const detCotizacion = {
    id: id,
    valores: valores,
    plan: plan,
    vigencia: vigencia,
    plazoPagos: plazoPagos,
    user: user,
    primas: primas,
    cia: cia,
    todosDeducibles: valores.todosDeducibles,
    opcion: opcion,
    grpSumas: grpSumas,
    todos: todos,
    opciones: opciones,
    opcionesDespforFilter: opcionesDespforFilter,
    pais:pais
  };
  
  //console.log(detCotizacion);

  await sleep(1); // simulate server latency
  //console.log(values);

  //CREATE PDF
  return axios.post(endpointPDFCotizacion, detCotizacion)
    .then(res => {
      console.log('PDF: ', res.data);
      return res.data;
    }).catch( error => {
      console.log(error);
    });

});