/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from '../../helpers/validate';
//import warn from '../../helpers/warn';
import RenderTextField from '../common/fields/renderTextField';
import RenderSelectField from '../common/fields/renderSelectField';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import { createNumberMask } from 'redux-form-input-masks';
import moment from 'moment';
import _ from 'lodash';
import {cia} from '../../env';

const currencyMask = createNumberMask({
  prefix: 'US$ ',
  //suffix: ' per item',
  decimalPlaces: 0,
  locale: 'en-US',
});

// const phoneMask = createTextMask({
//   pattern: '(999) 999-9999',
// });

class CotizadorPasoTres extends Component {
  constructor(props) {
    super(props);
    this.handleRangoChange = this.handleRangoChange.bind(this);
    this.state = {
      rangoPagoPrimas: [],
      isTermValue: false,
      loading: true
    };
  }
  componentDidMount(){
    if(this.props.duracionPrimas){
      const rangoEstablecido = Array.from(Array(Number(this.props.periodoCobertura)+1).keys()).slice(1);
      this.setState({rangoPagoPrimas: rangoEstablecido});
    }
    if(this.props.plan === '0187' || this.props.plan === '0183'){
      this.setState({isTermValue: true});
    }
    //Rango Cobert Producto
    const productoSeleccionado = _.find(
      this.props.productos, {'codprod': this.props.plan, 'orden': 1}
    );
    const edad = moment().diff(this.props.fechaDeNacimiento, 'years');
    this.setState({productoSeleccionado: productoSeleccionado});

    const rangoCoberturaProducto = Array.from(Array(productoSeleccionado.edadPermanencia - edad).keys()).slice(productoSeleccionado.perMinCobertura)
    this.setState({rangoCoberturaProducto: rangoCoberturaProducto});

    this.setState({loading: false});

    //console.log("PROPS: ", this.props, "PRODUCTO SELECCIONADO", productoSeleccionado, "EDAD: ", edad, "RANGO COBERTURA: ", rangoCoberturaProducto);
  }
  handleRangoChange (event, value){
    const nuevoRango = Array.from(Array(Number(value)+1).keys()).slice(1);
    //console.log(value, nuevoRango);
    this.setState({rangoPagoPrimas: nuevoRango.reverse()});
  }
  renderRangoPrimas() {
    const { rangoPagoPrimas, isTermValue } = this.state;
    if(isTermValue){
      return rangoPagoPrimas.map(item => {
        if(item >= 10){
          return(<option key={item} value={item}>{item}</option>);
        }
      });
    }
    if(!isTermValue){
      return rangoPagoPrimas.map(item =>
        <option key={item} value={item}>{item}</option>
      );
    }
  }
  //const { change, handleSubmit, pristine, previousPage, submitting, rangoCobertura, rangoPagoPrimas = [] } = props;
  render(){
    const { PasoContainer, PasoName, PasoDesc, PasoForm, PasoButtons, SiguienteButton, AnteriorButton, FieldRow, Loading } = styles;
    const { loading } = this.state;
    return (
      <PasoContainer>
        <PasoName>Paso 3</PasoName>
        <PasoDesc>Su póliza</PasoDesc>
        {loading && 
          <Loading>
            <CircularProgress color='primary' />
          </Loading>
        }
        {!loading && 
          <PasoForm>
          <form onSubmit={this.props.handleSubmit}>
            <FieldRow>
              <Field
                name="periodoCobertura"
                component={RenderSelectField}
                label="Periodo de Cobertura (Años)" 
                onChange={this.handleRangoChange}
              >
                <option value=""></option>
                {this.state.rangoCoberturaProducto.map(item =>
                  <option key={item} value={item}>{item}</option>
                )};
              </ Field>
              <Field
                name="duracionPrimas"
                component={RenderSelectField}
                label="Duración de pago de primas (Años)"
              >
                <option value=""></option>
                {this.renderRangoPrimas()}
              </Field>
            </FieldRow>
            <FieldRow>
              <Field
                name="plazoPagos"
                component={RenderSelectField}
                label={cia === '02'?'Frecuencia de Pagos': 'Forma de Pago'}
              >
                <option value=""></option>
                <option value="M">Mensual</option>
                <option value="T">Trimestral</option>
                <option value="S">Semestral</option>
                <option value="A">Anual</option>
              </Field>
              <Field
                name="sumaAsegurada"
                component={RenderTextField}
                label="Suma Asegurada"
                {...currencyMask}
              />
            </FieldRow>
            <PasoButtons>
              <AnteriorButton type="button" className="previous" onClick={this.props.previousPage}>
                <TrendingFLat />
                <div>Anterior</div>
              </AnteriorButton>
              <SiguienteButton type="submit" className="next" disabled={this.props.pristine || this.props.submitting}>
                <div>Siguiente</div>
                <TrendingFLat />
              </SiguienteButton>
            </PasoButtons>
          </form>
        </PasoForm>
        } 
      </PasoContainer>
      
    );
  }
  
}

CotizadorPasoTres.propTypes = {
  handleSubmit: PropTypes.any,
  input: PropTypes.object
};

CotizadorPasoTres = reduxForm({
  form: 'cotizador', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(CotizadorPasoTres);

const selector = formValueSelector('cotizador');
CotizadorPasoTres = connect(
  state => {
    const { fechaDeNacimiento, periodoCobertura, plazoPagos, sumaAsegurada, duracionPrimas, plan
    } = selector(state, 'fechaDeNacimiento', 'plan', 'periodoCobertura', 'plazoPagos', 'sumaAsegurada', 'duracionPrimas', 'rangoPrimas');
    const edad = moment().diff(fechaDeNacimiento, 'years');
    const edadPermanencia = cia === '02' ? 101 : 101;
    //console.log(plan, cia);
    //perMinCobertura
    let rangoCobertura;
    if(cia === '02' || cia === '01' ){
      if(plan === '0208'){
        rangoCobertura = plan === '0208' ? Array.from(Array(edadPermanencia - edad).keys()).slice(10) : Array.from(Array(edadPermanencia - edad).keys()).slice(5);
      }else{
        rangoCobertura = plan === '0187' || plan === '0183' ? Array.from(Array(edadPermanencia - edad).keys()).slice(10) : Array.from(Array(edadPermanencia - edad).keys()).slice(5);
      }
    }else{
      rangoCobertura = plan === '0187' || plan === '0183' || plan === '0215'  || plan === '0214' ? Array.from(Array(edadPermanencia - edad).keys()).slice(15) : Array.from(Array(edadPermanencia - edad).keys()).slice(5);
    }
    
    //const rangoCoberturaTERMSEG = plan === '0186' ? Array.from(Array(edadPermanencia - edad).keys()).slice(5) : Array.from(Array(edadPermanencia - edad).keys()).slice(1);
    //const rangoPagoPrimas = Array.from(Array(100 - edad).keys()).slice(1);
    return {
      fechaDeNacimiento,
      edad,
      periodoCobertura,
      plazoPagos,
      sumaAsegurada, 
      duracionPrimas,
      rangoCobertura,
      plan,
      //rangoCoberturaTERMSEG
      //rangoPagoPrimas
    };
  }
)(CotizadorPasoTres);

export default CotizadorPasoTres;