import styled from 'styled-components';
import {source} from '../../../env';

const HeaderContainer = styled.div`
  background-color: ${source === 'eva' ? '#003B63' : '#00466A'};
  //background-color: #00466A;
  width: 100%;
  margin: 0;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1.2rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  background-color: #FFFFFF;
`;

export default { HeaderContainer, ContentContainer };