/* eslint-disable no-undef */
import 'url-search-params-polyfill';
import Cookies from 'universal-cookie';

const currentEnv = process.env.REACT_APP_ENVIROMENT;
const cookies = new Cookies();

function getParams() {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    cia: searchParams.get('cia') || '',
    poliza: searchParams.get('poliza') || '',
    producto: searchParams.get('producto') || '',
    guid: searchParams.get('guid') || '',
    deducible: searchParams.get('deducible') || '',
  };
}
const ciaParam = getParams();

const isCambio = ciaParam.poliza ? true : false;

const dev = {
  env: 'DEVELOPMENT',
  envURL: 'localhost:3000',
  envPort: ':3030',
  beURL: 'localhost:3031',
  bePort: '3031',
  beMethod: '',
  testCobertura: {
    codcobert: 'TEST' + Math.floor((Math.random() * 100) + 1),
    desccobert: 'Cobertura de Prueba',
    sumaAsegurada: 10000,
    outPrimaNeta: 10,
    outImpuestos: 10,
    outCargoAdmin: 10,
    outSumaAsegurada: 5,
    pGastoAnualProm: 10,
    pPrimaComision: 10
  }
};

const staging = {
  env: 'STAGING',
  envURL: 'portalwwg.eastus.cloudapp.azure.com:3000',
  envPort: ':80',
  beURL: 'portalwwg.eastus.cloudapp.azure.com:3031',
  bePort: '3031',
  beMethod: '/read.php'
};


const prod = {
  env: 'PRODUCTION',
  envURL: 'cotizadores.wwseguros.com.do',
  envPort: ':80',
  beURL: 'cotizadores.wwseguros.com.do:30443',
  bePort: '30443',
  beMethod: '/read.php'
};

let preEnv = dev;

switch(currentEnv) {
case 'DEVELOPMENT': {
  preEnv = dev;
  break;
}
case 'PRODUCTION': {
  preEnv = prod;
  break;
}
case 'STAGING': {
  preEnv = staging;
  break;
}
default: {
  preEnv = dev;
  break;
}
}

let preCia = '03';

switch(ciaParam.cia) {
case 'wws': {
  preCia = '03';
  break;
}
case 'wwsm': {
  preCia = '01';
  break;
}
case 'wwm': {
  preCia = '02';
  break;
}
default: {
  preCia = ciaParam.cia;
  break;
}
}

let preSource = 'wws';

switch(ciaParam.cia) {
case 'wws': {
  preSource = 'wws';
  break;
}
case '02': {
  preSource = 'wwm';
  break;
}
case 'wwm': {
  preSource = 'wwm';
  break;
}
case 'wwsm': {
  preSource = 'wwsm';
  break;
}
case 'eva': {
  preSource = 'eva';
  break;
}
case '08': {
  preSource = 'wwm';
  break;
}
case '09': {
  preSource = 'wwm';
  break;
}
case '10': {
  preSource = 'wwm';
  break;
}
case '11': {
  preSource = 'wwm';
  break;
}
case '12': {
  preSource = 'wwm'
  break;
}
default: {
  preSource = 'wws';
  break;
}
}

let prePais ;

prePais = cookies.get('pais');
//console.log(cookies.get('pais'));

///VALIDANDO ERROR DEL PAIS
if (typeof prePais == 'undefined') {
  console.log('ESTA UNDEFINED');

//   if(preCia === '03' || preCia === '01'){
//     prePais = {
//       codcia: '01',
//       codpais: '015',
//       descpais: 'REPUBLICA DOMINICANA',
//       vida: 'S', 
//       salud: 'S', 
//       saludColectivo: 'S' 
//     }
//   } else if(preCia === '02'){
//     prePais = {
//       codcia: '02',
//       codpais: '042',
//       descpais: 'PANAMA',
//       vida: 'S', 
//       salud: 'S', 
//       saludColectivo: 'S' 
//     }
//   } else if(preCia === '09'){
//     prePais = {
//       codcia: '09',
//       codpais: '037',
//       descpais: 'PARAGUAY',
//       vida: 'N', 
//       salud: 'S', 
//       saludColectivo: 'S' 
//     }
//   } else if(preCia === '10'){
//     prePais = {
//       codcia: '10',
//       codpais: '008',
//       descpais: 'COLOMBIA',
//       vida: 'S', 
//       salud: 'S', 
//       saludColectivo: 'S' 
//     }    
//   }else{
//     prePais = {
//       codcia: '02',
//       codpais: '042',
//       descpais: 'PANAMA',
//       vida: 'S', 
//       salud: 'S', 
//       saludColectivo: 'S' 
//     }
// }

} else {
  console.log('NO ESTA UNDEFINED');
  prePais = cookies.get('pais');
}

console.log('Pais:', prePais, 'Cia:', preCia, 'Source:', preSource);

export const isDevelopment = (currentEnv === 'DEVELOPMENT');
export const isProdcution = (currentEnv === 'PRODUCTION');
export const isStaging = (currentEnv === 'STAGING');

export const env = preEnv;

export const stgEnv = staging;
export const devEnv = dev;
export const prodEnv = prod;
export const cia = preCia;
export const source = preSource;
export const cambio = isCambio;
export const cambioParams = ciaParam;
export const pais = prePais;