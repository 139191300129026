/* eslint-disable no-console */
import axios from 'axios';
import {env, cia} from '../../../env';
import _ from 'lodash';
import moment from 'moment';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const cotizacionURl = `//${env.beURL}/stpr-cotizacion-salud`;
const certURl = `//${env.beURL}/stpr-cert-salud`;
const asegURl = `//${env.beURL}/stpr-aseg-salud`;
const primasURl = `//${env.beURL}/stpr-primas-salud`;

export default (async function cotizar(valores, asegurados, opcionADesplegar) {

  await sleep(0.001); // simulate server latency

  //const hasDependientes = valores.dependientes ? true : false;
  const ramo = '0003'; //hasDependientes ? '0002' : '0001';
  const opcionesDesp = opcionADesplegar.opcionesDesplegar.split(',');

  const cotizacion = {
    codcia: cia,
    codprod: valores.plan,
    codramo: ramo,
    forpag: valores.plazoPagos,
    codplan: valores.opcion ? valores.opcion : opcionesDesp[0]
  }

  //console.log(asegurados)

  /////TEST/////

  console.log('VALORES.:', valores,'COTIZACION.:', cotizacion, 'OPCION A DESPLEGAR.:', opcionADesplegar, opcionesDesp, 'ASEGURADOS: ', asegurados);

  return axios.post(cotizacionURl, cotizacion)

  .then(cot => {
    //console.log('COT.:',cot);

    const certificados = _.cloneDeep(valores.certificados);

    const promises = certificados.map((certificado, i) => {

      const cert = {
        numcot: cot.data.outNumcot,
        numcert: i + 1,
        codcia: cia,
        nombres: certificado.nombres,
        apellidos: certificado.apellidos,
        fecnac: certificado.fechaDeNacimiento,
        sexo: certificado.sexo
      }

      //console.log(cert);

      return axios.post(certURl, cert).then(res => {
        //let p = Object.assign({}, res.data);
        //p.pformapago = cob.pformapago;
        //console.log('Certificado guardado: ', res);
        return res;
      }).catch((error) => {
        console.log(error);
      });
    
    });

    return Promise.all(promises)
      .then(values => {
        //console.log('Certificados guardados: ',values);
        return values;
      }).catch(error => {
        console.log(error);
      });

    //return axios.post(certURl, cert);
  }).then(cert => {
    //console.log('CERT.: ',cert);

    const flatAsegurados = _.flattenDeep(asegurados);
    
    if(asegurados && asegurados.length){

      //ASEGURADOS
      //console.log(flatAsegurados);
      
      const promises = flatAsegurados.map((dep, i) => {
        const edad = moment().diff(dep.edad, 'years');
        const isConyugue = dep.parentesco === 'C' ? true : false;
        const isHijo = dep.parentesco === 'H' ? true : false;
        const isTitular = dep.parentesco === 'T' ? true : false;

        let codrel = '';
        
        if(isTitular && dep.sexo === 'H'){
          codrel = '0001'
        }else if (isTitular && dep.sexo === 'M'){
          codrel = '0002'
        }else if (isConyugue && dep.sexo === 'M'){
          codrel = '0008'
        }else if (isConyugue && dep.sexo === 'H'){
          codrel = '0007'
        }else if (isHijo && dep.sexo === 'M'){
          codrel = '0006'
        }else{
          codrel = '0005'
        }

        const aseg = {
          numcot: cert[0].data.outNumcot,
          numcert: dep.numcert,
          codcia: cia,
          numaseg: dep.numaseg,
          nombres: dep.nombres,
          apellidos: dep.apellidos,
          codrel: codrel,
          sexo: dep.sexo,
          fecnac: dep.edad,
          indmenor: isHijo && edad <= 25 ? 'S' : 'N'
        }

        //console.log(isTitular, aseg);
  
        return axios.post(asegURl, aseg).then(res => {
          //let p = Object.assign({}, res.data);
          //p.pformapago = cob.pformapago;
          //console.log(res);
          return res;
        }).catch((error) => {
          console.log(error);
        });
      
      });
  
      return Promise.all(promises)
        .then(values => {
          //console.log('Asegurados insertados: ',values);
          return values;
        }).catch(error => {
          console.log(error);
        });

    }
    else{
      console.log('NO HAY ASEGURADOS')
    }
    
  }).then(aseg => {

    if(aseg){
      //console.log('ASEG.: ',aseg);
      if(aseg.length > 0){

        const primas = {
          numcot: aseg[0].data.outNumcot,
          codcia: cia,
          codramo: ramo,
          opciones: opcionADesplegar.opcionesDesplegar
        }
  
        return axios.post(primasURl, primas);
      } else {
        
        const primas = {
          numcot: aseg.data.outNumcot,
          codcia: cia,
          codramo: ramo,
          opciones: opcionADesplegar.opcionesDesplegar
        }
    
        return axios.post(primasURl, primas);
      }
    }

  }).then(primas => {
    //console.log('PRIMAS.: ', primas);
    return primas.data;
  }).catch( error => {
    console.log(error);
  });

 

});
