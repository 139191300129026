/* eslint-disable no-prototype-builtins */
/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
//import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { Field, reduxForm } from 'redux-form';
import validate from '../../helpers/validate';
import RenderRadioGroup from '../common/fields/renderRadioGroup';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import CircularProgress from '@material-ui/core/CircularProgress';
import Planes from '../common/cotizador/planes';
import {displayDisclaimer} from '../../helpers/alerts';

import {cia, env} from '../../env';


class CotizadorPasoUno extends Component {
  constructor(props) {
    super(props);
    this.irAWWSM = this.irAWWSM.bind(this);
    this.state = {
      primaTarget: 0,
      primaNeta: 0
    };
  }

  irAWWSM(){
    const currentURl = `//${env.envURL}`;
    window.open(`${currentURl}/vida?cia=wwsm`, '_self');
  }

  componentDidMount() {
    const { resetForm, reset } = this.props;
    if(resetForm){
      //console.log('REINICIALA:', resetForm);
      reset();
    }
  }

  render(){
    const { handleSubmit, productos, loading } = this.props;
    const { 
      PasoContainer, PasoName, PasoDesc, 
      PasoButtons, SiguienteButton, Loading, AnteriorButton
    } = styles;

    return (
      <PasoContainer>
        <PasoName>Paso 1</PasoName>
        <PasoDesc>Elija un Plan</PasoDesc>
        <form onSubmit={handleSubmit}>
          {loading && 
          <Loading>
            <CircularProgress color='primary' />
          </Loading>
          }
          {!loading && 
          <div>
            <Field name="plan" component={RenderRadioGroup}>
              <Planes productos={productos}/>
            </Field>
            {cia === '03' &&
              <PasoButtons>
                <AnteriorButton type="button" onClick={() => displayDisclaimer(`//${env.envURL}/vida?cia=wwsm`)}>
                  <div style={{textAlign: 'center', float: 'none'}}>Otros Productos</div>
                  {/* <Add /> */}
                </AnteriorButton>
              </PasoButtons>
            }
            <PasoButtons>
              <SiguienteButton type="submit" className="next">
                <div>Siguiente</div>
                <TrendingFLat className="rightIcon" />
              </SiguienteButton>
            </PasoButtons>
          </div>
          }
        </form>
      </PasoContainer>
    );
  }
}

CotizadorPasoUno.propTypes = {
  handleSubmit: PropTypes.any
};

CotizadorPasoUno = reduxForm({
  form: 'cotizador', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(CotizadorPasoUno);

// const selector = formValueSelector('cotizador');
// CotizadorPasoUno = connect(
//   state => {
//     const { plan } = selector(state, 'plan');
//     return {
//       plan,
//       valores: getFormValues('cotizador')(state)
//     };
//   }
// )(CotizadorPasoUno);

export default CotizadorPasoUno;