import styled from 'styled-components';

const Title = styled.h1`
  margin: 10px 0;
  color: #2C405A;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 1.2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  max-width: 80em;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  align-content: center;
  img {
    height: 42px;
    margin-left: 20px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
`;

const Menu = styled.div`
  display: flex;
  margin-left: 50px;
`;

const MenuItem = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  cursor: pointer;
  width: 100px;
  height: 69px;
  margin: 0 10px;
  border-bottom: 3px;
  border-bottom-style: ${props => props.primary ? 'solid' : 'solid'};
  border-bottom-color: ${props => props.primary ? '#00A9E6' : 'transparent'};

  transition: all 0.2s linear;

  :hover {
    border-bottom-style: solid;
    border-bottom-color: #00A9E6;
  }
  a {
    color: #FFF;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  a:hover{
    text-decoration:none;
  }
`;

const MenuLink = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const PerfilContainer = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  img {
    margin-right: 12px;
  }
  transition: all 0.2s linear;
`;

const PerfilName = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin: 3px 0 3px 0;
  color: #FFF;
  transition: all 0.2s linear;
`;

const PerfilRol = styled.h5`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #B1C6D1;
  transition: all 0.2s linear;
`;

const MenuPais = styled.div`
  margin-right:25px;
`;

const BanderaMenu = styled.img`
  height:20px;
  margin: 3px 0 !important;
`;
const BanderaMenuText = styled.span`
    font-size: 10px;
    text-transform: capitalize;
    padding-left: 10px;
`;

export default{ Title, HeaderContainer, LogoContainer, MenuContainer, 
  PerfilContainer, PerfilName, PerfilRol, Menu, MenuItem, MenuLink, MenuPais, BanderaMenu, BanderaMenuText};