/* eslint-disable no-console */
import axios from 'axios';
import {env} from '../../../env';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const currentURl = `//${env.beURL}`;
const endpointCotizacion = 'coberturas';
const endpointURL = `${currentURl}/${endpointCotizacion}`;

export async function saveCoberturas(cotizacion, coberturas, id) {
  
  //console.log('Cotizacion: ', cotizacion, 'Coberturas: ', coberturas, endpointURL);

  await sleep(500); // simulate server latency

  // //GUARDAR COBERTURAS

  //POST COBERTURAS
  //------------------------------------------------

  const promises = coberturas.map(cobert => {
    const cobertura = {
      idCotizacion: id,
      codcobert: cobert.codcobert,
      sumaAsegurada: cobert.sumaAsegurada
    };

    if(cobert.sumaAsegurada > 0 && cobert.dependencia === null){
      //console.log(cobertura);

      return axios.post(endpointURL, cobertura).then(res => {
        //console.log(res);
        return res;
      }).catch( (error) => {
        console.log(error);
        //errorMessage('Lo Sentimos', 'Ha ocurrido un error, por favor intentelo mas tarde');
      });
    }



  });

  const resCoberturas = await Promise.all(promises)
    .then(values => {
      //console.log(values);
      return values;
    }).catch(error => {
      this.setState({
        error: error
      });
    });

}
