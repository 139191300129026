import React, { Component } from 'react';
import Header from '../common/header/header';
import styles from './index.styles';
import { withKeycloak } from 'react-keycloak';
import CircularProgress from '@material-ui/core/CircularProgress';
import Options from './welcome';
import {displayError, errorMessage} from '../../helpers/alerts';
import axios from 'axios';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import {env, source, pais, cambioParams, cambio, cia} from '../../env';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.profile = this.profile.bind(this);
    //this.getPaises = this.getPaises.bind(this);
    this.setPais = this.setPais.bind(this);
    this.state = {
      user: {},
      rol: '',
      loading: true,
      isTester: false,
      paises: []
    };
  } 
  profile() {
    this.props.keycloak.loadUserProfile().success(profile => {
      const rol = profile.attributes.rol;
      const isTester = _.includes(rol, 'prod' );
      const roles = this.props.keycloak.resourceAccess.cotizador.roles;

      //GET PAIS
      axios.post(`//${env.beURL}/strp-paises`, {
        estado: 'ACT'
      }).then(
        res => {
          //console.log(res);
          let paises;
          if(res.data.data){
            paises = res.data.data;
          } else {
            paises = res.data;
          }

          const paisesDominio = _.filter(
            paises, {'dominio': source.toUpperCase()}
          );
          this.setState({paises: paisesDominio});
    
          const paisesCia = _.filter(
            paises, {'codcia': cia.toUpperCase()}
          );
          
          //const paisDefecto = paisesCia[0];

          const paisDefecto = pais !== undefined ? _.find(paisesCia, {'codpais': pais.codpais, 'codcia': cia.toUpperCase()}) : paisesCia[0];

          ///console.log(paisDefectoTEst);

          const dominio = paisesCia[0].dominio;

          const userRolValid = _.includes(roles, dominio);

          console.log('DOMINIO: ', dominio , 'TIENE ROL VALIDO: ', userRolValid);

          if (userRolValid === false) { 
            displayError('Lo sentimos','Usted no tiene permisos para utilizar el cotizador con esta compañia.');
          }

          //SI DOMINIO Y PAIS NO SON IGUALES - LOGOUT
          console.log(pais, dominio)
          if(pais){
            if(pais.dominio !== dominio){
              //console.log(pais);
              this.props.keycloak.logout()
              const cookies = new Cookies();
              cookies.remove('pais');
              window.open(`/?cia=${paisDefecto.codcia}`, '_self');
            }
          }
          

          this.setState({paises: paisesCia, paisesCia: paisesCia, rolValido: userRolValid, paisDefecto: paisDefecto, loading: false});
          //this.setState({paises: paisesCia});
        }
      ).catch(
        () => {
          errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
        }
      );


      console.log(profile, roles, this.props.keycloak);
      this.setState({
        user: profile,
        rol: 'Intermediario',
        isTester: isTester
      });

    }).error(()=> {
      console.log('Failed to load user profile');
    });
    this.props.keycloak.loadUserInfo().success(info => {
      console.log(info);
    }).error(()=> {
      console.log('Failed to load user info');
    });
  }

  // getPaises(){
  //   axios.post(`//${env.beURL}/strp-paises`, {
  //     estado: 'ACT'
  //   }).then(
  //     res => {
  //       //console.log(res);
  //       let paises;
  //       if(res.data.data){
  //         paises = res.data.data;
  //       } else {
  //         paises = res.data;
  //       }
  //       const paisesDominio = _.filter(
  //         paises, {'dominio': source.toUpperCase()}
  //       );
  //       this.setState({paises: paisesDominio});

  //       const paisesCia = _.filter(
  //         paises, {'codcia': cia.toUpperCase()}
  //       );
  //       this.setState({paises: paisesDominio, paisesCia: paisesCia});
  //     }
  //   ).catch(
  //     () => {
  //       errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
  //     }
  //   );
  // }

  setPais(pais){
    console.log(pais);
    const cookies = new Cookies();
    cookies.set('pais', {codpais : pais.codpais, codcia: pais.codcia, descpais: pais.descpais, dominio: pais.dominio}, {path: '/'});
    window.open(`/?cia=${pais.codcia}`, '_self');
  }

  componentDidMount(){
    //LOAD PROFILE
    this.profile();
    //this.getPaises();

    console.log(cambio, cambioParams);
  }

  render() {
    const { HeaderContainer, ContentContainer, PasoContainer, 
      PasoName, PasoDesc, Paises, PaisContainer, Pais, PaisBreak, Loading } = styles;
    const { user, loading, isTester, paises, paisesCia, paisDefecto } = this.state;

    //console.log(paises, paisesCia, paisDefecto);
    
    let content = [];
    paises.forEach((pais, i) =>{
      if((i+1) % 4 === 0){
        content.push(<PaisContainer key={pais.codpais}>
          <Pais onClick={() => this.setPais(pais)} >
            <img src={`../../flags/${pais.codpais}.png`} alt={pais.descpais} />
          </Pais>
          <span>{pais.descpais}</span>
        </PaisContainer>);
        content.push(<PaisBreak key={i} />);
      }else{
        content.push(<PaisContainer key={pais.codpais}>
          <Pais onClick={() => this.setPais(pais)}>
            <img src={`../../flags/${pais.codpais}.png`} alt={pais.descpais} />
          </Pais>
          <span>{pais.descpais}</span>
        </PaisContainer>);
      }
    });

    return(
      <div> 
        <HeaderContainer>
          {!loading && 
            <Header user={user} rol={this.state.rol} paises={paises} paisDefecto={this.state.paisDefecto}/>
          } 
        </HeaderContainer>
        <ContentContainer className='container'>
          {loading && 
            <Loading>
              <CircularProgress color='primary' />
            </Loading>
          }
          {!loading && source ==='wws' && pais &&
            <Options paisDefecto={this.state.paisDefecto} isTester={isTester}/>
          }
          {!loading && source ==='wwm' && pais && 
            <Options paisDefecto={this.state.paisDefecto}  isTester={isTester}/>
          }
          {!loading && source ==='wcl' && pais && 
            <Options paisDefecto={this.state.paisDefecto}  isTester={isTester}/>
          }
          {!loading && source ==='wpy' && pais && 
            <Options paisDefecto={this.state.paisDefecto}  isTester={isTester}/>
          }
          {!loading && source ==='wwm' && !pais && 
            <div>
              <PasoContainer>
                <PasoName>Bienvenido</PasoName>
                <PasoDesc>Elija su pais de residencia</PasoDesc>
              </PasoContainer>
              <Paises>
                {content}
              </Paises>
            </div>
          }
          {!loading && source ==='wws' && !pais && 
            <div>
              <PasoContainer>
                <PasoName>Bienvenido</PasoName>
                <PasoDesc>Elija su pais de residencia</PasoDesc>
              </PasoContainer>
              <Paises>
                {content}
              </Paises>
            </div>
          }
          {!loading && source ==='wpy' && !pais && 
            <div>
              <PasoContainer>
                <PasoName>Bienvenido</PasoName>
                <PasoDesc>Elija su pais de residencia</PasoDesc>
              </PasoContainer>
              <Paises>
                {content}
              </Paises>
            </div>
          }
        </ContentContainer>
      </div>   
    );
  }
}

export default withKeycloak(Welcome);
//export default Welcome;