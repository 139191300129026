import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';

class RenderCheckBoxGroup extends Component {
  onChange(event){
    const {input, itemValue} = this.props;
    var oldValues = input.value || [];
    var newValues = _.without(oldValues, itemValue.codcobert);

    if(event.target.checked){
      newValues = oldValues.concat(itemValue.codcobert);
    }

    input.onChange(newValues);
  }

  render(){
    const{input, itemValue} = this.props;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value.indexOf(itemValue.codcobert) >= 0}
            onChange={this.onChange.bind(this)}
            value={itemValue.codcobert}
            color="default"
          />
        }
        label={itemValue.desccobert}
      />
      // <label>
      //   <input
      //     type='checkbox'
      //     checked={input.value.indexOf(itemValue.codcobert) >= 0}
      //     onChange={this.onChange.bind(this)}
      //   />
      //   {itemValue.descripcion}
      // </label>
    );
  }
}

export default RenderCheckBoxGroup;