/* eslint-disable no-console */
import validaciones from './api/vida/valBeneficios';
import moment from 'moment';
//import axios from 'axios';
import _ from 'lodash';
import {cia, pais} from '../env';


const validate = values => {

  const errors = {};
  const minima = moment().subtract(18, 'years');
  const edad = moment().diff(values.fechaDeNacimiento, 'years');
  const url = window.location.pathname.slice(1);
  let edadMaxima;

  if(url === 'salud' || url === 'saludcolectivo'){
    edadMaxima = 74;
  }else{
    edadMaxima = cia === '03' ? 74 : 74;
  }

  let minimoTitulares;

  if(cia === '03'){
    minimoTitulares = 3;
  }else{
    minimoTitulares = 15;
  }

  const madt = _.find(validaciones, {'codcobert': 'MADT'});
  const itpt = _.find(validaciones, {'codcobert': 'ITPT'});
  const enfg = _.find(validaciones, {'codcobert': 'ENFG'});
  //const exon = _.find(validaciones, {'codcobert': 'EXON'});
  const devp = _.find(validaciones, {'codcobert': 'DEVP'});

  

  //console.log(url, edadMaxima, minimoTitulares);

  //VIDA

  //Paso 1
  if (!values.plan) {
    errors.plan = 'Campo requerido';
  }
  //Paso 2
  if (!values.nombres) {
    errors.nombres = 'Campo requerido';
  }
  if (!values.apellidos) {
    errors.apellidos = 'Campo requerido';
  }
  if (!values.email) {
    errors.email = 'Campo requerido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Correo electrónico invalido';
  }
  if (!values.sexo) {
    errors.sexo = 'Campo requerido';
  }
  if (!values.fumador) {
    errors.fumador = 'Campo requerido';
  }
  if (!values.fechaDeNacimiento) {
    errors.fechaDeNacimiento = 'Campo requerido';
  } else if (moment(values.fechaDeNacimiento).isSameOrAfter(minima)) {
    errors.fechaDeNacimiento = 'La edad mínima para cotizar es de 18 años';
  }else if (edad > edadMaxima) {
    errors.fechaDeNacimiento = `La edad máxima para cotizar es de ${edadMaxima} años`;
  }
  if (!values.beneficiosAdicionales) {
    errors.beneficiosAdicionales = 'Campo requerido';
  }
  //Paso 3
  if (!values.periodoCobertura) {
    errors.periodoCobertura = 'Campo requerido';
  }
  if(cia === '02'){
    if(values.plan === '0183' && Number(values.periodoCobertura) < 10) {
      //console.log('ERROR');
      errors.periodoCobertura = 'Campo requerido';
    }
  }
  if(cia === '03'){
    if(values.plan === '0187' && Number(values.periodoCobertura) < 15) {
      //console.log(values.plan, Number(values.periodoCobertura), 'ERROR');
      errors.periodoCobertura = 'Campo requerido';
    }
  }
  if(cia === '08'){
    if(values.plan === '0215' && Number(values.periodoCobertura) < 15) {
      //console.log(values.plan, Number(values.periodoCobertura), 'ERROR');
      errors.periodoCobertura = 'Periodo de cobertura debe ser mayor de 15';
    }
  }
  if(pais.descpais === 'PERU'){
    if(values.tipoprod !== 'SALUD' && Number(values.sumaAsegurada) > 2000000) {
      //console.log(values.plan, Number(values.periodoCobertura), 'ERROR');
      errors.sumaAsegurada = 'La suma máxima es de $2,000,000';
    }
  }
  if (!values.duracionPrimas) {
    errors.duracionPrimas = 'Campo requerido';
  }
  if (!values.plazoPagos) {
    errors.plazoPagos = 'Campo requerido';
  }
  if (!values.sumaAsegurada) {
    errors.sumaAsegurada = 'Campo requerido';
  }else if (values.sumaAsegurada < 25000) {
    errors.sumaAsegurada = 'El monto mínimo a asegurar es de US$25,000';
  }
  if(cia === '08'){
    if(values.plan === '0213' && values.sumaAsegurada < 50000) {
      //console.log(values.plan, Number(values.periodoCobertura), 'ERROR');
      errors.sumaAsegurada = 'El monto mínimo a asegurar es de US$50,000';
    }
    if(values.plan === '0214' && values.sumaAsegurada < 100000) {
      //console.log(values.plan, Number(values.periodoCobertura), 'ERROR');
      errors.sumaAsegurada = 'El monto mínimo a asegurar es de US$100,000';
    }
    if(values.plan === '0215' && values.sumaAsegurada < 25000) {
      //console.log(values.plan, Number(values.periodoCobertura), 'ERROR');
      errors.sumaAsegurada = 'El monto mínimo a asegurar es de US$25,000';
    }
    if(values.plan === '0215' && Number(values.periodoCobertura) < 15) {
      //console.log(values.plan, Number(values.periodoCobertura), 'ERROR');
      errors.periodoCobertura = 'El periodo mínimo de la poliza debe ser 15 años';
    }
  }
  //Paso 4
  //SUMA ASEGURADA SUPERV
  if(values.plan === '0191' || values.plan === '0208' || values.plan === '0215'){
    if (!values.tipoPropuesta) {
      errors.tipoPropuesta = 'Campo requerido';
    }
    if (!values.psumaasesuperv) {
      errors.psumaasesuperv = 'Campo requerido';
    }
    if (values.psumaasesuperv > values.sumaAsegurada) {
      errors.psumaasesuperv = 'El monto no debe ser mayor a la suma segurada';
    }
    if (values.tipoPropuesta === '1') {
      if (values.psumaasesuperv < 10000) {
        errors.psumaasesuperv = 'El monto no debe ser menor de $10,000';
      }
    }
    if (values.tipoPropuesta === '2') {
      console.log(values);
      if (values.psumaasesuperv > Number(values.primaNeta)) {
        errors.psumaasesuperv = 'El monrto no puede ser mayor a la prima máxima';
      }
    }
  }

  //MADT
  if (values.MADT > madt.saMaxima) {
    errors.MADT = 'La suma asegurada no debe ser mayor de $400,000';
  }else if (values.MADT >= values.sumaAsegurada){
    errors.MADT = 'El valor debe ser menor a la Suma Asegurada';
  }
  //ITPT
  if (values.ITPT > itpt.saMaxima) {
    errors.ITPT = 'La suma asegurada no debe ser mayor de $400,000';
  }else if (values.ITPT >= values.sumaAsegurada){
    errors.ITPT = 'El valor debe ser menor a la Suma Asegurada';
  }
  //ENFG
  if (values.ENFG > enfg.saMaxima) {
    errors.ENFG = 'La suma asegurada no debe ser mayor de $400,000';
  }else if (values.ENFG >= values.sumaAsegurada){
    errors.ENFG = 'El valor debe ser menor a la Suma Asegurada';
  }
  //EXON
  if (values.EXON >= values.sumaAsegurada){
    errors.EXON = 'El valor debe ser menor a la Suma Asegurada';
  }
  //DEVP
  if (values.DEVP > devp.saMaxima) {
    errors.DEVP = 'La suma asegurada no debe ser mayor de $300,000';
  }else if (values.DEVP >= Number(values.sumaAsegurada * devp.porcentajeSA)){
    errors.DEVP = `El valor debe ser menor o igual al ${devp.porcentajeSA} de la Suma Asegurada`;
  }


  //SALUD

  // if (!values.opcion) {
  //   errors.opcion = 'Campo requerido';
  // }
  if (!values.opcion && !values.todosDeducibles) {
    errors.opcion = 'Campo requerido';
  }

  //DEPENDIENTES
  if(values.dependientes){
    const dependientesArrayErrors = [];
    values.dependientes.forEach((dependiente, i) => {
      const dependienteErrors = {};
      const edad = moment().diff(dependiente.edad, 'years');
      const minima = moment().diff(dependiente.edad, 'days');
      //console.log(edad, minima);
      if (!dependiente || !dependiente.nombres) {
        dependienteErrors.nombres = 'Campo requerido';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (!dependiente || !dependiente.apellidos) {
        dependienteErrors.apellidos = 'Campo requerido';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (!dependiente || !dependiente.parentesco) {
        dependienteErrors.parentesco = 'Campo requerido';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (!dependiente || !dependiente.sexo) {
        dependienteErrors.sexo = 'Campo requerido';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (!dependiente || !dependiente.edad) {
        dependienteErrors.edad = 'Campo requerido';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (!dependiente || edad > edadMaxima) {
        dependienteErrors.edad = 'Campo requerido';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (dependiente.parentesco === 'H' && edad > 24) {
        dependienteErrors.edad = 'La edad máxima para dependientes hijos es de 24 años';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (dependiente.parentesco === 'H' && minima <= 0) {
        dependienteErrors.edad = 'La edad mínima para dependientes hijos es de 1 día';
        dependientesArrayErrors[i] = dependienteErrors;
      }
      if (dependiente.parentesco === 'C' && edad <= 18) {
        dependienteErrors.edad = 'La edad mínima para los conyuges es de 18 años';
        dependientesArrayErrors[i] = dependienteErrors;
      }
    });
    if (dependientesArrayErrors.length) {
      errors.dependientes = dependientesArrayErrors;
    }
  }

  //SALUD COLECTIVA

  if (!values.cliente) {
    errors.cliente = 'Campo requerido';
  }

  if (!values.certificados || !values.certificados.length) {
    errors.certificados = { _error: 'Se debe agregar al menos un titular a la poliza' };
  }

  if (!values.certificados || values.certificados.length < minimoTitulares) {
    errors.certificados = {_error: `La cantidad mínima de titulares es de ${minimoTitulares}` };
  }
  
  if(values.certificados){
    const certificadosArrayErrors = [];
    values.certificados.forEach((certificado, i) => {
      const certificadosErrors = {};
      const edad = moment().diff(certificado.fechaDeNacimiento, 'years');
      //const minima = moment().diff(certificado.edad, 'days');
      if (!certificado || !certificado.nombres) {
        certificadosErrors.nombres = 'Campo requerido';
        certificadosArrayErrors[i] = certificadosErrors;
      }
      if (!certificado || !certificado.apellidos) {
        certificadosErrors.apellidos = 'Campo requerido';
        certificadosArrayErrors[i] = certificadosErrors;
      }
      if (!certificado || !certificado.sexo) {
        certificadosErrors.sexo = 'Campo requerido';
        certificadosArrayErrors[i] = certificadosErrors;
      }
      if (!certificado || !certificado.fechaDeNacimiento) {
        certificadosErrors.fechaDeNacimiento = 'Campo requerido';
        certificadosArrayErrors[i] = certificadosErrors;
      } else if (edad < 18) {
        certificadosErrors.fechaDeNacimiento = 'La edad mínima para cotizar es de 18 años';
        certificadosArrayErrors[i] = certificadosErrors;
      }
      else if (edad > edadMaxima) {
        certificadosErrors.fechaDeNacimiento = `La edad máxima para cotizar es de ${edadMaxima} años`;
        certificadosArrayErrors[i] = certificadosErrors;
      }
      if (certificado && certificado.dependientes && certificado.dependientes.length) {
        const dependientesArrayErrors = [];
        //Duplicate Conyugue
        const unique = _.filter(certificado.dependientes, function(o) { return o.parentesco === 'C'; });
        console.log(unique);
        unique.length > 1 ? console.log(true) : console.log(false);

        

        //console.log('hasDuplicate: ', duplicateC(certificado.dependientes));
        certificado.dependientes.forEach((dependiente, di) => {
          const dependienteErrors = {};
          const edad = moment().diff(dependiente.edad, 'years');
          const minima = moment().diff(dependiente.edad, 'days');

          //console.log(edad, minima);
          if (!dependiente || !dependiente.nombres) {
            dependienteErrors.nombres = 'Campo requerido';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (!dependiente || !dependiente.apellidos) {
            dependienteErrors.apellidos = 'Campo requerido';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (!dependiente || !dependiente.parentesco) {
            dependienteErrors.parentesco = 'Campo requerido';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (!dependiente || !dependiente.sexo) {
            dependienteErrors.sexo = 'Campo requerido';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (!dependiente || !dependiente.edad) {
            dependienteErrors.edad = 'Campo requerido';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (!dependiente || edad > edadMaxima) {
            dependienteErrors.edad = `La edad máxima para cotizar es de ${edadMaxima} años`;
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (dependiente.parentesco === 'H' && edad > 24) {
            dependienteErrors.edad = 'La edad máxima para dependientes hijos es de 24 años';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (dependiente.parentesco === 'H' && minima <= 0) {
            dependienteErrors.edad = 'La edad mínima para dependientes hijos es de 1 día';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (dependiente.parentesco === 'C' && edad <= 18) {
            dependienteErrors.edad = 'La edad mínima para los conyuges es de 18 años';
            dependientesArrayErrors[di] = dependienteErrors;
          }
          if (unique.length > 1) {
            dependienteErrors.parentesco = 'Solo puede haber un conyugue por titular';
            dependientesArrayErrors[di] = dependienteErrors;
          }
        });
        if (dependientesArrayErrors.length) {
          certificadosErrors.dependientes = dependientesArrayErrors;
          certificadosArrayErrors[i] = certificadosErrors;
        }
      }

    });
    if (certificadosArrayErrors.length) {
      errors.certificados = certificadosArrayErrors;
    }
  }


  return errors;

};

export default validate;
