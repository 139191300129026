import React from 'react';
import styles from '../../cotizador-salud/index.styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Radio from '@material-ui/core/Radio';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {cia} from '../../../env';

//import { Link } from '@material-ui/core';

const Plan = (props) => {
  const {  Plan, 
    FondoPlan, TituloPlan, DetallesPlan, MyModal, InnerModal, BeneficiosPlan
  } = styles;

  //MODAL STYLES AN HANDLERS
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //console.log(props.producto);

  return ( 
    <div>
      {props.producto &&
      <Plan>
        <FondoPlan bgImage={props.producto.img}>
          <Radio 
            value={props.producto.codprod} label={props.producto.descprod} 
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircle />}
            color="default"
          />
        </FondoPlan>
        <DetallesPlan>
          <TituloPlan>{props.producto.descprod}</TituloPlan>
          <BeneficiosPlan>
            <button type="button" onClick={handleOpen}>
              Beneficios
            </button>
          </BeneficiosPlan>
        </DetallesPlan>

        <MyModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          //className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <InnerModal>
              <img src={`../../../beneficios/${cia}/${props.producto.codprod}.jpg`} />
            </InnerModal>
          </Fade>
        </MyModal>
      </Plan> 
      }
    </div>
  );
};
 
export default Plan;