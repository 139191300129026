import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
//import PropTypes from 'prop-types';

const RenderRadioGroup = ({ input, ...rest }) => {
  return (
    <RadioGroup
      {...input}
      {...rest}
      valueselected={input.value}
      onChange={(event, value) => input.onChange(value)}
    >
    </RadioGroup>
  );
};

export default RenderRadioGroup;