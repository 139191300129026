import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { Field, reduxForm } from 'redux-form';
import validate from '../../helpers/validate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RenderRadioButton from '../common/fields/renderRadioButton';
import Radio from '@material-ui/core/Radio';
import RenderTextField from '../common/fields/renderTextField';
import RenderDateField from '../common/fields/renderDateField';
import RenderSelectField from '../common/fields/renderSelectField';
import TrendingFLat from '@material-ui/icons/TrendingFlat';

const CotizadorPasoDos = props => {
  const { handleSubmit, previousPage } = props;
  const { PasoContainer, PasoName, PasoDesc, PasoForm, PasoButtons, SiguienteButton, AnteriorButton, FieldRow, FieldRowFumador } = styles;
  return (
    <PasoContainer>
      <PasoName>Paso 2</PasoName>
      <PasoDesc>Díganos sobre usted</PasoDesc>
      <PasoForm>
        <form onSubmit={handleSubmit}>
          <FieldRow>
            <Field
              name="nombres"
              component={RenderTextField}
              label="Nombres"
            />
            <Field
              name="apellidos"
              component={RenderTextField}
              label="Apellidos"
            />
          </FieldRow>
          <FieldRow>
            <Field
              name="sexo"
              component={RenderSelectField}
              label="Sexo"
            >
              <option value=""></option>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
            </Field>
            <Field name="fechaDeNacimiento" label="Fecha de Nacimiento" component={RenderDateField} />
          </FieldRow>
          <FieldRowFumador>
            <Field name="fumador" label="Condición de fumador" component={RenderRadioButton}>
              <FormControlLabel value="S" control={<Radio color="default" />} label="Fumador" />
              <FormControlLabel value="N" control={<Radio color="default" />} label="No Fumador" />
            </Field>
          </FieldRowFumador>
          <PasoButtons>
            <AnteriorButton type="button" className="previous" onClick={previousPage}>
              <TrendingFLat />
              <div>Anterior</div>
            </AnteriorButton>
            <SiguienteButton type="submit" className="next">
              <div>Siguiente</div>
              <TrendingFLat />
            </SiguienteButton>
          </PasoButtons>
        </form>
      </PasoForm>
    </PasoContainer>
  );
};

CotizadorPasoDos.propTypes = {
  meta: PropTypes.object,
  handleSubmit: PropTypes.func,
  previousPage: PropTypes.any
};

export default reduxForm({
  form: 'cotizador', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(CotizadorPasoDos);
