const LOAD = 'LOAD';

const reducer = (state = {}, action) => {
  switch (action.type){
  case LOAD:
    console.log(action.data);
    console.log(state);
    return{
      ...state,
      data: {
        ...state.certificados,
        cliente: action.data.cliente,
        certificados: action.data.certificados
      }
      
      //data: action.data
    };
  default:
    return state;
  }
};

export const load = data => ({type: LOAD, data});
export default reducer;