import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from 'react-keycloak';
import {AppRouter} from './components/common/routes/index';

const keycloak = new Keycloak();

const keycloakProviderInitConfig = {
  onLoad: 'login-required'
};

class App extends Component {
  
  render() {

    return (
      <KeycloakProvider 
        keycloak={keycloak}
        initConfig={keycloakProviderInitConfig}
      >
        <div className="body">
          <AppRouter />
        </div>
      </KeycloakProvider>
    ); 
  }
}
export default App;