import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.png';
import logoPanama from '../../../images/logoPanama.png';
import logoEva from '../../../images/logoEva.png';
import perfilPic from '../../../images/perfilPic.png';
import MenuPais from './menuPais';
import styles from './index.styles';
import Cookies from 'universal-cookie';
import { withKeycloak } from 'react-keycloak';
import {source, pais} from '../../../env';

class Header extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
  } 
  logOut(){
    //console.log(pais);
    this.props.keycloak.logout()
    const cookies = new Cookies();
    cookies.remove('pais');
    window.open(`/?cia=${pais.codcia}`, '_self');
  }
  
  render(){
    const {HeaderContainer, LogoContainer, MenuContainer, PerfilContainer, 
      PerfilName, PerfilRol, Menu, MenuItem, MenuLink} = styles;
    const {paises, paisDefecto} = this.props;

    return(
      <HeaderContainer>
        <LogoContainer>
          <Link to="/">
            {source === 'wws' && <img src={logo} alt="Logo" />}
            {source === 'eva' && <img src={logoEva} alt="Logo" />}
            {source === 'wwm' && <img src={logoPanama} alt="Logo" />}
            {source === 'wcl' && <img src={logoPanama} alt="Logo" />}
            {source === 'wwsm' && <img src={logoPanama} alt="Logo" />}
            {source === 'wpy' && <img src={logoPanama} alt="Logo" />}
            {source === 'irg' && <img src={logoPanama} alt="Logo" />}
          </Link>
        </LogoContainer>
        <MenuContainer className='menu'>
          <Menu>
            {paisDefecto.salud === 'S' &&
              <MenuItem primary={this.props.salud}>
                <Link to="/salud">Salud</Link>
              </MenuItem>
            }
            {paisDefecto.saludColectivo === 'S' &&
              <MenuItem primary={this.props.saludcolectivo}>
                <Link to="/saludcolectivo">Salud Colectiva</Link>
              </MenuItem>
            }
            {paisDefecto.vida === 'S' &&
              <MenuItem primary={this.props.primary}>
                <Link to="/vida">Vida</Link>
              </MenuItem>
            }
            <MenuItem>
              <MenuLink onClick={() => this.logOut()}>Salir</MenuLink>
            </MenuItem>
          </Menu>
        </MenuContainer>
        <PerfilContainer className='perfil'>
          {source === 'wwm' && pais &&
            <MenuPais paises={paises} paisSelected={pais} />
          }
          {source === 'wcl' && pais &&
            <MenuPais paises={paises} paisSelected={pais} />
          }
          <div className='perfilPic'>
            <img src={perfilPic} alt='Imagen de Perfil'/>
          </div>
          <div className='perfilDetails'>
            <PerfilName>
              <div>
                {this.props.user &&
                  this.props.user.firstName !== '' ? `${this.props.user.firstName} ${this.props.user.lastName}` : ''}
                {/* {!this.props.keycloak.authenticated ? 'NOT ' : ''} */}
              </div>
            </PerfilName>
            <PerfilRol>
              {this.props.rol &&
                this.props.rol !== '' ? this.props.rol : ''}
            </PerfilRol>
          </div>
        </PerfilContainer>
      </HeaderContainer>
    );
  }
  
}

export default withKeycloak(Header);