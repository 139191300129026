import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import validate from '../../helpers/validate';
import RenderTextField from '../common/fields/renderTextField';
import RenderDateField from '../common/fields/renderDateField';
import RenderSelectField from '../common/fields/renderSelectField';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import Button from '@material-ui/core/Button';
//CARD
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, styled } from '@material-ui/core/styles';
//ACCORDION
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as XLSX from 'xlsx';
import {load as cargaCertificados} from '../../reducers/certificados';
import { connect } from 'react-redux';
import _ from 'lodash';

import { formValues } from 'redux-form';

let CotizadorPasoUno = props => {
  const { handleSubmit, load, cliente } = props;
  const { PasoContainer, PasoName, PasoDesc, PasoForm, PasoFormColec ,PasoButtons, SiguienteButton, FieldRow, FieldRowOne,
    TituloDependiente, DependientesTitle, DependientesButton, DependientesButtons } = styles;
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleInputChange = (event) => {
    const target = event.target;
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //const this2 = this;
    // this.setState({
    //   [name]: value
    // });
    let hojas = [];
    let certificados = [];
    let lastIndex;
    if (name === 'file') {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {type: 'array'});

        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          //certificados.push({cliente: cliente});
          XL_row_object.map((aseg, index) => {
            if (aseg.parentesco === 'T'){
              aseg.i = index;
              lastIndex = index;
              certificados.splice(index, 0, aseg);
              //return aseg;
              //console.log(certificados);
            }else{
              //console.log(certificados);
              //console.log(index, lastIndex);
              //console.log(_.find(certificados, {'i': lastIndex}));
              let titular = _.find(certificados, {'i': lastIndex});
              aseg.edad = aseg.fechaDeNacimiento;
              if('dependientes' in titular){
                titular.dependientes.push(aseg);
              }else{
                titular.dependientes = [];
                titular.dependientes.push(aseg);
              }
            }
          });

          //const cliente = formValues('cotizadorSaludColectivo')(CotizadorPasoUno);

          console.log(cliente);

          hojas.push({
            data: XL_row_object,
            sheetName,
            certificados
          });
        });



        console.log(hojas);
        load({cliente: cliente, certificados: hojas[0].certificados});
        // this2.setState({
        //   selectedFileDocument: target.files[0],
        //   hojas
        // });
      };
    }
  }; 

  const useStyles = makeStyles({
    card: {
      borderRadius: 0,
      boxShadow: 'none'
    },
    root: {
      width: '100%',
    },
    alice:{
      background: 'aliceblue'
    },
    titulo: {
      margin: '10px 0'
    },
    cargarButton: {
      backgroundColor: '#FFF',
      border: '#CCC solid 1px',
      borderRadius: '7px',
      width: '240px',
      height: '60px',
      padding: '0 20px',
      margin: '10px 10px',
      color: '#6c757d',
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 'normal',
    
      transition: 'all 0.2s linear',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.075)'
      }
    }

  
    // div {
    //   float: left;
    //   margin-top: 4px;
    // }
  
    // svg{
    //   float: right;
    // }

  });
  const classes = useStyles();

  const CardActionsTop = styled(CardActions)({
    paddingLeft: 25,
    paddingBottom : 0
  });

  const IconButtonRigth = styled(IconButton)({
    marginLeft: 'auto !important',
    color: '#00A9E6'
  });

  const CardDependientes = styled(Card)({
    marginBottom: '10px !important',
    paddingTop: 0,
    transition: 'all 0.2s linear'
  });

  const CardDependientesContent = styled(CardContent)({
    paddingTop: 0
  });
  
  const renderCertificados = ({ fields, meta: { error, submitFailed } }) => (
    <div className={classes.root}>
      {fields.map((certificado, index) => (
        <Accordion key={index} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.alice}
          >
            <Typography className={classes.heading}>
              <h5 className={classes.titulo}>Titular #{index + 1}</h5>
            </Typography>
            <IconButtonRigth 
              className={classes.buttom_left }
              onClick={() => fields.remove(index)}
              title="Borrar Titular"
              aria-label="delete">
              <DeleteIcon fontSize="small"/>
            </IconButtonRigth>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.root}>
              <FieldRow>
                <Field
                  name={`${certificado}.nombres`}
                  component={RenderTextField}
                  label="Nombres"
                />
                <Field
                  name={`${certificado}.apellidos`}
                  component={RenderTextField}
                  label="Apellidos"
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name={`${certificado}.sexo`}
                  component={RenderSelectField}
                  label="Sexo"
                >
                  <option value=""></option>
                  <option value="H">Masculino</option>
                  <option value="M">Femenino</option>
                </Field>
                <Field name={`${certificado}.fechaDeNacimiento`} label="Fecha de Nacimiento" component={RenderDateField} />
              </FieldRow>

              <FieldArray name={`${certificado}.dependientes`} component={renderDependientes} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      
      <DependientesButtons>
        <DependientesButton type="button" onClick={() => fields.push({})}>
          <div>Agregar Titular</div>
          <ControlPointOutlinedIcon />
        </DependientesButton>
        <div className={classes.root}>
          <input
            style={{display: 'none'}}
            onChange={handleInputChange} 
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            name="file"
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span" className={classes.cargarButton}>
              <div>CARGAR ASEGURADOS</div>
            </Button> 
          </label>
        </div>
        <div>
          {submitFailed && error && <span>{error}</span>}
        </div>
      </DependientesButtons>
      
    </div>
  );


  const renderDependientes = ({ fields, meta: { error, submitFailed } }) => (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        {fields.length > 0 ? <DependientesTitle>Dependientes</DependientesTitle> : ''}
        {fields.map((dependiente, index) => (
          <CardDependientes key={index}>
            <CardActionsTop className={ classes.card_action }>
              <TituloDependiente>Dependiente #{index + 1}</TituloDependiente>
              <IconButtonRigth 
                className={classes.buttom_left }
                title="Borrar dependiente"
                onClick={() => fields.remove(index)}
                aria-label="delete">
                <DeleteIcon fontSize="small"/>
              </IconButtonRigth>
            </CardActionsTop>
            <CardDependientesContent>
              <FieldRow>
                <Field
                  name={`${dependiente}.nombres`}
                  component={RenderTextField}
                  label="Nombres"
                />
                <Field
                  name={`${dependiente}.apellidos`}
                  component={RenderTextField}
                  label="Apellidos"
                />
              </FieldRow>
              <FieldRow>
                <Field
                  name={`${dependiente}.parentesco`}
                  component={RenderSelectField}
                  label="Parentesco"
                >
                  <option value=""></option>
                  <option value="C">Conyugue</option>
                  <option value="H">Hijo</option>
                </Field>
                <Field
                  name={`${dependiente}.sexo`}
                  component={RenderSelectField}
                  label="Sexo"
                >
                  <option value=""></option>
                  <option value="H">Masculino</option>
                  <option value="M">Femenino</option>
                </Field>
            
              </FieldRow>
              <FieldRow>
                <Field name={`${dependiente}.edad`} label="Fecha de Nacimiento" component={RenderDateField} />
              </FieldRow>
            </CardDependientesContent>
          </CardDependientes>
        ))}

        <DependientesButtons>
          <DependientesButton type="button" onClick={() => fields.push({})}>
            <div>Agregar Dependientes</div>
            <ControlPointOutlinedIcon />
          </DependientesButton>
          {submitFailed && error && <span>{error}</span>}
        </DependientesButtons>
      </CardContent>
    </Card>

  );

  return (
    <PasoContainer>
      <PasoName>Paso 1</PasoName>
      <PasoDesc>Díganos sobre su grupo</PasoDesc>

      <PasoForm>
        <form onSubmit={handleSubmit}>
          <PasoFormColec>
            <FieldRowOne>
              <Field
                name="cliente"
                component={RenderTextField}
                label="Cliente"
              />
            </FieldRowOne>   
          </PasoFormColec>

          <FieldArray name="certificados" component={renderCertificados} />

          <PasoButtons>
            <SiguienteButton type="submit" className="next">
              <div>Siguiente</div>
              <TrendingFLat className="rightIcon" />
            </SiguienteButton>
          </PasoButtons>

        </form>
      </PasoForm>
    </PasoContainer>
  );
};

CotizadorPasoUno = reduxForm({
  form: 'cotizadorSaludColectivo', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(CotizadorPasoUno);

CotizadorPasoUno.propTypes = {
  meta: PropTypes.object,
  handleSubmit: PropTypes.func,
  previousPage: PropTypes.any
};
const selector = formValueSelector('cotizadorSaludColectivo');
CotizadorPasoUno = connect(
  state => ({
    cliente: selector(state, 'cliente'),
    initialValues : state.certificados.data,
  }),
  {load : cargaCertificados}
)(CotizadorPasoUno);

export default CotizadorPasoUno;
