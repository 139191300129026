/* eslint-disable no-console */
import axios from 'axios';
import {env, cia, pais} from '../../../env';
import _ from 'lodash';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const currentURl = `//${env.beURL}`;
const endpointCotizacion = 'cotizacion';
const endpointURL = `${currentURl}/${endpointCotizacion}`;

export async function saveCotizacion(valores, user, cot, codBroker) {
  //console.log(cot);

  const prima = _.find(cot, (o) => { return o.pformapago === valores.plazoPagos; });

  const apellidos = valores.apellidos;
  const nombres = valores.nombres;
  const authorName = `${user.firstName} ${user.lastName}`;

  const apellidosUpper = apellidos.toUpperCase();
  const nombresUpper = nombres.toUpperCase();
  const authorNameUpper = authorName.toUpperCase();

  const codBrokerSplit = codBroker.split(',');
  const firstCodbroker = codBrokerSplit[0];

  console.log(codBrokerSplit, firstCodbroker);

  //console.log(apellidos, apellidosUpper);

  const cotizacion = {
    idCompania: cia,
    codBroker: codBroker ? firstCodbroker : '',
    pais: pais.codpais,
    codprod: valores.plan,
    fechaNacimiento: valores.fechaDeNacimiento,
    periodoPagos: valores.duracionPrimas,
    sexo: valores.sexo,
    fumador: valores.fumador === 'N' ? 0 : 1,
    periodoCobertura: valores.periodoCobertura,
    sumaAsegurada: valores.sumaAsegurada,
    sumaAseguradaSupervivencia: valores.sumaAseguradaSupervivencia ? valores.sumaAseguradaSupervivencia : 0,
    author: user.username,
    authorName: authorNameUpper,
    cliente: nombresUpper,
    clienteApellidos: apellidosUpper,
    cargoAdmin:cot.outCargoAdmin ? cot.outCargoAdmin : prima.outCargoAdmin,
    formaPago:cot.pformapago ? cot.pformapago : prima.pformapago,
    primaNeta:cot.outPrimaNeta ? cot.outPrimaNeta : prima.outPrimaNeta,
    impuestos: cot.outImpuestos ? cot.outImpuestos : prima.outImpuestos
  };

  await sleep(500); // simulate server latency

  //GUARDAR COTIZACION

  return axios.post(endpointURL, cotizacion)
    .then(res => {
      console.log(res);
      return res.data;
    }).catch( error => {
      console.log(error);
    });
}
