import React, { Component } from 'react';
import Header from '../header/header';
import Cotizador from '../../cotizador-salud-cambios/cotizador';
import showResults from '../../../helpers/showResults';
import styles from './index.styles';
import { withKeycloak } from 'react-keycloak';
import {errorMessage} from '../../../helpers/alerts';
import axios from 'axios';
import {env, source} from '../../../env';
import _ from 'lodash';

class LayoutSaludCambios extends Component {
  constructor(props) {
    super(props);
    this.profile = this.profile.bind(this);
    this.getPaises = this.getPaises.bind(this);
    this.state = {
      user: {
        firstName: '',
        email: '',
        lastName: '',
        rol: '',
        username: ''
      },
      rol: '',
      userInfo:{},
      paises: []

    };
  }
  profile() {
    this.props.keycloak.loadUserProfile().success(profile => {
      //console.log(JSON.stringify(profile, null, '  '));
      this.setState({
        user: profile,
        rol: 'Intermediario'
      });
    }).error(function() {
      //console.log('Failed to load user profile');
    });
  }

  info() {
    this.props.keycloak.loadUserInfo().success(info => {
      //console.log(JSON.stringify(profile, null, '  '));
      this.setState({
        userInfo: info
      });
    }).error(function() {
      //console.log('Failed to load user profile');
    });
  }

  getPaises(){
    axios.post(`//${env.beURL}/strp-paises`, {
      estado: 'ACT'
    }).then(
      res => {
        //console.log(res);
        let paises;
        if(res.data.data){
          paises = res.data.data;
        } else {
          paises = res.data;
        }
        const paisesDominio = _.filter(
          paises, {'dominio': source.toUpperCase()}
        );
        this.setState({paises: paisesDominio});
      }
    ).catch(
      () => {
        errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
      }
    );
  }

  componentDidMount(){
    //LOAD PROFILE
    this.profile();
    this.getPaises();
    this.info();
  }

  render() {
    const { HeaderContainer, ContentContainer } = styles;
    const { paises } = this.state;
    return(
      <div> 
        <HeaderContainer>
          <Header salud user={this.state.user} rol={this.state.rol} paises={paises} />
        </HeaderContainer>
        <ContentContainer className='container'>
          <div className='row'>
            <div className='col'>
              <Cotizador onSubmit={showResults} user={this.state.user} userInfo={this.state.userInfo} cia={this.props.match.params.cia} />
            </div>
          </div> 
        </ContentContainer>
      </div>
    );
  }
}

export default withKeycloak(LayoutSaludCambios);
//export default Layout;