/* eslint-disable no-console */
import axios from 'axios';
import {env, cia, pais} from '../../../env';
import moment from 'moment';
import _ from 'lodash';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const currentURl = `//${env.beURL}`;
const endpointCotizacion = 'cotizaciones-salud';
const endpointURL = `${currentURl}/${endpointCotizacion}`;

export async function saveCotizacion(valores, user, numcot, vigencia, primas, codBroker, opcionADesplegar, valorPlazoPagos) {
  const opcionesDesp = opcionADesplegar.opcionesDesplegar.split(',');
  const apellidos = valores.apellidos;
  const nombres = valores.nombres;
  const authorName = `${user.firstName} ${user.lastName}`;

  const apellidosUpper = apellidos.toUpperCase();
  const nombresUpper = nombres.toUpperCase();
  const authorNameUpper = authorName.toUpperCase();

  let asegurados;
  //ASEGURADOS
  if(valores.dependientes){
    const preAsegurados = _.cloneDeep(valores.dependientes);
    const asegPrincipal = {
      nombres: valores.nombres,
      apellidos: valores.apellidos,
      sexo: valores.sexo,
      edad: valores.fechaDeNacimiento,
      parentesco: 'T'
    }
  
    const isPrincipalShifted = _.find(preAsegurados, {nombres: valores.nombres, apellidos: valores.apellidos});
  
    if(!isPrincipalShifted){
      preAsegurados.unshift(asegPrincipal);
    }
    asegurados = preAsegurados.map((dep, i) => {
      const edad = moment().diff(dep.edad, 'years');
      const isConyugue = dep.parentesco === 'C' ? true : false;
      const isHijo = dep.parentesco === 'H' ? true : false;
      const isTitular = dep.parentesco === 'T' ? true : false;
  
      let codrel = '';
      
      if(isTitular && dep.sexo === 'H'){
        codrel = '0001'
      }else if (isTitular && dep.sexo === 'M'){
        codrel = '0002'
      }else if (isConyugue && dep.sexo === 'M'){
        codrel = '0008'
      }else if (isTitular && dep.sexo === 'H'){
        codrel = '0007'
      }else if (isHijo && dep.sexo === 'M'){
        codrel = '0006'
      }else{
        codrel = '0005'
      }
  
      const aseg = {
        numaseg: i,
        nombres: dep.nombres,
        apellidos: dep.apellidos,
        codrel: codrel,
        sexo: dep.sexo,
        fecnac: dep.edad,
        indmenor: isHijo && edad <= 25 ? 'S' : 'N'
      }
  
      //console.log(isTitular, aseg);
  
      return aseg;
    
    });
  }else{
    asegurados = [{
      codcia: cia,
      numaseg: 0,
      nombres: valores.nombres,
      apellidos: valores.apellidos,
      codrel: valores.sexo === 'H' ? '0001' : '0002',
      sexo: valores.sexo,
      fecnac: valores.fechaDeNacimiento
    }]
  }
  

  // const mergedAsegurados = _(asegurados)
  //   .keyBy('numaseg')
  //   .merge(_.keyBy(primas, 'numaseg'))
  //   .values()
  //   .value();

  const mergedAseguradosPrimas = _.map(primas, (item) => {
    return _.merge(item, _.find(asegurados, {'numaseg': item.numaseg}));
  })

  console.log('ASEGURADOS.:', asegurados);
  console.log('PRIMAS.: ', primas);
  console.log('MERGED.: ', mergedAseguradosPrimas);
  console.log(valorPlazoPagos)

  const codBrokerSplit = codBroker.split(',');
  const firstCodbroker = codBrokerSplit[0];

  console.log(codBrokerSplit, firstCodbroker);

  const cotizacion = {
    numcot: numcot,
    idCompania: cia,
    pais: pais.codpais,
    codBroker: codBroker ? firstCodbroker : '',
    codprod: valores.plan,
    fechaNacimiento: valores.fechaDeNacimiento,
    sexo: valores.sexo,
    author: user.username,
    authorName: authorNameUpper,
    clienteNombres: nombresUpper,
    clienteApellidos: apellidosUpper,
    vigencia: vigencia,
    formaPago: valores.plazoPagos ? valores.plazoPagos : 'A',
    codramo: valores.dependientes ? '0002' : '0001',
    codplan: valores.opcion ? valores.opcion : opcionesDesp[0],
    totalFormadDePago: valorPlazoPagos
  };

  //console.log(cotizacion);

  await sleep(500); // simulate server latency

  //GUARDAR COTIZACION

  return axios.post(endpointURL, cotizacion)
    .then(res => {
      //console.log('COTIZACION.:', res);

      //GUARDAR PRIMAS
      if(res){
        const endpointCotizacion = 'primas-salud';
        const endpointURL = `${currentURl}/${endpointCotizacion}`;

        mergedAseguradosPrimas.map((prima) => {
          const apellidos = prima.apellidos;
          const nombres = prima.nombres;
        
          const apellidosUpper = apellidos.toUpperCase();
          const nombresUpper = nombres.toUpperCase();
          const primaSalud = {
            asegurado: prima.asegurado,
            idCompania: cia,
            pais: pais.codpais,
            codprod: prima.codprod,
            codramo: prima.codramo,
            codplan: prima.codplan,
            cuotaAnual: prima.cuotaAnual,
            cuotaSemestral: prima.cuotaSemestral,
            cuotaTrimestral: prima.cuotaTrimestral,
            cuotaMensual: prima.cuotaMensual,
            deducible: Number(prima.deducible),
            descrel: prima.descrel,
            edad: prima.edad,
            impuesto: Number(prima.impuesto),
            indMenor: prima.indmeno,
            nombres: prima.nombres ? nombresUpper : '',
            apellidos: prima.apellidos ? apellidosUpper : '',
            fecnac: prima.fecnac,
            numaseg: prima.numaseg,
            numcot: prima.numcot,
            plan: prima.plan,
            primaAnual: prima.prima_a,
            primaSemestral: prima.prima_s,
            primaTrimestral: prima.prima_t,
            primaMensual: prima.prima_m,
            impuestoA: prima.impuesto_a,
            impuestoS: prima.impuesto_s,
            impuestoT: prima.impuesto_t,
            impuestoM: prima.impuesto_m
          }

          axios.post(endpointURL, primaSalud).then(res => {
            //console.log('PRIMA.:' ,res);
          });
        })
    } 

      return res.data;
    }).catch( error => {
      console.log(error);
    });
}