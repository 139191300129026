import React from 'react';
import styles from './index.styles';
import LogoSeguros from '../../images/logoSegurosImp.jpg';
import LogoMedical from '../../images/logoMedicalImp.png';
import NumberFormat from 'react-number-format';
import {cia} from '../../env';

const logoModal = cia === '03' ? LogoSeguros : LogoMedical;

const Requisitos = (props) => {
  const { InnerModal, LogoModal, GuiaTitle, CapitalAsegurado, Parametros, RequeridosTitle, LogoContainer, Notas} = styles;
  //console.log(props);
  return ( 
    <InnerModal>
      <LogoContainer>
        <LogoModal><img src={logoModal} alt='Logo Modal'/></LogoModal>
        <GuiaTitle>Guía de requisitos básicos<br /> de asegurabilidad</GuiaTitle>
      </LogoContainer>
      <div>
        <CapitalAsegurado>CAPITAL ASEGURADO EN US$</CapitalAsegurado>
      </div>
      <Parametros>
        <div>Edad: {props.edad}</div>
        <div>
          Suma Asegurada: <NumberFormat value={props.sumaAsegurada} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </div>
      </Parametros>
      <div>
        <RequeridosTitle>Documentos requeridos</RequeridosTitle>
        <ul>
          {props.req.documentos.map((doc, index) => {
            return <li key={index}>{doc.documento}</li>;
          })}
        </ul>
      </div>
      <div>
        {props.req.nota &&
          <Notas><b>Notas:</b> {props.req.nota}</Notas>
        }
      </div>
    </InnerModal>
  );
};
 
export default Requisitos;