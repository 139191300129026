/* eslint-disable no-console */
import axios from 'axios';
import {env, cia} from '../../../env';
import _ from 'lodash';
import moment from 'moment';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const cotizacionURl = `//${env.beURL}/stpr-cotizacion-salud`;
const certURl = `//${env.beURL}/stpr-cert-salud`;
const asegURl = `//${env.beURL}/stpr-aseg-salud`;
const primasURl = `//${env.beURL}/stpr-primas-salud`;

export default (async function cotizar(valores, opcionADesplegar) {

  await sleep(0.001); // simulate server latency

  const hasDependientes = valores.dependientes ? true : false;
  const depLength = hasDependientes && valores.dependientes.length > 0 ? true : false;
  const ramo = depLength ? '0002' : '0001';
  const opcionesDesp = opcionADesplegar.opcionesDesplegar.split(',');

  console.log(depLength);

  const cotizacion = {
    codcia: cia,
    codprod: valores.plan,
    codramo: ramo,
    forpag: valores.plazoPagos,
    codplan: valores.opcion ? valores.opcion : opcionesDesp[0]
  }

  console.log('VALORES.:', valores,'COTIZACION.:', cotizacion, 'OPCION A DESPLEGAR.:', opcionADesplegar, opcionesDesp);

  return axios.post(cotizacionURl, cotizacion)
  .then(cot => {
    console.log('COT.:',cot);

    const cert = {
      numcot: cot.data.outNumcot,
      numcert: 1,
      codcia: cia,
      nombres: valores.nombres,
      apellidos: valores.apellidos,
      fecnac: valores.fechaDeNacimiento,
      sexo: valores.sexo
    }

    return axios.post(certURl, cert);
  }).then(cert => {
    console.log('CERT.: ',cert);
    
    if(hasDependientes){
      //CREATE ASEGURADOS
      //------------------------------------------------
      const asegurados = _.cloneDeep(valores.dependientes);
      const asegPrincipal = {
        nombres: valores.nombres,
        apellidos: valores.apellidos,
        sexo: valores.sexo,
        edad: valores.fechaDeNacimiento,
        parentesco: 'T'
      }

      const isPrincipalShifted = _.find(asegurados, {nombres: valores.nombres, apellidos: valores.apellidos});

      //console.log(asegurados, isPrincipalShifted);

      if(!isPrincipalShifted){
        asegurados.unshift(asegPrincipal);
      }
      //ASEGURADOS
      //console.log(asegurados);
      
      const promises = asegurados.map((dep, i) => {
        const edad = moment().diff(dep.edad, 'years');
        const isConyugue = dep.parentesco === 'C' ? true : false;
        const isHijo = dep.parentesco === 'H' ? true : false;
        const isTitular = dep.parentesco === 'T' ? true : false;

        let codrel = '';
        
        if(isTitular && dep.sexo === 'H'){
          codrel = '0001'
        }else if (isTitular && dep.sexo === 'M'){
          codrel = '0002'
        }else if (isConyugue && dep.sexo === 'M'){
          codrel = '0008'
        }else if (isConyugue && dep.sexo === 'H'){
          codrel = '0007'
        }else if (isHijo && dep.sexo === 'M'){
          codrel = '0006'
        }else{
          codrel = '0005'
        }

        const aseg = {
          numcot: cert.data.outNumcot,
          numcert: 1,
          codcia: cia,
          numaseg: i,
          nombres: dep.nombres,
          apellidos: dep.apellidos,
          codrel: codrel,
          sexo: dep.sexo,
          fecnac: dep.edad,
          indmenor: isHijo && edad <= 25 ? 'S' : 'N'
        }

        //console.log(isTitular, aseg);
  
        return axios.post(asegURl, aseg).then(res => {
          //let p = Object.assign({}, res.data);
          //p.pformapago = cob.pformapago;
          //console.log(res);
          return res;
        }).catch((error) => {
          console.log(error);
        });
      
      });
  
      return Promise.all(promises)
        .then(values => {
          //console.log(values);
          return values;
        }).catch(error => {
          console.log(error);
        });

    }else{
      const edad = moment().diff(valores.fechaDeNacimiento, 'years');
      const aseg = {
        numcot: cert.data.outNumcot,
        codcia: cia,
        numaseg: '0',
        nombres: valores.nombres,
        apellidos: valores.apellidos,
        codrel: valores.sexo === 'H' ? '0001' : '0002',
        sexo: valores.sexo,
        fecnac: valores.fechaDeNacimiento,
        indmenor: edad < 18 ? 'S' : 'N'
      }
      console.log(asegURl, aseg);

      return axios.post(asegURl, aseg);
    }
    
  }).then(aseg => {

    if(aseg){
      console.log('ASEG.: ',aseg);
      if(aseg.length > 0){

        const primas = {
          numcot: aseg[0].data.outNumcot,
          codcia: cia,
          codramo: ramo,
          opciones: opcionADesplegar.opcionesDesplegar
        }
  
        return axios.post(primasURl, primas);
      } else {
        
        const primas = {
          numcot: aseg.data.outNumcot,
          codcia: cia,
          codramo: ramo,
          opciones: opcionADesplegar.opcionesDesplegar
        }
    
        return axios.post(primasURl, primas);
      }
    }

  }).then(primas => {
    console.log('PRIMAS.: ', primas);
    return primas.data;
  }).catch( error => {
    console.log(error);
  });

 

});
