import React from 'react';
import styles from '../../cotizador-salud/index.styles';
import Plan from './plan-salud';
import PlanModal from './plan-modal';
import PlanDetails from '../../../helpers/api/salud/plans-salud';
import moment from 'moment';
import {cia} from '../../../env';
import infinity from '../../../images/infinity.jpeg';

import _ from 'lodash';

const Planes = ({ productos, hasDependientes, dob }) => {
  const { Planes, PlanBreak } = styles;
  //Merge dos arreglos de obejtos by Key/Value
  const plansWithDetails = _.map(productos, function(obj) {
    return _.assign(obj, _.find(PlanDetails, {
      codprod: obj.codprod
    }));
  });
  const plansByRamo = hasDependientes ? _.filter(plansWithDetails, {'codramo': '0002'}) : _.filter(plansWithDetails, {'codramo': '0001'});
  const sortedPlans = _.uniqBy(_.orderBy(plansByRamo, [(o) => { return o.descprod; }], ['desc']), 'codprod');
  const edad = moment().diff(dob, 'years');
  const plans = edad >= 29 && cia === '03' ? _.filter(sortedPlans, (o) => { return o.codprod !== '0128'; }) : sortedPlans;
  const planesAgrupados = _.filter(plans, (o) => { return o.codprodOrg; });

  const infinityPlan = {
      'codcia': '03',
      'codprod': '0145',
      img: infinity
  }

  console.log(productos, plans, dob, edad, sortedPlans, planesAgrupados, planesAgrupados.length);

  let content = [];

  if(planesAgrupados.length > 0){
    content.push(<Plan key={infinity.codprod} producto={infinityPlan} beneficios={planesAgrupados} />);
  }

  plans.forEach((producto, i) =>{
    if((i+1) % 4 === 0){
      console.log((i+1) % 4);
      content.push(<PlanBreak key={i} />);
      if(!producto.codprodOrg){
        content.push(<Plan key={producto.codprod} producto={producto} beneficios={plans} />);
      }
    }else{
      console.log((i+1) % 4);
      if(!producto.codprodOrg){
        content.push(<Plan key={producto.codprod} producto={producto} beneficios={plans} />);
      }
    }
  });

  return (
    <Planes>
      {content}
    </Planes>
  );

  // const sortedPlans = _.orderBy(productos, [(o) => { return o.descprod; }], ['desc']);
  // return ( 
  //   <Planes>
  //     {sortedPlans.map(producto => 
  //       <Plan key={producto.codprod} producto={producto} beneficios={productos} />
  //     )}
  //   </Planes>
  // );

      // if(!producto.codprodOrg){
    //   content.push(<div><p>DIV PARA MODAL</p></div>);
    // }
};
 
export default Planes;