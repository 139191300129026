/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './index.styles';
import { Field, reduxForm, getFormValues } from 'redux-form';
import validate from '../../helpers/validate';
import RenderRadioGroup from '../common/fields/renderRadioGroup';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import CircularProgress from '@material-ui/core/CircularProgress';
import Planes from '../common/cotizador/planes-salud';
//import moment from 'moment';


class CotizadorPasoDos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productos: [],
      planSelected: ''
    };
  }
  componentDidMount(){}
  //const { change, handleSubmit, pristine, previousPage, submitting, rangoCobertura, rangoPagoPrimas = [] } = props;
  
  render(){

    const { handleSubmit, productos, loading, valores } = this.props;
    const { 
      PasoContainer, PasoName, PasoDesc, 
      PasoButtons, SiguienteButton, AnteriorButton, Loading
    } = styles;

    const hasDependientes = valores.dependientes ? true : false;

    return (
      <PasoContainer>
        <PasoName>Paso 2</PasoName>
        <PasoDesc>Elija un Plan</PasoDesc>
        <form onSubmit={handleSubmit}>
          {loading && 
        <Loading>
          <CircularProgress color='primary' />
        </Loading>
          }
          {!loading && 
        <div>
          <Field name="plan" component={RenderRadioGroup}>
            <Planes productos={productos} hasDependientes={hasDependientes} dob={this.props.valores.fechaDeNacimiento}/>
          </Field>
          <PasoButtons>
            <AnteriorButton type="button" className="previous" onClick={this.props.previousPage}>
              <TrendingFLat />
              <div>Anterior</div>
            </AnteriorButton>
            <SiguienteButton type="submit" className="next">
              <div>Siguiente</div>
              <TrendingFLat />
            </SiguienteButton>
          </PasoButtons>
        </div>
          }
        </form>
      </PasoContainer>
    );
  }
}

CotizadorPasoDos.propTypes = {
  handleSubmit: PropTypes.any,
  input: PropTypes.object
};

CotizadorPasoDos = reduxForm({
  form: 'cotizadorSalud', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(CotizadorPasoDos);

//const selector = formValueSelector('cotizadorSalud');
CotizadorPasoDos = connect(
  state => {
    //const { fechaDeNacimiento} = selector(state, 'fechaDeNacimiento');
    return {
      valores: getFormValues('cotizadorSalud')(state)
    };
  }
)(CotizadorPasoDos);

export default CotizadorPasoDos;
