import wwterm from '../../../images/term.jpg';
import termValue from '../../../images/termValue.jpg';
import survivor from '../../../images/survivor.jpg';
import p0206 from '../../../images/productos/vida/0206.jpg';
import p0202 from '../../../images/productos/vida/0202.jpg';
import p0216 from '../../../images/productos/vida/0216.jpg';

export default [
  {
    id: 1,
    codprod: '0186',
    name: 'WW Term',
    description: ['Es un seguro de vida individual diseñado para ofrecerle protección a un precio atractivo y la flexibilidad que busca para usted y su familia. El producto WWTerm, es un seguro de vida a término donde usted elige el período de cobertura y la suma asegurada. WWTerm le da la facilidad de adquirir múltiples opciones de duración. Puede ser elegido por períodos entre: 10, 15, 20, 25 o 30 años. Puede ser elegido por cualquier periodo hasta los 90 años.'],
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM_MAY18.pdf',
    img: wwterm
  },
  {
    id: 2,
    name: 'Term Value',
    codprod: '0187',
    description: 'Es un seguro de vida que garantiza el retorno de la prima pagada al final del período contratado. En el producto WWTerm Value el período de cobertura y el plazo de pago es flexible y se ajusta a sus necesidades.',
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM-VALUE_MAY18.pdf',
    img: termValue
  },
  {
    id: 3,
    name: 'Survivor',
    codprod: '0191',
    description: 'Survivor es un seguro de vida individual diseñado para ofrecerle la seguridad y el respaldo que busca. Nuestro producto le ofrece la protección financiera de faltar a destiempo o mediante sus ahorros, cumplir con sus metas a corto o largo plazo.',
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM-VALUE_MAY18.pdf',
    img: survivor
  },
  {
    id: 4,
    codprod: '0184',
    name: 'WW Term',
    description: 'Es un seguro de vida individual diseñado para ofrecerle protección a un precio atractivo y la flexibilidad que busca para usted y su familia. El producto WWTerm, es un seguro de vida a término donde usted elige el período de cobertura y la suma asegurada. WWTerm le da la facilidad de adquirir múltiples opciones de duración. Puede ser elegido por cualquier periodo hasta los 90 años.',
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM_MAY18.pdf',
    img: wwterm
  },
  {
    id: 5,
    name: 'Term Value',
    codprod: '0183',
    description: 'Es un seguro de vida que garantiza el retorno de la prima pagada al final del período contratado. En el producto WWTerm Value el período de cobertura y el plazo de pago es flexible y se ajusta a sus necesidades.',
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM-VALUE_MAY18.pdf',
    img: termValue
  },
  {
    id: 6,
    name: 'WW TERM INTERNATIONAL',
    codprod: '0204',
    description: 'Es un seguro de vida individual diseñado para ofrecerle protección a un precio atractivo y la flexibilidad que busca para usted y su familia. El producto WWTerm, es un seguro de vida a término donde usted elige el período de cobertura y la suma asegurada. WWTerm le da la facilidad de adquirir múltiples opciones de duración. Puede ser elegido por períodos entre: 10, 15, 20, 25 o 30 años. Puede ser elegido por cualquier periodo hasta los 90 años.',
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM-VALUE_MAY18.pdf',
    img: wwterm
  },
  {
    id: 7,
    name: 'TERM VALUE INTERNATIONAL',
    codprod: '0205',
    description: 'Es un seguro de vida que garantiza el retorno de la prima pagada al final del período contratado. En el producto WWTerm Value el período de cobertura y el plazo de pago es flexible y se ajusta a sus necesidades.',
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM-VALUE_MAY18.pdf',
    img: termValue
  },{
    'codprod': '0206',
    img: p0206
  },
  {
    'codprod': '0202',
    img: p0202
  },
  {
    id: 8,
    name: 'Survivor',
    codprod: '0208',
    description: 'Survivor es un seguro de vida individual diseñado para ofrecerle la seguridad y el respaldo que busca. Nuestro producto le ofrece la protección financiera de faltar a destiempo o mediante sus ahorros, cumplir con sus metas a corto o largo plazo.',
    beneficiosURL: 'https://wwseguros.com.do/wp-content/uploads/2018/10/WWS_SHEET_WWTERM-VALUE_MAY18.pdf',
    img: survivor
  },
  {
    'codprod': '0213',
    description: 'Es un seguro de vida individual diseñado para ofrecerle protección a un precio atractivo y la flexibilidad que busca para usted y su familia. El producto WWTerm, es un seguro de vida a término donde usted elige el período de cobertura y la suma asegurada. WWTerm le da la facilidad de adquirir múltiples opciones de duración. Puede ser elegido por cualquier periodo hasta los 90 años.',
    img: wwterm
  },
  {
    'codprod': '0214',
    description: 'Es un seguro de vida que garantiza el retorno de la prima pagada al final del período contratado. En el producto WWTerm Value el período de cobertura y el plazo de pago es flexible y se ajusta a sus necesidades.',
    img: termValue
  },
  {
    'codprod': '0215',
    description: 'Survivor es un seguro de vida individual diseñado para ofrecerle la seguridad y el respaldo que busca. Nuestro producto le ofrece la protección financiera de faltar a destiempo o mediante sus ahorros, cumplir con sus metas a corto o largo plazo.',
    img: survivor
  },
  {
    'codprod': '0216',
    description: 'Survivor es un seguro de vida individual diseñado para ofrecerle la seguridad y el respaldo que busca. Nuestro producto le ofrece la protección financiera de faltar a destiempo o mediante sus ahorros, cumplir con sus metas a corto o largo plazo.',
    img: p0216
  }
];