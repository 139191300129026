import Swal from 'sweetalert2';
// Abstraction for alerts / prompts

export const alertMessage = (message) => {
  Swal.fire(message);
};

export const successMessage = (title,message) => {
  Swal.fire(
    title,
    message, 
    'success'
  );
};

export const errorMessage = (title,message) => {
  Swal.fire(
    title,
    message, 
    'error'
  );
};

export const displayError = (title, description) => { 
  Swal.fire({ 
    title, 
    text: description, 
    type: 'error',
    timer: 10000,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowEnterKey: false,
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: false,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  }).then(okay => {
    if (okay) {
      window.location.href = 'https://wwseguros.com.do/';
    }
  });
};

export const displayErrorPolizas = (title, description) => {
  Swal.fire({ 
    title, 
    text: description, 
    type: 'error',
    timer: 10000,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowEnterKey: false,
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: false,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  }).then(okay => {
    if (okay) {
      window.location.href = '/';
    }
  });
};

export const displayDisclaimer = (url) => {
  Swal.fire({ 
    //title: '<h3>Usted está abandonando el portal de cotizaciones de WorldWide Seguros</h3>', 
    html: '<p style="text-align:justify;font-size: 12px;width:90%">Usted está abandonando el portal de cotizaciones de WorldWide Seguros y siendo redirigido a otro portal. Para continuar seleccione OK. Para permanecer en la página web de WorldWide Seguros, cierre esta ventana.</p>', 
    //type: 'warning',
    timer: 20000,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowEnterKey: false,
    allowOutsideClick: false,
    showCloseButton: true,
    showCancelButton: false,
    position: 'bottom',
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  }).then(result => {
    console.log(result);
    // if (okay.value) {
    //   window.location.href = url;
    // }else{

    // }

    if (result.value) {
      window.location.href = url;
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      Swal.close();
    }
  });
};