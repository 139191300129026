/* eslint-disable no-class-assign */
import React, { Component }  from 'react';
import styles from './index.styles';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormValues } from 'redux-form';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TrendingFLat from '@material-ui/icons/TrendingFlat';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import Print from '@material-ui/icons/Print';
import Email from '@material-ui/icons/Email';
import NumberFormat from 'react-number-format';
import Requisitos from './requisitos';
import CircularProgress from '@material-ui/core/CircularProgress';
//REQUESTS
import cotizar from '../../helpers/api/salud/cotizar';
import {saveCotizacion} from '../../helpers/api/salud/saveCotizacion';
//import {saveCoberturas} from '../../helpers/api/saveCoberturas';
//import getRequisitos from '../../helpers/api/vida/requisitos';
import enviarCorreo from '../../helpers/api/salud/enviarCorreo';
import createPDF from '../../helpers/api/salud/createPDF';
//ALERTS
import {alertMessage, successMessage, errorMessage} from '../../helpers/alerts';
//REQUISITOS MODAL
//import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
//HELPERS
//import ReactToPrint from 'react-to-print';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
//import axios from 'axios';
import {cia, env, source, pais} from '../../env';
//${env.envURL}/productos${env.beMethod}
//const currentURl = `//${env.envURL}/coberturas${env.beMethod}`;

class CotizadorCotizacion extends Component {
  constructor(props) {
    super(props);
    this.enviarPorCorreo = this.enviarPorCorreo.bind(this);
    this.verCotizacion = this.verCotizacion.bind(this);
    this.nuevaCotizacion = this.nuevaCotizacion.bind(this);
    this.sumaPrimas = this.sumaPrimas.bind(this);
    this.state = {
      cotizacionId: '',
      cotizacion: {},
      primaAnual: {},
      primaSemestral: {},
      primaTrimestral: {},
      primaMensual: {},
      primas: [],
      sumaPrimas: null,
      sumaImpuestos: null,
      sumaCargosAdm: null,
      loading: true,
      error: null,
      hideForPrint: false,
      isTermValue: false,
      isSurvivor: false,
      selectedPlan: {},
      open: false,
      requisitos: {}
    };
  }

  componentDidMount() {

    const {valores, productos, userInfo} = this.props;

    //console.log(userInfo.CodBroker);

    const hasDependientes = valores.dependientes ? true : false;
    //console.log(valores);

    //GET OPCIONES
    axios.post(`//${env.beURL}/stpr-planes-salud`, {
      cia: cia,
      codprod: valores.plan,
      codramo: hasDependientes ? '0002' : '0001'
    }).then(
      res => {
        //console.log(res);
        let planes;
        if(res.data.data){
          planes = res.data.data;
        } else {
          planes = res.data;
        }
        this.setState({opciones: planes});
        console.log('planes: ', planes);
      }
    ).catch(
      () => {
        errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
      }
    );

    //GET OPCIONES POSIBLES AND COTIZAR
    axios.post(`//${env.beURL}/opciones-salud`, {
      cia: cia,
      codprod: valores.plan
    }).then(
      res => {
        console.log(res);
        let opcionesDesplegar;
        if(res.data.data){
          opcionesDesplegar = res.data.data;
        } else {
          opcionesDesplegar = res.data;
        }
        this.setState({opcionesDesplegar: opcionesDesplegar});
        console.log('Opciones a Desplegar: ', opcionesDesplegar);

        //OPCION A DESPLEGAR
        const opcionADesplegar = _.find(opcionesDesplegar, {
          codcia: cia, 
          opcion: valores.opcion ? valores.opcion : null,
          todosDeduc: valores.todosDeducibles ? 'S' : 'N'
        });

        const opcionesDesp = opcionADesplegar.opcionesDesplegar.split(',');
        const opcionesDespforFilter = opcionesDesp.reduce((s, a) => {
          s.push({
            name: a,
            codplan: a
          });
          return s;
        }, []);

        this.setState({opcionADesplegar: opcionADesplegar, opcionesDespforFilter:opcionesDespforFilter});
        //console.log('Opcion a Desplegar: ', opcionADesplegar);

        console.log(valores, opcionADesplegar);
        //COTIZAR
        cotizar(valores, opcionADesplegar).then((res) => {
          if(res){
        
            console.log(res);
            const opcionesDesp = opcionADesplegar.opcionesDesplegar.split(',');
            const opcionesDespforFilter = opcionesDesp.reduce((s, a) => {
              s.push({
                name: a,
                codplan: a
              });
              return s;
            }, []);
            //const opcionesDesp = opcionADesplegar.opcionesDesplegar;

            const todos = valores.todosDeducibles ? true : false;

            let grpSumasOpcion;

            if(todos){
              const preGrpSumasOpcion = _.chain(res)
                .groupBy('codplan')
                .map((value, key) => ({ name: key, primas: value }))
                .map((opcion) => ({ 
                  name: opcion.name,  
                  sumaPrimaAnual: this.sumaPrimas('prima_anual', opcion.primas), 
                  sumaPrima: this.sumaPrimas('prima', opcion.primas), 
                  sumaCuota: this.sumaPrimas('cuotaAnual', opcion.primas), 
                  sumaPrimaS: this.sumaPrimas('prima_s', opcion.primas), 
                  sumaCuotaS: this.sumaPrimas('cuotaSemestral', opcion.primas), 
                  sumaPrimaT: this.sumaPrimas('prima_t', opcion.primas), 
                  sumaCuotaT: this.sumaPrimas('cuotaTrimestral', opcion.primas), 
                  sumaPrimaM: this.sumaPrimas('prima_m', opcion.primas), 
                  sumaCuotaM: this.sumaPrimas('cuotaMensual', opcion.primas), 
                  sumaImpuestoA: this.sumaPrimas('impuesto_a', opcion.primas), 
                  sumaImpuestoS: this.sumaPrimas('impuesto_s', opcion.primas), 
                  sumaImpuestoT: this.sumaPrimas('impuesto_t', opcion.primas), 
                  sumaImpuestoM: this.sumaPrimas('impuesto_m', opcion.primas), 
                  sumaCuotaAnual: this.sumaPrimas('cuotaAnual', opcion.primas) }))
                .value();
          
              //grpSumasOpcion = _.filter(preGrpSumasOpcion, predicateSumas(opcionesDesp.map(x => x)));
              grpSumasOpcion = _.intersectionBy(preGrpSumasOpcion, opcionesDespforFilter, 'name');

            }else{
          
              grpSumasOpcion = _.chain(res)
                .groupBy('codplan')
                .map((value, key) => ({ name: key, primas: value }))
                .map((opcion) => ({ 
                  name: opcion.name,  
                  sumaPrimaAnual: this.sumaPrimas('prima_anual', opcion.primas), 
                  sumaPrima: this.sumaPrimas('prima', opcion.primas), 
                  sumaCuota: this.sumaPrimas('cuotaAnual', opcion.primas), 
                  sumaPrimaS: this.sumaPrimas('prima_s', opcion.primas), 
                  sumaCuotaS: this.sumaPrimas('cuotaSemestral', opcion.primas), 
                  sumaPrimaT: this.sumaPrimas('prima_t', opcion.primas), 
                  sumaCuotaT: this.sumaPrimas('cuotaTrimestral', opcion.primas), 
                  sumaPrimaM: this.sumaPrimas('prima_m', opcion.primas), 
                  sumaCuotaM: this.sumaPrimas('cuotaMensual', opcion.primas), 
                  sumaImpuestoA: this.sumaPrimas('impuesto_a', opcion.primas), 
                  sumaImpuestoS: this.sumaPrimas('impuesto_s', opcion.primas), 
                  sumaImpuestoT: this.sumaPrimas('impuesto_t', opcion.primas), 
                  sumaImpuestoM: this.sumaPrimas('impuesto_m', opcion.primas), 
                  sumaCuotaAnual: this.sumaPrimas('cuotaAnual', opcion.primas) }))
                .value();
            }

            //console.log('GrpSumasOpcion.: ', grpSumasOpcion);

            const opcionSeleccionada = valores.opcion ? _.find(grpSumasOpcion, {name: valores.opcion}) : grpSumasOpcion[0];

            const numcot = res[0].numcot;
        
            //FILTRO DE OPCIONES
            //AGRUPAR ASEGURADOS Y FILTRAR OPCIONES

            let grpAsegurados;

            if(todos){
              grpAsegurados = cia === '03' && valores.plan === '0140' ? _.chain(res)
              // Group the elements of Array based on `color` property
                .groupBy('asegurado')
              // `key` is group's name (color), `value` is the array of objects
              //prima, prima_anual, plan, deducible
                .map((value, key) => ({ asegurado: key, opciones: value }))
                .map((value) => ({ 
                  asegurado: value.asegurado, 
                  opciones: _.intersectionBy(value.opciones, opcionesDespforFilter, 'codplan')
                }))
                .value() : _.chain(res)
              // Group the elements of Array based on `color` property
                .groupBy('asegurado')
              // `key` is group's name (color), `value` is the array of objects
              //prima, prima_anual, plan, deducible
                .map((value, key) => ({ asegurado: key, opciones: value }))
                .map((value) => ({ 
                  asegurado: value.asegurado, 
                  opciones: _.intersectionBy(value.opciones, opcionesDespforFilter, 'codplan')
                }))
                .value();
            }else{
              grpAsegurados = _.chain(res)
              // Group the elements of Array based on `color` property
                .groupBy('asegurado')
              // `key` is group's name (color), `value` is the array of objects
              //prima, prima_anual, plan, deducible
                .map((value, key) => ({ asegurado: key, opciones: value }))
                .value();
            }

            //console.log('GrpAsegurados.: ', grpAsegurados);

            this.setState({
              numcot,
              primas: res,
              grpSumasOpcion,
              grpAsegurados,
              loading: false
            });

            //PDF STUFF
            //SAVE PDF
            createPDF(numcot, this.props.valores, planSelected, this.props.vigencia, this.props.plazoPagosDesc ,this.props.user, 
              res, cia, opcionSeleccionada, grpSumasOpcion, todos, this.state.opciones, opcionesDespforFilter, pais).then((res) => {
              //console.log(res);
              if(res === 'error'){
                errorMessage(
                  'Lo Sentimos', 'Ha ocurrido un error, por favor inténtelo mas tarde.'
                );
              }
              //console.log(res);
            }).catch(error => {alertMessage(error);});

            //VALOR SUMA PLAZO PAGOS
            let valorPlazoPagos;
            
            if(valores.plazoPagos === 'A'){
              valorPlazoPagos = Number(opcionSeleccionada.sumaPrimaAnual + opcionSeleccionada.sumaCuotaAnual + opcionSeleccionada.sumaImpuestoA);
            }
            if(valores.plazoPagos === 'S'){
              valorPlazoPagos = Number(opcionSeleccionada.sumaPrimaS + opcionSeleccionada.sumaCuotaS + opcionSeleccionada.sumaImpuestoS);
            }
            if(valores.plazoPagos === 'T'){
              valorPlazoPagos = Number(opcionSeleccionada.sumaPrimaT + opcionSeleccionada.sumaCuotaT + opcionSeleccionada.sumaImpuestoT);
            }
            if(valores.plazoPagos === 'M'){
              valorPlazoPagos = Number(opcionSeleccionada.sumaPrimaM + opcionSeleccionada.sumaCuotaM + opcionSeleccionada.sumaImpuestoM);
            }

            //console.log(opcionSeleccionada, valorPlazoPagos);

            //SAVE COTIZACION
            saveCotizacion(this.props.valores, this.props.user, numcot, this.props.vigenciaDate, res, userInfo.CodBroker, opcionADesplegar, valorPlazoPagos).then((res) => {
              //console.log(res);
              if(res === 'error'){
                errorMessage(
                  'Lo Sentimos', 'Ha ocurrido un error, por favor inténtelo mas tarde.'
                );
              }
              //console.log(res);
            }).catch(error => {alertMessage(error);});

        

          }
        }).catch(error => { errorMessage('Lo sentimos', error);});
      }
    ).catch(
      () => {
        errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
      }
    );

    //PLAN SELECCIONADO
    const planSelected = _.find(productos, { 'codprod': valores.plan});
    this.setState({selectedPlan: planSelected});

    //console.log('Plan Seleccionado:', planSelected);

   
  }

  renderAsegurados() {
    const { primas, grpAsegurados } = this.state;
    const { valores } = this.props;
    const { DecucibleRow, DecucibleColumn, DecucibleMainColumn } = styles;

    const todos = valores.todosDeducibles ? true : false;
    

    if(primas) {

      return grpAsegurados.map((asegurado) => {
        const opcionSeleccionada = _.find(asegurado.opciones, {codplan: valores.opcion});
        //console.log(asegurado, opcion1, opcion2, opcion3);

        return(
          <DecucibleRow key={asegurado.asegurado}>
            {todos &&
              <DecucibleMainColumn todos={todos}>{asegurado.opciones[0].nombres}</DecucibleMainColumn>
            }
            {!todos &&
              <DecucibleMainColumn>{asegurado.opciones[0].nombres}</DecucibleMainColumn>
            }
            <DecucibleColumn>{asegurado.opciones[0].edad}</DecucibleColumn>
            {todos && asegurado.opciones.map((opcion, i) => {
              if(i <= 4){
                return (<DecucibleColumn key={opcion.codplan}>
                  <NumberFormat fixedDecimalScale value={Number(Number(opcion.prima_anual) + Number(opcion.cuotaAnual) + Number(opcion.impuesto_a))} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                </DecucibleColumn>);
              }
            })}
            {!todos && 
              <DecucibleColumn last key={opcionSeleccionada.codplan}>
                <NumberFormat fixedDecimalScale value={Number(Number(opcionSeleccionada.prima_anual) + Number(opcionSeleccionada.cuotaAnual) + Number(opcionSeleccionada.impuesto_a))} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
              </DecucibleColumn>
            }
          </DecucibleRow>);
        
      });
    }

  }

  //Render Asegurados Header
  renderAseguradosHeader() {
    const { primas, opciones, opcionesDespforFilter } = this.state;
    const { valores } = this.props;
    const { DecucibleRow, DecucibleColumn, DecucibleMainColumn, ContainerTable } = styles;
    
    const opcionesFiltradas = _.intersectionBy(opciones, opcionesDespforFilter, 'codplan');
    //console.log(opciones, opcionesFiltradas);

    const todos = valores.todosDeducibles ? true : false;

    if(primas) {

      //console.log(grpAsegurados);
      const opcionSeleccionada = _.find(opciones, {codplan: valores.opcion});
      const deducibleSeleccionado = valores.opcion ? _.find(opciones, {codplan: valores.opcion}) : opciones[0];
      const deduciblePare = deducibleSeleccionado.deducible_pare ? true : false;

      return(<td colSpan={6}><ContainerTable><tbody>
        <DecucibleRow>
          {todos &&
            <DecucibleMainColumn bold todos={todos}>DEDUCIBLES</DecucibleMainColumn>
          }
          {!todos &&
            <DecucibleMainColumn bold>DEDUCIBLES</DecucibleMainColumn>
          }
          <DecucibleColumn></DecucibleColumn>
          {todos && opcionesFiltradas.map((opcion, i) => {
            if(i <= 4){
              return (<DecucibleColumn key={opcion.codplan}>{opcion.descplan}</DecucibleColumn>);
            }
          })}
          {!todos && 
            <DecucibleColumn key={opcionSeleccionada.codplan} last>{opcionSeleccionada.descplan}</DecucibleColumn>
          }
        </DecucibleRow>
        {deduciblePare &&
          <DecucibleRow>
            {todos &&
              <DecucibleMainColumn todos={todos}>País de Residencia</DecucibleMainColumn>
            }
            {!todos &&
              <DecucibleMainColumn >País de Residencia</DecucibleMainColumn>
            }
            <DecucibleColumn></DecucibleColumn>
            {todos && opcionesFiltradas.map((opcion, i) => {
              if(i <= 4){
                return (
                  <DecucibleColumn key={opcion.codplan}>
                    <NumberFormat fixedDecimalScale value={Number(opcion.deducible_pare)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                  </DecucibleColumn>);
              }
            })}
            {!todos && 
              <DecucibleColumn last>
                <NumberFormat fixedDecimalScale value={Number(deducibleSeleccionado.deducible_pare)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
              </DecucibleColumn>
            }
          </DecucibleRow>
        }
        <DecucibleRow>
          {todos &&
            <DecucibleMainColumn todos={todos}>
              {deduciblePare && <span>En el exterior</span>}
              {!deduciblePare && <span>Aplica solo en el exterior</span>}
            </DecucibleMainColumn>
          }
          {!todos &&
            <DecucibleMainColumn >
              {deduciblePare && <span>En el exterior</span>}
              {!deduciblePare && <span>Aplica solo en el exterior</span>}
            </DecucibleMainColumn>
          }
          <DecucibleColumn></DecucibleColumn>
          {todos && opcionesFiltradas.map((opcion, i) => {
            if(i <= 4){
              return (
                <DecucibleColumn key={opcion.codplan}>
                  <NumberFormat fixedDecimalScale value={Number(opcion.deducible)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                </DecucibleColumn>);
            }
          })}
          {!todos && 
            <DecucibleColumn last>
              <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.deducible)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
            </DecucibleColumn>
          }
        </DecucibleRow>
        <DecucibleRow>
          <DecucibleMainColumn th>Asegurado</DecucibleMainColumn>
          <DecucibleColumn th>Edad</DecucibleColumn>
          {todos && opcionesFiltradas.map((opcion, i) => {
            if(i <= 4){
              return (<DecucibleColumn th key={opcion.codplan}>PRIMA ANUAL</DecucibleColumn>);
            }
          })}
          {!todos && 
            <DecucibleColumn th last>PRIMA ANUAL</DecucibleColumn>
          }
        </DecucibleRow>
      </tbody></ContainerTable></td>
      );
    }

  }

  //Render Asegurados
  renderDeducibles() {
    const { grpSumasOpcion } = this.state;
    const { valores } = this.props;
    const { DecucibleRow, DecucibleColumn, ContainerTable, PrimaColumn } = styles;

    const todos = valores.todosDeducibles ? true : false;

    if(grpSumasOpcion) {

      const opcionSeleccionada = _.find(grpSumasOpcion, {name: valores.opcion});

      //console.log(grpSumasOpcion, opcionSeleccionada);

      return (<td colSpan={6}>
        <ContainerTable>
          <tbody>
            {valores.plazoPagos === 'A' &&
            <td colSpan={6}>
              <ContainerTable>
                <tbody>
                  {valores.dependientes &&
                    <DecucibleRow primaRow>
                      <PrimaColumn bgwhite>Prima Anual</PrimaColumn>
                      <DecucibleColumn></DecucibleColumn>
                      {todos && grpSumasOpcion.map((opcion, i) => {
                        if(i <= 4){
                          return (<DecucibleColumn key={opcion.name}>
                            <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaAnual + opcion.sumaCuotaAnual + opcion.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                          </DecucibleColumn>);
                        }
                      })}
                      {!todos && 
                        <DecucibleColumn key={opcionSeleccionada.codplan} last>
                          <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaAnual + opcionSeleccionada.sumaCuotaAnual + opcionSeleccionada.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                        </DecucibleColumn>
                      }
                    </DecucibleRow>
                  }
                </tbody>
              </ContainerTable>
            </td>
            }
            {valores.plazoPagos === 'S' &&
            <td colSpan={6}>
              <ContainerTable>
                <tbody>
                  {valores.dependientes &&
                    <DecucibleRow primaRow>
                      <PrimaColumn bgwhite>Prima Anual</PrimaColumn>
                      <DecucibleColumn></DecucibleColumn>
                      {todos && grpSumasOpcion.map((opcion, i) => {
                        if(i <= 4){
                          return (<DecucibleColumn key={opcion.name}>
                            <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaAnual + opcion.sumaCuotaAnual + opcion.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                          </DecucibleColumn>);
                        }
                      })}
                      {!todos && 
                        <DecucibleColumn key={opcionSeleccionada.codplan} last>
                          <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaAnual + opcionSeleccionada.sumaCuotaAnual + opcionSeleccionada.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                        </DecucibleColumn>
                      }
                    </DecucibleRow>
                  }
                  <DecucibleRow primaRow>
                    <PrimaColumn bgwhite>Prima Semestral</PrimaColumn>
                    <DecucibleColumn></DecucibleColumn>
                    {todos && grpSumasOpcion.map((opcion, i) => {
                      if(i <= 4){
                        return (<DecucibleColumn key={opcion.name}>
                          <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaS + opcion.sumaCuotaS + opcion.sumaImpuestoS)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                        </DecucibleColumn>);
                      }
                    })}
                    {!todos && 
                      <DecucibleColumn key={opcionSeleccionada.codplan} last>
                        <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaS + opcionSeleccionada.sumaCuotaS + opcionSeleccionada.sumaImpuestoS)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                      </DecucibleColumn>
                    }
                  </DecucibleRow>
                </tbody>
              </ContainerTable>
            </td>
            }
            {valores.plazoPagos === 'T' &&
            <td colSpan={6}>
              <ContainerTable>
                <tbody>
                  {valores.dependientes &&
                    <DecucibleRow primaRow>
                      <PrimaColumn bgwhite>Prima Anual</PrimaColumn>
                      <DecucibleColumn></DecucibleColumn>
                      {todos && grpSumasOpcion.map((opcion, i) => {
                        if(i <= 4){
                          return (<DecucibleColumn key={opcion.name}>
                            <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaAnual + opcion.sumaCuotaAnual + opcion.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                          </DecucibleColumn>);
                        }
                      })}
                      {!todos && 
                        <DecucibleColumn key={opcionSeleccionada.codplan} last>
                          <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaAnual + opcionSeleccionada.sumaCuotaAnual + opcionSeleccionada.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                        </DecucibleColumn>
                      }
                    </DecucibleRow>
                  }
                  <DecucibleRow primaRow>
                    <PrimaColumn bgwhite>Prima Semestral</PrimaColumn>
                    <DecucibleColumn></DecucibleColumn>
                    {todos && grpSumasOpcion.map((opcion, i) => {
                      if(i <= 4){
                        return (<DecucibleColumn key={opcion.name}>
                          <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaS + opcion.sumaCuotaS + opcion.sumaImpuestoS)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                        </DecucibleColumn>);
                      }
                    })}
                    {!todos && 
                      <DecucibleColumn key={opcionSeleccionada.codplan} last>
                        <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaS + opcionSeleccionada.sumaCuotaS + opcionSeleccionada.sumaImpuestoS)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                      </DecucibleColumn>
                    }
                  </DecucibleRow> 
                  <DecucibleRow primaRow>
                    <PrimaColumn bgwhite>Prima Trimestral</PrimaColumn>
                    <DecucibleColumn></DecucibleColumn>
                    {todos && grpSumasOpcion.map((opcion, i) => {
                      if(i <= 4){
                        return (<DecucibleColumn key={opcion.name}>
                          <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaT + opcion.sumaCuotaT + opcion.sumaImpuestoT)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                        </DecucibleColumn>);
                      }
                    })}
                    {!todos && 
                      <DecucibleColumn key={opcionSeleccionada.codplan} last>
                        <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaT + opcionSeleccionada.sumaCuotaT + opcionSeleccionada.sumaImpuestoT)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                      </DecucibleColumn>
                    }
                  </DecucibleRow>  
                </tbody>
              </ContainerTable>
            </td>
            }
            {valores.plazoPagos === 'M' &&
            <td colSpan={6}>
              <ContainerTable>
                <tbody>
                  {valores.dependientes &&
                    <DecucibleRow primaRow>
                      <PrimaColumn bgwhite>Prima Anual</PrimaColumn>
                      <DecucibleColumn></DecucibleColumn>
                      {todos && grpSumasOpcion.map((opcion, i) => {
                        if(i <= 4){
                          return (<DecucibleColumn key={opcion.name}>
                            <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaAnual + opcion.sumaCuotaAnual + opcion.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                          </DecucibleColumn>);
                        }
                      })}
                      {!todos && 
                        <DecucibleColumn key={opcionSeleccionada.codplan} last>
                          <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaAnual + opcionSeleccionada.sumaCuotaAnual + opcionSeleccionada.sumaImpuestoA)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                        </DecucibleColumn>
                      }
                    </DecucibleRow>
                  }
                  <DecucibleRow primaRow>
                    <PrimaColumn bgwhite>Prima Semestral</PrimaColumn>
                    <DecucibleColumn></DecucibleColumn>
                    {todos && grpSumasOpcion.map((opcion,i) => {
                      if(i <= 4){
                        return (<DecucibleColumn key={opcion.name}>
                          <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaS + opcion.sumaCuotaS + opcion.sumaImpuestoS)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                        </DecucibleColumn>);
                      }
                    })}
                    {!todos && 
                      <DecucibleColumn key={opcionSeleccionada.codplan} last>
                        <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaS + opcionSeleccionada.sumaCuotaS + opcionSeleccionada.sumaImpuestoS)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                      </DecucibleColumn>
                    }
                  </DecucibleRow> 
                  <DecucibleRow primaRow>
                    <PrimaColumn bgwhite>Prima Trimestral</PrimaColumn>
                    <DecucibleColumn></DecucibleColumn>
                    {todos && grpSumasOpcion.map((opcion, i) => {
                      if(i <= 4){
                        return (<DecucibleColumn key={opcion.name}>
                          <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaT + opcion.sumaCuotaT + opcion.sumaImpuestoT)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                        </DecucibleColumn>);
                      }
                    })}
                    {!todos && 
                      <DecucibleColumn key={opcionSeleccionada.codplan} last>
                        <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaT + opcionSeleccionada.sumaCuotaT + opcionSeleccionada.sumaImpuestoT)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                      </DecucibleColumn>
                    }
                  </DecucibleRow> 
                  <DecucibleRow primaRow>
                    <PrimaColumn bgwhite>Prima Mensual</PrimaColumn>
                    <DecucibleColumn></DecucibleColumn>
                    {todos && grpSumasOpcion.map((opcion, i) => {
                      if(i <= 4){
                        return (<DecucibleColumn key={opcion.name}>
                          <NumberFormat fixedDecimalScale value={Number(opcion.sumaPrimaM+ opcion.sumaCuotaM+ opcion.sumaImpuestoM)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />  
                        </DecucibleColumn>);
                      }
                    })}
                    {!todos && 
                      <DecucibleColumn key={opcionSeleccionada.codplan} last>
                        <NumberFormat fixedDecimalScale value={Number(opcionSeleccionada.sumaPrimaM + opcionSeleccionada.sumaCuotaM + opcionSeleccionada.sumaImpuestoM)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                      </DecucibleColumn>
                    }
                  </DecucibleRow>   
                </tbody>
              </ContainerTable>
            </td>
            }
          </tbody>
        </ContainerTable>
      </td>

      );
    }

  }

  
  enviarPorCorreo(){
    //const mensaje = this.renderReactToString();
    //const impresion = this.componentRef.current;

    enviarCorreo(this.props.user, this.state.numcot, this.state.selectedPlan).then((res) => {
      //console.log(res);
      if(res === 'error'){
        errorMessage(
          'Lo Sentimos', 'Ha ocurrido un error, por favor inténtelo mas tarde.'
        );
      }
      successMessage('Listo!','La cotizacion fue enviada a su correo electrónico');
      //console.log(res);
    }).catch(error => {alertMessage(error);});
  }

  verCotizacion(){
    const currentURl = `//${env.beURL}`;
    window.open(`${currentURl}/salud/cotizacion-${this.state.numcot}.pdf`, '_blank');
    //window.open(`https://cotizadores.wwseguros.com.do:30443/salud/cotizacion-${this.state.numcot}.pdf`, '_blank');
  }

  nuevaCotizacion(){
    const currentURl = `//${env.envURL}`;
    window.open(`${currentURl}?cia=${cia}`, '_self');
  }


  solicitar(){
    //const currentURl = `//${env.beURL}`;
    window.open(`https://portal.wwseguros.com.do/dashboard/requests/new-requests/salud/cotizacion/${this.state.numcot}`, '_blank');
    //window.open(`https://wwsdevfrontend-desadevfrontend.azurewebsites.net/dashboard/requests/new-requests/salud/cotizacion/${this.state.numcot}`, '_blank');
  }

  //SUMA PRIMAS / IMPUESTOS / COUTA
  sumaPrimas(key, array){
    return array.reduce((a, b) => a + (b[key] || 0), 0);
  }

  render(){

    //MODAL STYLES AN HANDLERS
    // const handleOpenM = () => {
    //   this.setState({open: true});
    // };
  
    const handleCloseM = () => {
      this.setState({open: false});
    };

    const { loading } = this.state;

    const { MyModal, PasoContainer, PasoDesc, PasoForm, PasoButtons, Loading, ContainerTable,
      SiguienteButton, AnteriorButton, PasoName, FieldRow, Disclaimer, ActionsButtons, 
      DeducibleTable, DeducibleContainer
    } = styles;

    return (
      <PasoContainer>
        <PasoName></PasoName>
        <PasoDesc>Cotización</PasoDesc>
        <PasoForm>
          <form onSubmit={this.props.handleSubmit}>
            <FieldRow>
              <TextField
                id="fullName"
                label="Cliente"
                defaultValue={this.props.fullName}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="numcot"
                label="No. Cotización"
                value={this.state.numcot}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </FieldRow>
            <FieldRow>
              <TextField
                id="plan"
                label="Plan"
                value={this.state.selectedPlan.descprod}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                id="plazoPagos"
                label="Frecuencia de Pagos"
                defaultValue={this.props.plazoPagosDesc}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            </FieldRow>
            <FieldRow>
              <TextField
                id="intermediario"
                label="Intermediario"
                defaultValue={this.props.user.firstName +' '+ this.props.user.lastName}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="vigencia"
                label="Vigencia"
                defaultValue={this.props.vigencia}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            </FieldRow>
            {loading && 
              <Loading>
                <CircularProgress color='primary' />
              </Loading>
            }
            {!loading && 
              <DeducibleContainer>
                <DeducibleTable>
                  <tbody>
                    <tr>{this.renderAseguradosHeader()}</tr>
                    <tr>
                      <td colSpan={6} >
                        <ContainerTable>
                          <tbody>
                            {this.renderAsegurados()}
                          </tbody>
                        </ContainerTable>
                      </td>
                    </tr>
                    <tr>{this.renderDeducibles()}</tr>
                  </tbody>
                </DeducibleTable>
              </DeducibleContainer>
            }
            {!loading &&
              <Disclaimer>
                {/* <a href={reqAsegurabilidad} target='_blank' rel='noopener noreferrer'>Requisitos de Asegurabilidad</a> */}
                {/* <a href="#" onClick={handleOpenM}>Requisitos de Asegurabilidad</a> */}
                
                <p>* Prima incluye gastos administrativos
                  <br/>* Esta cotización tiene una vigencia de treinta (30) días a partir de la fecha de emisión de la propuesta.
                  <br/>* La emisión de esta cotización no garantiza la emisión o contratación de la póliza.
                  <br/>* Los pagos pueden ser realizados en efectivo, cheque, transferencia o tarjeta de crédito.
                  <br/>* La presente cotización ha sido elaborada en base a las informaciones suministradas.
                  <br/>En caso de variación en el contenido en dichas informaciones, la cotización reflejará los ajustes que fueren necesarios.
                  <br/>Esta cotización corresponde al pais {pais.descpais}.
                  </p>
                  
                {cia === '02' &&
                  <p>“Regulado y Supervisado por la Superintendencia de Seguros y Reaseguros de Panama”</p>
                }
              </Disclaimer>
            }
            {!loading &&
            <div>
              <PasoButtons className='noPrint'>
                <ButtonGroup>
                  <AnteriorButton type="button" className="previous" onClick={this.props.previousPage}>
                    <TrendingFLat />
                    <div>Volver</div>
                  </AnteriorButton>
                  <ActionsButtons type="button" onClick={this.enviarPorCorreo.bind(this)}>
                    <div>Enviar por Correo</div>
                    <Email />
                  </ActionsButtons>
                  <ActionsButtons type="button" onClick={this.verCotizacion.bind(this)}>
                    <div>Imprimir</div>
                    <Print />
                  </ActionsButtons>
                </ButtonGroup>
              </PasoButtons>
              <PasoButtons className='noPrint'>
                <SiguienteButton type="button" onClick={this.nuevaCotizacion.bind(this)} disabled={this.props.pristine || this.props.submitting}>
                  <div>Nueva Cotización</div>
                  <Add />
                </SiguienteButton>
                <SiguienteButton type="button" onClick={this.solicitar.bind(this)}>
                  <div>Solicitar</div>
                  <AddToPhotosOutlinedIcon />
                </SiguienteButton>
              </PasoButtons>
            </div>
            }
            
          </form>
        </PasoForm>
        <MyModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          //className={classes.modal}
          open={this.state.open}
          onClose={handleCloseM}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Requisitos req={this.state.requisitos} edad={this.props.edad} sumaAsegurada={this.props.sumaAsegurada}/>
          </Fade>
        </MyModal>
      </PasoContainer>
    );
  }
  
}

CotizadorCotizacion = reduxForm({
  form: 'cotizadorSalud', //              <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CotizadorCotizacion);

const selector = formValueSelector('cotizadorSalud');
CotizadorCotizacion = connect(
  state => {
    const { plan, nombres, apellidos, 
      fechaDeNacimiento, sexo, plazoPagos, sumaAsegurada, duracionPrimas, dependientes
    } = selector(state, 'plan', 'nombres', 'apellidos', 'fechaDeNacimiento', 'sexo', 'plazoPagos', 'dependientes');
    const edad = moment().diff(fechaDeNacimiento, 'years');
    const sexoName = (sexo !== 'M') ? 'Femenino' : 'Masculino';
    const getPlazoPagosDesc = (plazoPagos) => {
      if(plazoPagos === 'A'){return 'Anual';}
      if(plazoPagos === 'S'){return 'Semestral';}
      if(plazoPagos === 'M'){return 'Mensual';}
      return 'Trimestral';
    };
    const newVigencia = moment().date() <= 15 ? moment().clone().startOf('month') : moment().add(1, 'month').startOf('month');
    //console.log(newVigencia);
    const plazoPagosDesc = getPlazoPagosDesc(plazoPagos);
    const vigencia = newVigencia.format('MMM/YYYY');
    const vigenciaDate = newVigencia;

    return {
      plan,
      nombres,
      apellidos,
      fullName: `${nombres || ''} ${apellidos || ''}`,
      fechaDeNacimiento,
      dependientes,
      edad,
      sexo,
      sexoName,
      plazoPagos,
      plazoPagosDesc,
      sumaAsegurada, 
      duracionPrimas,
      vigencia,
      vigenciaDate,
      valores: getFormValues('cotizadorSalud')(state)
    };
  }
)(CotizadorCotizacion);

export default CotizadorCotizacion;
