import React from 'react';
import styles from './index.styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Cookies from 'universal-cookie';
import {source} from '../../../env';
import _ from 'lodash';


const MenuPais = (props) => {
  const {paises, paisSelected} = props;
  const { MenuPais, BanderaMenu, BanderaMenuText } = styles;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //Sort List
  const sortedPaises = _.sortBy(paises, 'descpais');

  const setPais = (pais) => {
    setAnchorEl(null);
    console.log(pais);
    const cookies = new Cookies();
    cookies.set('pais', {
      codpais : pais.codpais, 
      codcia: pais.codcia, 
      descpais: pais.descpais,
      vida: pais.vida, 
      salud: pais.salud, 
      saludColectivo: pais.saludColectivo 
    }, {path: '/'});
    window.open(`/?cia=${pais.codcia}`, '_self');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#00466a',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      }
    },
  });

  return ( 
    <MenuPais>
      <ThemeProvider theme={theme}>
        <Button 
          variant="contained"
          color="primary"
          size="small"
          aria-controls="simple-menu" 
          aria-haspopup="true" 
          onClick={handleClick}
          endIcon={<KeyboardArrowDownRoundedIcon />}
        >
          <BanderaMenu src={`../../flags/${paisSelected.codpais}.png`} alt={paisSelected.codpais} />
        </Button>
      </ThemeProvider>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {sortedPaises.map((pais, index) => {
          return <MenuItem key={index} onClick={() => setPais(pais)}>
            <BanderaMenu src={`../../flags/${pais.codpais}.png`} alt={pais.descpais} /> 
            <BanderaMenuText>{pais.descpais}</BanderaMenuText>
          </MenuItem>;
        })}

      </Menu>
    </MenuPais>
  );
};
 
export default MenuPais;