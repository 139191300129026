import React from 'react';
import styles from '../../cotizador-salud/index.styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

//import { Link } from '@material-ui/core';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#F1F1F1',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Plan = (props) => {
  const {  Plan, 
    FondoPlan, TituloPlan, DetallesPlan, 
    BeneficiosPlan, BeneficiosList
  } = styles;

  return ( 
    <Plan>
      <FondoPlan bgImage={props.producto.img}>
        <Radio 
          value={props.producto.codprod} label={props.producto.descprod} 
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircle />}
          color="default"
        />
      </FondoPlan>
      <DetallesPlan>
        <TituloPlan>{props.producto.descprod}</TituloPlan>
        <BeneficiosPlan>
          <HtmlTooltip
            title={
              <React.Fragment>
                <BeneficiosList>
                  {props.producto.description}
                  {/* <Link /> */}
                </BeneficiosList>
              </React.Fragment>
            }
          >
            <Button>Beneficios</Button>
          </HtmlTooltip>
        </BeneficiosPlan>
      </DetallesPlan>
    </Plan>
  );
};
 
export default Plan;