import React from 'react';
import TextField from '@material-ui/core/TextField';
//import moment from 'moment';

const RenderDateField = ({
  input,
  label,
  meta: { touched, invalid, error },
  ...custom
}) => {
  //const today = moment();
  //const minus18 = today.subtract('year', 18);
  return(
    <TextField
      id={label}
      label={label}
      type="date"
      //defaultValue={minus18}
      InputLabelProps={{
        shrink: true,
      }}
      pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}"
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
};

export default RenderDateField;