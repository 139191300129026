/* eslint-disable no-console */

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function showResults() {

  await sleep(500); // simulate server latency
  window.location.href = '/';

});
