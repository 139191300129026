import React from 'react';
import styled from 'styled-components';

import { CircularProgress } from '@material-ui/core';

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: ${props => props.main ? '600px' : '100%' };

  svg{
      color: #01a9e5;
    }
`;

function Loader() {
  return (
    <Root main>
      <CircularProgress m={2} color="primary" />
    </Root>
  );
}

export default Loader;
