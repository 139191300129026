import React, { Component } from 'react';
import Header from '../header/header';
import Cotizador from '../../cotizador-salud-colectivo/cotizador';
import showResults from '../../../helpers/showResults';
import styles from './index.styles';
import { withKeycloak } from 'react-keycloak';
import {errorMessage} from '../../../helpers/alerts';
import axios from 'axios';
import {env, source, cia} from '../../../env';
import _ from 'lodash';

class LayoutSalud extends Component {
  constructor(props) {
    super(props);
    this.profile = this.profile.bind(this);
    this.getPaises = this.getPaises.bind(this);
    this.state = {
      user: {
        firstName: '',
        email: '',
        lastName: '',
        rol: '',
        username: ''
      },
      rol: '',
      userInfo:{},
      paises: [],
      loading: true

    };
  }
  profile() {
    this.props.keycloak.loadUserProfile().success(profile => {
      //console.log(JSON.stringify(profile, null, '  '));
      this.setState({
        user: profile,
        rol: 'Intermediario'
      });
    }).error(function() {
      //console.log('Failed to load user profile');
    });
  }

  info() {
    this.props.keycloak.loadUserInfo().success(info => {
      //console.log(JSON.stringify(profile, null, '  '));
      this.setState({
        userInfo: info
      });
    }).error(function() {
      //console.log('Failed to load user profile');
    });
  }

  getPaises(){
    axios.post(`//${env.beURL}/strp-paises`, {
      estado: 'ACT'
    }).then(
      res => {
        //console.log(res);
        let paises;
        if(res.data.data){
          paises = res.data.data;
        } else {
          paises = res.data;
        }
        const paisesDominio = _.filter(
          paises, {'dominio': source.toUpperCase()}
        );

        const paisesCia = _.filter(
          paises, {'codcia': cia.toUpperCase()}
        );
        const paisDefecto = paisesCia[0];

        this.setState({paises: paisesDominio, paisDefecto: paisDefecto, loading: false});
      }
    ).catch(
      () => {
        errorMessage('Lo sentimos','Ha sucedido un error, por favor intentelo mas tarde');
      }
    );
  }

  componentDidMount(){
    //LOAD PROFILE
    this.profile();
    this.getPaises();
    this.info();
  }

  render() {
    const { HeaderContainer, ContentContainer, Loading } = styles;
    const { paises, loading } = this.state;
    return(
      <div> 
        <HeaderContainer>
          {!loading &&
           <Header saludcolectivo user={this.state.user} rol={this.state.rol} paises={paises} paisDefecto={this.state.paisDefecto}/>
          }
        </HeaderContainer>
        <ContentContainer className='container'>
          <div className='row'>
            <div className='col'>
              <Cotizador onSubmit={showResults} user={this.state.user} userInfo={this.state.userInfo} cia={this.props.match.params.cia} />
            </div>
          </div> 
        </ContentContainer>
      </div>
    );
  }
}

export default withKeycloak(LayoutSalud);
//export default Layout;