/* eslint-disable no-console */
import axios from 'axios';
import {env} from '../../../env';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function enviarCorreo(user, id, plan) {
  const currentURl = `//${env.beURL}`;
  const mailer = 'mailer';
  const endpointMailer = `${currentURl}/${mailer}`;
  
  //console.log(cotizacion, user, id, mensaje, endpointURL);

  const correo = {
    from: 'negocios@wws.com.do',
    to: user.email,
    subject: `WWS - Cotizacion para ${plan.desccobert}, #${id}`,
    html: ' ',
    attachments: [
      {   // file on disk as an attachment
        filename: 'cotizacion.pdf',
        path: `./public/tmp/cotizacion-${id}.pdf` // stream this file
      }
    ]
  };

  //console.log(correo);

  await sleep(1); // simulate server latency
  //console.log(values);
  if(correo.to){
    //CREATE PDF
    return axios.post(endpointMailer, correo)
      .then(res => {
        console.log('MAIL: ', res.data);
        return res.data;
      }).catch( error => {
        console.log(error);
      });
  }else{
    return 'error';
  }

});
