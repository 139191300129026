/* eslint-disable no-console */
import axios from 'axios';
import {env} from '../../../env';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default (async function createPDF(valores, user, cotizacion, id, plan, isTermValue, isSurvivor, requisitos, edad, esFumador, sexo, 
  plazoPagos, primas, fechaTermino, primaAnual, primaSemestral, primaTrimestral, primaMensual, cia, pais) {
  const currentURl = `//${env.beURL}`;
  const pdfCotizacion = 'pdf-cotizacion';
  const endpointPDFCotizacion = `${currentURl}/${pdfCotizacion}`;
  //const endpointPDFCotizacion = `//localhost:3030/${pdfCotizacion}`;
  
  //console.log(endpointPDFCotizacion, cotizacion, primaAnual, primaSemestral, primaTrimestral, primaMensual);
  const detCotizacion = {
    user: user,
    cotizacion: cotizacion,
    id: id,
    valores: valores,
    plan: plan,
    isTermValue: isTermValue,
    isSurvivor: isSurvivor,
    requisitos: requisitos,
    edad: edad,
    esFumador: esFumador,
    sexo: sexo,
    plazoPagos: plazoPagos,
    primas: primas,
    fechaTermino: fechaTermino,
    primaAnual: primaAnual,
    primaSemestral: primaSemestral,
    primaTrimestral: primaTrimestral,
    primaMensual: primaMensual,
    cia: cia,
    pais: pais
  };


  //console.log(detCotizacion);

  await sleep(1); // simulate server latency
  //console.log(values);

  //CREATE PDF
  return axios.post(endpointPDFCotizacion, detCotizacion)
    .then(res => {
      console.log('PDF: ', res.data);
      return res.data;
    }).catch( error => {
      console.log(error);
    });

});