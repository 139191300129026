/* eslint-disable no-console */
import axios from 'axios';
import {env} from '../../../env';
//import _ from 'lodash';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const currentURl = `//${env.beURL}/strp-requisitos`;

export default (async function cotizar(edad, sumaAsegurada) {
  
  const preReq = {
    edad: edad,
    sumaAsegurada: sumaAsegurada,
  };
  await sleep(1); // simulate server latency
  //GET REQUISITOS
  return axios.post(currentURl, preReq).then(res => {
    //console.log(res);
    return res.data;
  }).catch( error => {
    console.log(error);
  });
  

});
