export default [
  {
    codcobert: 'MADT',
    edadMinima: 18,
    emision: 65,
    permanencia: 70,
    porcentajeSA: 0,
    saMaxima: 400000
  },{
    codcobert: 'ITPT',
    edadMinima: 18,
    emision: 59,
    permanencia: 70,
    porcentajeSA: 0,
    saMaxima: 400000
  },{
    codcobert: 'ENFG',
    edadMinima: 18,
    emision: 59,
    permanencia: 65,
    porcentajeSA: 0,
    saMaxima: 400000
  },{
    codcobert: 'EXON',
    edadMinima: 18,
    emision: 55,
    permanencia: 59,
    porcentajeSA: 0,
    saMaxima: 0
  },{
    codcobert: 'DEVP',
    edadMinima: 18,
    emision: 70,
    permanencia: 99,
    porcentajeSA: 0.60,
    saMaxima: 300000
  }
];